import { LqdStepper, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { ReactNode, useEffect, useReducer, useState } from "react";
import { useAppDispatch } from "../../../../store";
import { toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { TenantCreateRaw } from "../../../superadmin/types/TenantCreateRaw";
import { createTenant } from "../../api/CreateTenant";
import { TenantCountry } from "../../types/TenantCountry";
import LiquidTermsAndConditions from "./LiquidTermsAndConditions";
import SignUpFormStep1, { Step1Form } from "./SignUpFormStep1";
import SignUpFormStep2, { Step2Form } from "./SignUpFormStep2";
import SignUpFormStep3, { Step3Form } from "./SignUpFormStep3";
import SignUpFormStep4, { Step4Form } from "./SignUpFormStep4";
import SignUpFormStep5 from "./SignUpFormStep5";

type SignUpFormData = Step1Form & Step2Form & Step3Form & Step4Form;

const initialSignUpFormData: SignUpFormData = {
  email: "",
  name: "",
  paymentOption: 1,
  phoneDDI: "",
  phoneNumber: "",
  tenantCountry: "",
  tenantCustomSegment: "",
  tenantId: "",
  tenantMainActivity: "",
  tenantNewContractsPerMonth: undefined,
  tenantSegment: "",
};

const INITIAL_STEP = 1;

export default function SignUpForm() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const [agreedToTermsAndConditions, setAgreedToTermsAndConditions] = useState(false);
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [loading, setLoading] = useState(false);
  // const [realEstateActivitiesList, setRealEstateActivitiesList] = useState([]);
  // const [tenantSegmentOptions, setTenantSegmentOptions] = useState([]);

  const reducer = (state: SignUpFormData, action: Step1Form | Step2Form | Step3Form | Step4Form) => {
    if ((action as Step1Form).tenantCountry) {
      if (state.tenantCountry !== (action as Step1Form).tenantCountry) {
        state.tenantId = "";
      }
    }
    const newState = { ...state, ...action };
    return newState;
  };

  const [signUpFormData, reducerDispatch] = useReducer(reducer, initialSignUpFormData);

  const applyForm = (form: Step1Form | Step2Form | Step3Form | Step4Form) => {
    reducerDispatch(form);
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 4) {
      const tenantToCreate: TenantCreateRaw = {
        business_activity: signUpFormData.tenantMainActivity,
        contact: {
          email: signUpFormData.email,
          name: signUpFormData.name,
          phone: signUpFormData.phoneNumber,
        },
        country: signUpFormData.tenantCountry as TenantCountry,
        document: {
          type: signUpFormData.tenantCountry === "brazil" ? "CNPJ" : "NIPC",
          value: signUpFormData.tenantId,
        },
        new_contracts_per_month: signUpFormData.tenantNewContractsPerMonth as string,
        providers: [],
        segments: [signUpFormData.tenantCustomSegment || signUpFormData.tenantSegment],
      };
      setLoading(true);
      createTenant(tenantToCreate)
        .then(() => {
          setCurrentStep(currentStep + 1);
        })
        .catch((error) => {
          console.log("error: ", error);
          if (error.response?.status < 500) {
            const notification: ToastNotification = {
              message: "Algo deu errado com sua requisição. Tente novamente.",
              type: "error",
            };
            dispatch(toastCalled(notification));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const getTenantSegmentOptions = async () => {
      try {
        // const { data: { data } } = await get("/segments");
        // const segmentOptions = data.map(({ name }: { name: string; value: string }) => {
        //   return { label: name, value: name };
        // });
        // setTenantSegmentOptions(segmentOptions);
      } catch (error) {
        handleLiquidErrors(error);
      }
    };
    getTenantSegmentOptions();
  }, []);

  useEffect(() => {
    const getRealEstateActivitiesList = async () => {
      try {
        // const { data: { data } } = await get("/activities");
        // const realEstateActivities = data.map(({ name }: { name: string; value: string }) => {
        //   return { label: name, value: name };
        // });
        // setRealEstateActivitiesList(realEstateActivities);
      } catch (error) {
        handleLiquidErrors(error);
      }
    };
    getRealEstateActivitiesList();
  }, []);

  const handleGoBack = () => setCurrentStep(currentStep - 1);

  const renderCurrentStep = (): ReactNode => {
    switch (currentStep) {
      case 1:
        return <SignUpFormStep1 defaultValues={signUpFormData} onSubmit={applyForm} />;
      case 2:
        return (
          <SignUpFormStep2
            defaultValues={signUpFormData}
            onGoBack={handleGoBack}
            onSubmit={applyForm}
            tenantCountry={signUpFormData.tenantCountry}
            tenantSegmentOptions={[]}
          />
        );
      case 3:
        return (
          <SignUpFormStep3
            defaultValues={signUpFormData}
            onGoBack={handleGoBack}
            onSubmit={applyForm}
            realEstateActivitiesList={[]}
            tenantSegment={signUpFormData.tenantSegment}
          />
        );
      case 4:
        return <SignUpFormStep4 loading={loading} onGoBack={handleGoBack} onSubmit={applyForm} />;
      case 5:
        return (
          <SignUpFormStep5
            agreed={agreedToTermsAndConditions}
            email={signUpFormData.email}
            onChange={(agreed) => setAgreedToTermsAndConditions(agreed)}
            onOpenTermsAndConditions={() => setCurrentStep(6)}
          />
        );
      case 6:
        return (
          <LiquidTermsAndConditions
            agreed={agreedToTermsAndConditions}
            onChange={(agreed) => setAgreedToTermsAndConditions(agreed)}
            onClose={() => setCurrentStep(5)}
          />
        );
    }
  };

  const renderHeader = (): ReactNode => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
              Crie sua conta
            </LqdTypography>
            <LqdTypography textstyle="p2Paragraph">Insira abaixo seus dados pessoais</LqdTypography>
          </>
        );
      case 2:
      case 3:
        return (
          <>
            <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
              Sobre sua empresa
            </LqdTypography>
            <LqdTypography textstyle="p2Paragraph">Escreva os dados da sua empresa</LqdTypography>
          </>
        );
      case 4:
        return (
          <>
            <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
              Faturamento
            </LqdTypography>
            <LqdTypography textstyle="p2Paragraph">Escolha a forma de pagamento</LqdTypography>
          </>
        );
    }
  };

  return (
    <Box
      sx={{
        alignItems: currentStep >= 5 ? "center" : "flex-start",
        boxSizing: "border-box",
        display: "flex",
        height: "100%",
        mt: { sm: 0, xs: 3 },
        position: "relative",
        pt: currentStep >= 5 ? 0 : "140px",
        px: { lg: "20%", md: "10%", sm: 6, xs: 3 },
      }}
    >
      <Box id="begin" sx={{ width: "100%" }}>
        {currentStep <= 4 ? (
          <>
            {renderHeader()}
            <LqdStepper
              currentStep={currentStep - 1}
              steps={[{ label: "" }, { label: "" }, { label: "" }, { label: "" }]}
              sx={{ mb: 5, mt: 4 }}
            />
          </>
        ) : null}
        {renderCurrentStep()}
      </Box>
    </Box>
  );
}

import { LqdChip, LqdIconButton, LqdLeftArrowIcon, LqdRightArrowIcon, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import RadioCard from "../../../common/components/RadioCard";

export type Step4Form = {
  paymentOption: number;
};

type SignUpFormStep4Props = {
  loading: boolean;
  onGoBack: () => void;
  onSubmit: (form: Step4Form) => void;
};

export default function SignUpFormStep4(props: SignUpFormStep4Props) {
  const { loading, onGoBack, onSubmit } = props;

  const [paymentOption, setPaymentOption] = useState(1);
  const handleSubmit = () => {
    onSubmit({ paymentOption });
  };

  return (
    <form style={{ marginTop: "64px" }}>
      <RadioCard
        active={paymentOption === 0}
        disabled
        handleClick={() => setPaymentOption(0)}
        sx={{ "&:hover": { cursor: "auto" }, height: "60px", mb: 3 }}
      >
        <LqdChip
          chipSize="extra-small"
          label="Em breve"
          sx={{ borderColor: "rgba(33, 36, 42, 1)", color: "rgba(33, 36, 42, 1)", mb: 2, px: 0 }}
        />
        <LqdTypography color="secondary">Cartão de crédito</LqdTypography>
      </RadioCard>
      <RadioCard active={paymentOption === 1} handleClick={() => setPaymentOption(1)} sx={{ height: "60px" }}>
        <LqdTypography>Boleto bancário</LqdTypography>
        <Box sx={{ maxWidth: { lg: "80%", sm: "90%" } }}>
          <LqdTypography color="secondary" sx={{ display: "inline-block" }} textstyle="c1Caption">
            Será enviado o boleto para o e-mail de cadastro
          </LqdTypography>
        </Box>
      </RadioCard>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
        <LqdIconButton buttonsize="large" onClick={onGoBack} type="outlinePrimary">
          <LqdLeftArrowIcon />
        </LqdIconButton>
        <LqdIconButton buttonsize="large" disabled={loading} onClick={() => handleSubmit()}>
          <LqdRightArrowIcon />
        </LqdIconButton>
      </Box>
    </form>
  );
}

import { Stack } from "@mui/material";
import { LayoutProps } from "../SimulatorFunctionBlocksFunctionInputsRenderer";
import SimulatorFunctionBlocksFunctionVariableInput from "../SimulatorFunctionBlocksFunctionVariableInput";

export default function SimulatorFunctionBlocksFunctionDefaultInputLayout(props: LayoutProps) {
  const {
    editVariableButton,
    func,
    newBlockButton,
    newVariableButton,
    onBlurFunction,
    onUpdateFunction,
    openSelectComponent,
    renderOptions,
    resultInputs,
    currentBlock,
    variableInputs,
  } = props;

  return (
    <Stack spacing={1} sx={{ ml: "80px", mt: 1.5 }}>
      {Object.entries(variableInputs).map(([key, variable]) => (
        <SimulatorFunctionBlocksFunctionVariableInput
          currentBlock={currentBlock}
          editVariable={editVariableButton}
          func={func}
          isOpen={openSelectComponent}
          isResult={false}
          key={key}
          newOption={variable.select === "variables" ? newVariableButton : newBlockButton}
          onChange={(value) =>
            onUpdateFunction(key, func.function_name.includes("jump") ? Number(value) : (value as string))
          }
          options={renderOptions[variable.select]}
          value={func.variables[key]}
          variableData={{ key, variable }}
        />
      ))}
      {Object.entries(resultInputs).map(([key, variable]) => (
        <SimulatorFunctionBlocksFunctionVariableInput
          currentBlock={currentBlock}
          editVariable={editVariableButton}
          func={func}
          isOpen={openSelectComponent}
          isResult={true}
          key={key}
          newOption={null}
          onBlur={onBlurFunction}
          onChange={(name) => onUpdateFunction(key, name as string, false, true)}
          options={[]}
          value={func.result[key]}
          variableData={{ key, variable }}
        />
      ))}
    </Stack>
  );
}

import { LqdIconButton, LqdLeftArrowIcon, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import SimulatorBuilder from "../../simulatorBuilder/components/SimulatorBuilderPage";
import { onSelectTenant } from "../adminSlice";
import AdminMainRoute from "../components/AdminMainRoute/AdminMainRoute";
import AnalysisDeletion from "../components/AnalysisDeletion/AnalysisDeletion";
import BuildingDateRegistration from "../components/BuildingDateRegistration/BuildingDateRegistration";
import GroupDeletion from "../components/GroupDeletion/GroupDeletion";
import ProductDeletion from "../components/ProductDeletion/ProductDeletion";
import SimulatorParametrization from "../components/SimulatorParametrization/SimulatorParametrization";
import TemplateConfig from "../components/TemplateConfig/TemplateConfig";
import TenantConfig from "../components/TenantConfig/TenantConfig";
import TenantConfigPanelButtons from "../components/TenantConfig/TenantConfigPanelButtons";
import UserDeletion from "../components/UserDeletion/UserDeletion";
import { AdminPagePanels } from "../types/AdminPagePanels";

export default function AdminPage() {
  const dispatch = useAppDispatch();

  const currentSelectedTenant = useAppSelector((state) => state.admin.currentSelectedTenant);

  const [currentPanel, setCurrentPanel] = useState<AdminPagePanels>(AdminPagePanels.ADMIN);

  const renderPanelContent = () => {
    switch (currentPanel) {
      case AdminPagePanels.ADMIN:
        return {
          component: <AdminMainRoute setCurrentPanel={setCurrentPanel} />,
          title1: "Opções de Administrador",
          title2: null,
          title3: null,
          title4: null,
        };
      case AdminPagePanels.ANALYSIS_DELETION:
        return {
          component: <AnalysisDeletion />,
          title1: "Exclusão de Conexões",
          title2: "Administrador",
          title3: "Gerenciar Empresas",
          title4: "Exclusão de Conexões",
        };
      case AdminPagePanels.GROUP_DELETION:
        return {
          component: <GroupDeletion />,
          title1: "Exclusão de Grupos",
          title2: "Administrador",
          title3: "Gerenciar Empresas",
          title4: "Exclusão de Grupos",
        };
      case AdminPagePanels.PRODUCT_DELETION:
        return {
          component: <ProductDeletion />,
          title1: "Exclusão de Produtos",
          title2: "Administrador",
          title3: "Gerenciar Empresas",
          title4: "Exclusão de Produtos",
        };
      case AdminPagePanels.PROJECT_CONCLUSION_DATE:
        return {
          component: <BuildingDateRegistration />,
          title1: "Data de Conclusão da Obra",
          title2: "Administrador",
          title3: "Gerenciar Empresas",
          title4: "Data de Conclusão da Obra",
        };
      case AdminPagePanels.SIMULATOR_PARAMETRIZATION:
        return {
          component: <SimulatorParametrization />,
          title1: "Parametrização de Simuladores",
          title2: "Administrador",
          title3: "Gerenciar Empresas",
          title4: "Parametrização de Simuladores",
        };
      case AdminPagePanels.TENANT_CONFIG:
        return {
          component: <TenantConfigPanelButtons setCurrentPanel={setCurrentPanel} />,
          title1: currentSelectedTenant.contact.name,
          title2: "Administrador",
          title3: "Gerenciar Empresas",
          title4: null,
        };
      case AdminPagePanels.TENANT_MANAGEMENT:
        return {
          component: <TenantConfig setCurrentPanel={setCurrentPanel} />,
          title1: "Gerenciar Empresas",
          title2: "Administrador",
          title3: "Gerenciar Empresas",
          title4: null,
        };
      case AdminPagePanels.TEMPLATE_CONFIG:
        return {
          component: <TemplateConfig />,
          title1: "Configurar Templates",
          title2: "Administrador",
          title3: "Configurar Templates",
          title4: null,
        };
      case AdminPagePanels.USER_DELETION:
        return {
          component: <UserDeletion />,
          title1: "Exclusão de Usuários",
          title2: "Administrador",
          title3: "Gerenciar Empresas",
          title4: "Exclusão de Usuários",
        };
      case AdminPagePanels.SIMULATOR_CONFIGURATOR:
        return {
          component: <SimulatorBuilder />,
          title1: "Gerenciar Simuladores",
          title2: "Administrador",
          title3: "Gerenciar Simuladores",
          title4: null,
        };
      default:
        return {
          component: null,
          title1: null,
          title2: null,
          title3: null,
          title4: null,
        };
    }
  };

  const panelContent = renderPanelContent();

  const handleReturnButton = () => {
    switch (currentPanel) {
      case AdminPagePanels.ANALYSIS_DELETION:
      case AdminPagePanels.GROUP_DELETION:
      case AdminPagePanels.PRODUCT_DELETION:
      case AdminPagePanels.PROJECT_CONCLUSION_DATE:
      case AdminPagePanels.SIMULATOR_PARAMETRIZATION:
      case AdminPagePanels.USER_DELETION:
        return setCurrentPanel(AdminPagePanels.TENANT_CONFIG);
      case AdminPagePanels.TENANT_CONFIG:
        dispatch(onSelectTenant(""));
        return setCurrentPanel(AdminPagePanels.TENANT_MANAGEMENT);
      case AdminPagePanels.SIMULATOR_CONFIGURATOR:
      case AdminPagePanels.TENANT_MANAGEMENT:
      case AdminPagePanels.TEMPLATE_CONFIG:
        return setCurrentPanel(AdminPagePanels.ADMIN);
      case AdminPagePanels.ADMIN:
      default:
        return null;
    }
  };

  const handleBreadcrumbClick = () => {
    switch (true) {
      case currentPanel === AdminPagePanels.TENANT_CONFIG:
        return setCurrentPanel(AdminPagePanels.TENANT_MANAGEMENT);
      case !!panelContent.title4:
        return setCurrentPanel(AdminPagePanels.TENANT_CONFIG);
      default:
        null;
    }
  };

  return (
    <Box sx={{ p: 5 }}>
      {currentPanel === AdminPagePanels.ADMIN ? (
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 8, ml: 8 }} textstyle="h4Headline">
          {panelContent.title1}
        </LqdTypography>
      ) : (
        <Box sx={{ alignItems: "center", display: "flex", mb: 6, ml: 8 }}>
          <LqdIconButton onClick={handleReturnButton} round="true" type="outlinePrimary">
            <LqdLeftArrowIcon />
          </LqdIconButton>
          {/* TODO: Daqui para baixo, alterar para o componente LqdBreadcrumb, fazendo as validações necessárias. */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ ml: 2 }} textstyle="h4Headline">
              {panelContent.title1}
            </LqdTypography>
            <Box>
              <LqdTypography
                onClick={() => setCurrentPanel(AdminPagePanels.ADMIN)}
                sx={{
                  ":hover": { cursor: "pointer", textDecoration: "underline" },
                  color: "rgba(155, 162, 175, 1)",
                  display: "inline",
                  ml: 2,
                }}
                textstyle="p2Paragraph"
              >
                {` ${panelContent.title2}/`}
              </LqdTypography>
              <LqdTypography
                onClick={handleBreadcrumbClick}
                sx={{
                  ":hover": {
                    cursor:
                      panelContent.title4 || currentPanel === AdminPagePanels.TENANT_CONFIG ? "pointer" : "default",
                    textDecoration:
                      panelContent.title4 || currentPanel === AdminPagePanels.TENANT_CONFIG ? "underline" : "none",
                  },
                  color: "rgba(101, 110, 127, 1)",
                  display: "inline",
                }}
                textstyle="p2Paragraph"
              >
                {panelContent.title4 ? ` ${panelContent.title3}/` : ` ${panelContent.title3}`}
              </LqdTypography>
              <LqdTypography sx={{ color: "rgba(101, 110, 127, 1)", display: "inline" }} textstyle="p2Paragraph">
                {panelContent.title4 ? ` ${panelContent.title4}` : null}
              </LqdTypography>
            </Box>
          </Box>
        </Box>
      )}
      {panelContent.component}
    </Box>
  );
}

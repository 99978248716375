import { LqdEditIcon, LqdIconButton, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { createTemplateModalStepGoTo, editTemplateModalStepGoTo } from "../../../adminSlice";

type TemplateSummaryCardsProps = {
  checkedSegments: Array<string>;
  country: string;
  currency: string;
  title: string;
};

export default function TemplateSummaryCards(props: TemplateSummaryCardsProps) {
  const { checkedSegments, country, currency, title } = props;

  const dispatch = useAppDispatch();
  const { createTemplate, segments, createTemplateModalStep, editTemplateModalStep, templateToEdit } = useAppSelector(
    (state) => state.admin
  );
  const currentStep = createTemplate ? createTemplateModalStep : editTemplateModalStep;

  return (
    <Box sx={{ width: "415px" }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: 3 }} textstyle="h5Headline">
        Resumo
      </LqdTypography>
      {title ? (
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "rgba(191, 196, 205, 0.12)",
            borderRadius: 5,
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            p: 3,
          }}
        >
          <Box>
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
              Nome do template
            </LqdTypography>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
              {title}
            </LqdTypography>
          </Box>
          <LqdIconButton
            onClick={() => dispatch(createTemplate ? createTemplateModalStepGoTo(1) : editTemplateModalStepGoTo(1))}
            type="ghostPrimary"
          >
            <LqdEditIcon color="rgba(33, 36, 42, 1)" />
          </LqdIconButton>
        </Box>
      ) : null}
      {currentStep > 2 && country && currency ? (
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "rgba(191, 196, 205, 0.12)",
            borderRadius: 5,
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            p: 3,
          }}
        >
          <Box>
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
              Moeda
            </LqdTypography>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
              {`${country} / ${currency}`}
            </LqdTypography>
          </Box>
          {!templateToEdit ? (
            <LqdIconButton
              onClick={() => dispatch(createTemplate ? createTemplateModalStepGoTo(2) : editTemplateModalStepGoTo(2))}
              type="ghostPrimary"
            >
              <LqdEditIcon color="rgba(33, 36, 42, 1)" />
            </LqdIconButton>
          ) : null}
        </Box>
      ) : null}
      {templateToEdit ? (
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "rgba(191, 196, 205, 0.12)",
            borderRadius: 5,
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            p: 3,
          }}
        >
          <Box>
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
              Segmentos
            </LqdTypography>
            {checkedSegments.map((segmentId) => (
              <LqdTypography color="rgba(33, 36, 42, 1)" key={segmentId} sx={{ mt: 0.5 }} textstyle="h5Headline">
                {segments[segmentId].name}
              </LqdTypography>
            ))}
          </Box>
          <LqdIconButton
            onClick={() => dispatch(createTemplate ? createTemplateModalStepGoTo(3) : editTemplateModalStepGoTo(3))}
            type="ghostPrimary"
          >
            <LqdEditIcon color="rgba(33, 36, 42, 1)" />
          </LqdIconButton>
        </Box>
      ) : null}
    </Box>
  );
}

import { getAnalysisDetailsSteps } from "../api/GetAnalysisDetailsSteps";
import { AnalysisStepsResponseRaw } from "../types/AnalysisDetails/Analysis/AnalysisStepsResponseBody";

export const delayedGetSteps = (analysisId: string, delayMs: number = 0) =>
  new Promise<AnalysisStepsResponseRaw | null>((resolve, reject) => {
    setTimeout(() => {
      const retry = () => {
        setTimeout(() => {
          delayedGetSteps(analysisId, delayMs) // Call the method again recursively
            .then(resolve)
            .catch(reject);
        }, 1000);
      };

      getAnalysisDetailsSteps(analysisId)
        .then((res) => {
          const rawSteps = res.data;
          resolve(rawSteps);
        })
        .catch(() => retry()); // Retry the method if there is an error;
    }, delayMs);
  });

import { LqdButton } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { AdminPagePanels } from "../../types/AdminPagePanels";

type AdminMainRouteProps = {
  setCurrentPanel: (panel: AdminPagePanels) => void;
};

export default function AdminMainRoute(props: AdminMainRouteProps) {
  const { setCurrentPanel } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 8, rowGap: "60px", width: "325px" }}>
      <LqdButton onClick={() => setCurrentPanel(AdminPagePanels.TEMPLATE_CONFIG)}>
        Configurar Templates de Produtos
      </LqdButton>
      <LqdButton onClick={() => setCurrentPanel(AdminPagePanels.TENANT_MANAGEMENT)}>Gerenciar Empresas</LqdButton>
      <LqdButton onClick={() => setCurrentPanel(AdminPagePanels.SIMULATOR_CONFIGURATOR)}>
        Gerenciar Simuladores
      </LqdButton>
    </Box>
  );
}

import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterThree() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        3. SOLUÇÕES LIQUID
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.1. A Liquid possui uma plataforma que consiste em automatizar o processo de análise ou decisão baseada em
        dados, voltada a humanizar esse processo, trazendo maior eficiência, assertividade e segurança para as partes
        envolvidas, inclusive para os Titulares dos Dados tratados na operação, uma vez que estes compartilharão ou
        terão os seus Dados compartilhados apenas quando necessário (“Plataforma Liquid”).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.2. A Plataforma Liquid possui uma inteligência artificial (Liquid AI) capaz de automatizar a análise ou
        decisão através das políticas de análise ou decisão estabelecidas pelo Usuário Decisor.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.3. Os Dados Pessoais do Usuário Analisado podem ser coletados das seguintes formas: (i) quando o próprio
        Usuário Analisado realiza o cadastro na Plataforma Liquid e fornece as informações necessárias, inclusive seus
        Dados Pessoais; (ii) quando o Usuário Decisor inserir os Dados do Usuário Analisado na Plataforma Liquid para
        uma análise ou decisão; ou (iii) quando, a partir de uma das hipóteses anteriores, os Dados são coletados
        através da conexão da Liquid AI a fontes de dados externas (item 6.1. desta Política).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.3.1. Quando o Usuário Decisor inserir Dados do Usuário Analisado na Plataforma Liquid, caberá ao Usuário
        Decisor a responsabilidade de informar ao Usuário Analisado sobre o Tratamento de seus Dados, inclusive no que
        se refere à coleta do respectivo consentimento, quando aplicável.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.4. Caberá também a cada Usuário Decisor estabelecer os indicadores que sejam compatíveis com seus interesses e
        necessidades (“Parâmetros”), os quais podem ser alterados a qualquer momento pelo Usuário Decisor e são de sua
        total responsabilidade.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.5. Através de suas Soluções, a Liquid realizará o Tratamento de Dados Pessoais do Usuário Analisado de acordo
        e dentro do estritamente necessário para cumprir com as finalidades estabelecidas pelo Usuário Decisor.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.6. O Tratamento será realizado automaticamente a partir dos Parâmetros estabelecidos na Plataforma Liquid pelo
        Usuário Decisor, não havendo qualquer ingerência, orientação ou interferência pela Liquid na forma ou duração do
        Tratamento decorrente da análise ou decisão.
      </LqdTypography>
    </>
  );
}

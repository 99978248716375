import { LqdTypography } from "@/liquid-components/src";
import { normalizeText } from "./normalizeText";

/** Retorna a string inserida com cores editadas de acordo com o texto da pesquisa, para dar "match" do search com o que está sendo renderizado.
 * @param text Texto que será modificado
 * @param highlight Texto pesquisado
 */
export const highlightText = (text: string, highlight: string) => {
  const normalizedText = normalizeText(text);
  const normalizedHighlight = normalizeText(highlight);

  const parts = normalizedText.split(new RegExp(`(${normalizedHighlight})`, "gi"));

  let currentIndex = 0;

  return parts.map((part, index) => {
    const originalPart = text.slice(currentIndex, currentIndex + part.length);
    currentIndex += part.length;

    return normalizeText(part) === normalizedHighlight ? (
      <LqdTypography component="span" key={index} style={{ fontWeight: 600 }}>
        {originalPart}
      </LqdTypography>
    ) : (
      originalPart
    );
  });
};

import { AllotmentSimulation } from "../../analysisDetails/types/Simulation/Allotment/AllotmentSimulation";
import { SBPESimulation } from "../../analysisDetails/types/Simulation/SBPE/SBPESimulation";
import {
  formatDocument,
  formatValueToCurrency,
  formatValueToPercentage,
} from "../../analysisDetails/utils/analysisDetailsDataFormatter";
import { AnalysisReportDataRaw } from "../types/AnalysisReportDataRaw";
import { FormattedAnalysisReportData } from "../types/FormattedAnalysisReportData";

type FormattedReportCustomHeaders = {
  [key: string]: number | string /* Peer / Bureau Data */;
  "COMPOSIÇÃO RENDAS": number | string;
  "CRIADO POR": number | string;
  "DATA CRIAÇÃO": number | string;
  ENTRADA: number | string;
  "FINANC. DESEJADO": number | string;
  "FINANC. MÁX. (PROPOSTA)": number | string;
  "FINANC. MÁX. (RENDA)": number | string;
  "ID CONEXÃO": number | string;
  "ID PRODUTO": number | string;
  "INTERAÇÃO HUMANA": number | string;
  "MÊS CRIAÇÃO": number | string;
  "NOME PRODUTO": number | string;
  "NOME TENANT": number | string;
  "PERÍODO FINANC.": number | string;
  "QTD PEERS": number | string;
  STATUS: number | string;
  "ÚLTIMA ATUALIZAÇÃO": number | string;
  "VALOR DA PROPOSTA": number | string;
};

export const reportCustomHeaders: Array<string> = [
  "NOME TENANT",
  "ID PRODUTO",
  "NOME PRODUTO",
  "ID CONEXÃO",
  "DATA CRIAÇÃO",
  "MÊS CRIAÇÃO",
  "CRIADO POR",
  "ÚLTIMA ATUALIZAÇÃO",
  "STATUS",
  "TOTAL OPEN CREDIT",
  "INTERAÇÃO HUMANA",
  "COMPOSIÇÃO RENDAS",
  "VALOR DA PROPOSTA",
  "ENTRADA",
  "FINANC. DESEJADO",
  "PERÍODO FINANC.",
  "FINANC. MÁX. (RENDA)",
  "FINANC. MÁX. (PROPOSTA)",
  "QTD PEERS",
];

export const formatReportData = (
  analysisData: Array<AnalysisReportDataRaw>,
  maxPeers: number,
  formattedDataList: Array<FormattedAnalysisReportData>
) => {
  for (let i = 0; i < analysisData.length; i += 1) {
    const formattedData: FormattedAnalysisReportData = {
      analysis_id: "",
      contract_value: "",
      created_at: "",
      created_at_month_year: "",
      created_by: "",
      desired_financing_value: "",
      desired_payment_term: "",
      down_payment: "",
      financing_limit_value: "",
      human_interaction: "",
      max_financing_value: "",
      monthly_income: "",
      peers_length: "",
      product_id: "",
      product_name: "",
      status: "",
      tenant_name: "",
      total_agreements_open_credit: "",
      updated_at: "",
    };

    // General Data
    formattedData["analysis_id"] = analysisData[i].analysis_id;
    formattedData["created_at"] = analysisData[i].created_at;
    formattedData["created_at_month_year"] = analysisData[i].created_at_month_year;
    formattedData["created_by"] = analysisData[i].created_by;
    formattedData["human_interaction"] = analysisData[i].human_interaction ? "Sim" : "Não";
    formattedData["product_id"] = analysisData[i].product_id;
    formattedData["product_name"] = analysisData[i].product_name;
    formattedData["status"] = analysisData[i].status;
    formattedData["tenant_name"] = analysisData[i].tenant_name;
    formattedData["updated_at"] = analysisData[i].updated_at;
    formattedData["peers_length"] = analysisData[i].peers.length;
    formattedData["total_agreements_open_credit"] = analysisData[i].total_agreements_open_credit;

    // Simulator Data
    const allotmentSimulation = analysisData[i].simulations.find(
      (simulation) => simulation.simulation_type === "allotment"
    );
    const sbpeSimulation = analysisData[i].simulations.find((simulation) => simulation.simulation_type === "sbpe");

    let contractValue: number | string = "";
    let desiredFinancingValue: number | string = "";
    let desiredPaymentTerm: number | string = "";
    let downPaymentValue: number | string = "";
    let financingLimitValue: number | string = "";
    let maxFinancingValue: number | string = "";
    let monthlyIncome: number | string = "";

    if (allotmentSimulation) {
      contractValue = (allotmentSimulation.simulation as AllotmentSimulation)?.highlight?.contract_value;
      desiredFinancingValue = (allotmentSimulation.simulation as AllotmentSimulation)?.highlight?.sec_loan_amount;
      desiredPaymentTerm = (allotmentSimulation.simulation as AllotmentSimulation)?.highlight?.desired_payment_term;
      downPaymentValue = (allotmentSimulation.simulation as AllotmentSimulation)?.highlight?.down_payment;
      financingLimitValue = (allotmentSimulation.simulation as AllotmentSimulation)?.highlight?.max_sec_loan_available;
      monthlyIncome = (allotmentSimulation.simulation as AllotmentSimulation)?.highlight?.monthly_income;
    }

    if (sbpeSimulation) {
      contractValue = (sbpeSimulation.simulation as SBPESimulation)?.highlight?.contract_value;
      desiredFinancingValue = (sbpeSimulation.simulation as SBPESimulation)?.highlight?.desired_financing_value;
      downPaymentValue = (sbpeSimulation.simulation as SBPESimulation)?.highlight?.down_payment;
      financingLimitValue = (sbpeSimulation.simulation as SBPESimulation)?.highlight?.financing_value_limit;
      maxFinancingValue = (sbpeSimulation.simulation as SBPESimulation)?.highlight?.max_financing_value;
      monthlyIncome = (sbpeSimulation.simulation as SBPESimulation)?.highlight?.monthly_income;
    }

    formattedData["contract_value"] = contractValue
      ? roundValue(formatValueToCurrency(Number(contractValue), "BRL", false))
      : "";
    formattedData["desired_financing_value"] = desiredFinancingValue
      ? roundValue(formatValueToCurrency(Number(desiredFinancingValue), "BRL", false))
      : "";
    formattedData["desired_payment_term"] = desiredPaymentTerm
      ? roundValue(formatValueToCurrency(Number(desiredPaymentTerm), "BRL", false))
      : "";
    formattedData["down_payment"] = downPaymentValue
      ? roundValue(formatValueToCurrency(Number(downPaymentValue), "BRL", false))
      : "";
    formattedData["financing_limit_value"] = financingLimitValue
      ? roundValue(formatValueToCurrency(Number(financingLimitValue), "BRL", false))
      : "";
    formattedData["max_financing_value"] = maxFinancingValue
      ? roundValue(formatValueToCurrency(Number(maxFinancingValue), "BRL", false))
      : "";
    formattedData["monthly_income"] = monthlyIncome
      ? roundValue(formatValueToCurrency(Number(monthlyIncome), "BRL", false))
      : "";

    // Peers / Bureau Data
    for (let j = 0; j < maxPeers; j += 1) {
      const peerPrefix = `peer_${j + 1}`;

      if (j < analysisData[i].peers.length) {
        formattedData[`${peerPrefix}_id`] = analysisData[i].peers[j].pass_id;
        formattedData[`${peerPrefix}_name`] = analysisData[i].peers[j].full_name;
        formattedData[`${peerPrefix}_document`] = formatDocument(analysisData[i].peers[j].document);
        formattedData[`${peerPrefix}_incoming`] = analysisData[i].peers[j].incoming || "Não informado";
        formattedData[`${peerPrefix}_estimated_income`] = analysisData[i].peers[j].estimated_income || "Não informado";
        formattedData[`${peerPrefix}_max_payment`] = analysisData[i].peers[j].max_payment || "Não informado";
        formattedData[`${peerPrefix}_income_commitment`] =
          formatValueToPercentage(analysisData[i].peers[j].income_commitment) || "Não informado";
        formattedData[`${peerPrefix}_has_partners`] = analysisData[i].peers[j].has_partners || "Não informado";
        formattedData[`${peerPrefix}_score_lqd`] = analysisData[i].peers[j].score_lqd;
        formattedData[`${peerPrefix}_rating_lqd`] = analysisData[i].peers[j].rating_lqd;
        formattedData[`${peerPrefix}_cpf_status`] = analysisData[i].peers[j].cpf_status;
        formattedData[`${peerPrefix}_has_debts`] = analysisData[i].peers[j].has_debts ? "Sim" : "Não";
        formattedData[`${peerPrefix}_debts_count`] = analysisData[i].peers[j].debts_count;
        formattedData[`${peerPrefix}_debts_sum`] = analysisData[i].peers[j].debts_sum;
      } else {
        formattedData[`${peerPrefix}_id`] = "";
        formattedData[`${peerPrefix}_name`] = "";
        formattedData[`${peerPrefix}_document`] = "";
        formattedData[`${peerPrefix}_incoming`] = "";
        formattedData[`${peerPrefix}_estimated_income`] = "";
        formattedData[`${peerPrefix}_max_payment`] = "";
        formattedData[`${peerPrefix}_income_commitment`] = "";
        formattedData[`${peerPrefix}_has_partners`] = "";
        formattedData[`${peerPrefix}_score_lqd`] = "";
        formattedData[`${peerPrefix}_rating_lqd`] = "";
        formattedData[`${peerPrefix}_cpf_status`] = "";
        formattedData[`${peerPrefix}_has_debts`] = "";
        formattedData[`${peerPrefix}_debts_count`] = "";
        formattedData[`${peerPrefix}_debts_sum`] = "";
      }
    }

    formattedDataList.push(formattedData);
  }

  return formattedDataList;
};

const roundValue = (value: number | string) => {
  switch (true) {
    case typeof value === "string":
      return value;
    case value === undefined:
      return "";
    case typeof value === "number":
    default:
      return Number(value).toFixed(2);
  }
};

export const getCSVData = (analysisData: Array<FormattedAnalysisReportData>, maxPeers: number) => {
  return analysisData.map((data) => {
    const formattedItem: FormattedReportCustomHeaders = {
      "COMPOSIÇÃO RENDAS": data.monthly_income,
      "CRIADO POR": data.created_by,
      "DATA CRIAÇÃO": data.created_at,
      ENTRADA: data.down_payment,
      "FINANC. DESEJADO": data.desired_financing_value,
      "FINANC. MÁX. (PROPOSTA)": data.max_financing_value,
      "FINANC. MÁX. (RENDA)": data.financing_limit_value,
      "ID CONEXÃO": data.analysis_id,
      "ID PRODUTO": data.product_id,
      "INTERAÇÃO HUMANA": data.human_interaction,
      "MÊS CRIAÇÃO": data.created_at_month_year,
      "NOME PRODUTO": data.product_name,
      "NOME TENANT": data.tenant_name,
      "PERÍODO FINANC.": data.desired_payment_term,
      "QTD PEERS": data.peers_length,
      STATUS: data.status,
      "VALOR DA PROPOSTA": data.contract_value,
      "ÚLTIMA ATUALIZAÇÃO": data.updated_at,
    };

    // Dynamically add fields for each peer based on maxPeers value
    for (let i = 0; i < maxPeers; i += 1) {
      const peerIndex = i + 1;
      formattedItem[`PEER ${peerIndex} PASS ID`] = data[`peer_${peerIndex}_id`];
      formattedItem[`PEER ${peerIndex} NOME`] = data[`peer_${peerIndex}_name`];
      formattedItem[`PEER ${peerIndex} DOCUMENTO`] = data[`peer_${peerIndex}_document`];
      formattedItem[`PEER ${peerIndex} RENDA`] = data[`peer_${peerIndex}_incoming`];
      formattedItem[`PEER ${peerIndex} RENDA ESTIMADA`] = data[`peer_${peerIndex}_estimated_income`];
      formattedItem[`PEER ${peerIndex} CAPAC. PAGTO. MENSAL ESTIMADA`] = data[`peer_${peerIndex}_max_payment`];
      formattedItem[`PEER ${peerIndex} COMPROMETIMENTO RENDA ESTIMADA`] = data[`peer_${peerIndex}_income_commitment`];
      formattedItem[`PEER ${peerIndex} PARTICIPAÇÕES SOCIETÁRIAS`] = data[`peer_${peerIndex}_has_partners`];
      formattedItem[`PEER ${peerIndex} SCORE`] = data[`peer_${peerIndex}_score_lqd`];
      formattedItem[`PEER ${peerIndex} RATING LIQUID`] = data[`peer_${peerIndex}_rating_lqd`];
      formattedItem[`PEER ${peerIndex} CPF REGULAR`] = data[`peer_${peerIndex}_cpf_status`];
      formattedItem[`PEER ${peerIndex} RESTRIÇÕES`] = data[`peer_${peerIndex}_has_debts`];
      formattedItem[`PEER ${peerIndex} QTD. RESTRIÇÕES`] =
        data[`peer_${peerIndex}_has_debts`] === "Sim" ? data[`peer_${peerIndex}_debts_count`] : "";
      formattedItem[`PEER ${peerIndex} VALOR RESTRIÇÕES`] =
        data[`peer_${peerIndex}_has_debts`] === "Sim" ? data[`peer_${peerIndex}_debts_sum`] : "";
    }

    return formattedItem;
  });
};

import {
  LqdButton,
  LqdCircularLoader,
  LqdDialog,
  LqdDialogActions,
  LqdSearch,
  LqdTypography,
} from "@/liquid-components/src";
import { dialogCalled } from "@common/commonSlice";
import { updateGroupsToRender } from "@groups/groupsSlice";
import { Box, Stack } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { SmallDialogAction } from "./SmallDialog";
import SmallDialogHeader from "./SmallDialogHeader";
import { normalizeText } from "@common/utils/normalizeText";

export default function SmallDialogSearch() {
  const dispatch = useAppDispatch();

  const { dialogContent, dialogShowState, dialogFieldValue, dialogLoading } = useAppSelector((state) => state.common);
  const { checkedItems } = useAppSelector((state) => state.groups);

  const { confirmationModalText, defaultCheckedItems, noResultsMessage, setDefaultCheckedItems } =
    dialogContent.searchStates!;

  const [searchValue, setSearchValue] = useState("");
  const [showNoResultsMessage, setShowNoResultsMessage] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setSearchValue("");
    setShowNoResultsMessage(false);
    dispatch(dialogCalled(null));
  };

  const getButtonType = (index: number, actions: Array<SmallDialogAction>) =>
    actions.length === 1 || index !== 0 ? "filledPrimary" : "outlineTertiary";

  const handleFirstConfirmClick = (action: SmallDialogAction, actionIndex: number) => {
    setShowNoResultsMessage(false);

    if (action.onClick) setShowConfirmationDialog(true);

    if (actionIndex === 0) handleCloseDialog();
  };

  const handleSecondConfirmClick = async (action: SmallDialogAction | null, actionIndex: number) => {
    if (action && action.onClick) {
      await action.onClick(dialogFieldValue);
      setDefaultCheckedItems([...checkedItems]);
      setShowConfirmationDialog(false);
      setSearchValue("");
    }

    if (actionIndex === 0) {
      setShowConfirmationDialog(false);
    }
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      const normalizedValue = normalizeText(value);

      if (value !== "") {
        const filteredValues = dialogContent.itemList!.filter((group) =>
          normalizeText(group.labelOne).includes(normalizedValue)
        );

        setShowNoResultsMessage(!filteredValues.length);
        dispatch(updateGroupsToRender(filteredValues));
      } else {
        setShowNoResultsMessage(false);
        dispatch(updateGroupsToRender(dialogContent.itemList));
      }
    }, 300),
    []
  );

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    handleSearch(value);
  };

  return (
    <>
      {showConfirmationDialog ? (
        <LqdDialog onClose={() => handleSecondConfirmClick(null, 0)} open={dialogShowState}>
          <SmallDialogHeader body={confirmationModalText.body} title={confirmationModalText.title} />
          <LqdDialogActions sx={{ mt: 2.5 }}>
            {dialogContent.actions.map((action, index, actions) => {
              return (
                <LqdButton
                  className="lqd-dialog-button"
                  disabled={dialogLoading}
                  key={action.title}
                  onClick={() => handleSecondConfirmClick(action, index)}
                  type={getButtonType(index, actions)}
                >
                  {dialogLoading && actions.length > 1 && index !== 0 ? (
                    <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
                  ) : (
                    action.title
                  )}
                </LqdButton>
              );
            })}
          </LqdDialogActions>
        </LqdDialog>
      ) : null}

      <LqdDialog customMaxWidth="100%" onClick={handleCloseDialog} open={dialogShowState}>
        <Box onClick={(e) => e.stopPropagation()} p={2} pt={3}>
          <Stack mb={3}>
            <LqdTypography color="rgba(23, 23, 23, 1)" sx={{ mb: 1 }} textstyle="h4Headline">
              {dialogContent.title}
            </LqdTypography>
            <LqdTypography color="rgba(115, 115, 115, 1)" textstyle="p2Paragraph">
              {dialogContent.body}
            </LqdTypography>
          </Stack>
          <Stack onFocus={(event) => event.stopPropagation()} onMouseDown={(event) => event.stopPropagation()}>
            <LqdSearch onChange={onChangeSearch} placeholder="Buscar Grupos" value={searchValue} />
          </Stack>
          {showNoResultsMessage ? (
            <Stack sx={{ m: "0 auto", mb: -2, mt: 4 }} width="60%">
              <LqdTypography color="rgba(155, 162, 175, 1)" textAlign="center" textstyle="h5Headline">
                {noResultsMessage}
              </LqdTypography>
            </Stack>
          ) : null}
          {dialogContent.children}
          <Stack flexDirection="row" gap={1.5} justifyContent="flex-end">
            <LqdDialogActions sx={{ mt: 2.5 }}>
              {dialogContent.actions.map((action, index, actions) => {
                return (
                  <LqdButton
                    className="lqd-dialog-button"
                    // TODO: Este disabled não é genérico e só funciona pra um modal com checkboxes que usam o checkedItem
                    disabled={
                      index !== 0
                        ? JSON.stringify([...defaultCheckedItems].sort()) === JSON.stringify([...checkedItems].sort())
                        : false
                    }
                    key={action.title}
                    onClick={() => handleFirstConfirmClick(action, index)}
                    type={getButtonType(index, actions)}
                  >
                    {action.title}
                  </LqdButton>
                );
              })}
            </LqdDialogActions>
          </Stack>
        </Box>
      </LqdDialog>
    </>
  );
}

import { LqdTypography } from "@/liquid-components/src";
import { Grid } from "@mui/material";

export default function UsersRouteTableHeader() {
  return (
    <Grid
      columns={{ lg: 16, md: 20 }}
      container
      sx={{
        alignItems: "center",
        backgroundColor: "rgba(248, 248, 249, 1)",
        borderBottom: "1px solid rgba(222, 225, 229, 1)",
        borderRadius: "8px 8px 0 0",
        height: "80px",
        mx: 4,
        width: "70vw",
      }}
    >
      <Grid item lg={6} md={7} sx={{ border: "none", pl: { lg: 3, md: 1, xs: 3 }, pr: { xs: 30 } }}>
        <LqdTypography textstyle="p2Paragraph">Nome</LqdTypography>
      </Grid>
      <Grid item lg={2} md={3} sx={{ border: "none", pl: { lg: 2, md: 0, xs: 3 }, pr: { xs: 2 } }}>
        <LqdTypography textstyle="p2Paragraph">Perfil</LqdTypography>
      </Grid>
      <Grid item lg={2} md={3} sx={{ border: "none", pl: { lg: 1, md: 0, xs: 3 }, pr: { xs: 2 } }}>
        <LqdTypography textstyle="p2Paragraph">Grupos</LqdTypography>
      </Grid>
      <Grid item lg={2.5} md={4} sx={{ border: "none", pl: { lg: 1, md: 0, xs: 3 }, pr: { xs: 7 } }}>
        <LqdTypography textstyle="p2Paragraph">Criação</LqdTypography>
      </Grid>
      <Grid item lg={1.5} md={3} sx={{ border: "none", pl: { lg: 0.5, md: 0, xs: 2 }, pr: { xs: 2 } }}>
        <LqdTypography textstyle="p2Paragraph">Status</LqdTypography>
      </Grid>
      <Grid item lg={2} md={3} sx={{ border: "none", pl: { lg: 0.5, md: 0, xs: 3 }, pr: { xs: 2 } }}>
        <LqdTypography textstyle="p2Paragraph">Notificações</LqdTypography>
      </Grid>
    </Grid>
  );
}

import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterSeven() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        7. ARMAZENAMENTO DE DADOS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        7.1. Os Dados Pessoais tratados pela Liquid através de suas Soluções serão armazenados em servidores que podem
        estar alocados fora do Brasil, desde que os fornecedores estejam adequados à legislação de proteção de dados de
        dados aplicável.
      </LqdTypography>
    </>
  );
}

import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../../store";
import { toastCalled } from "../../common/commonSlice";
import CreatePasswordForm from "../../common/components/CreatePasswordForm";
import { ToastNotification } from "../../common/types/ToastNotification";
import UserData from "../../signin/types/UserData";
import recreatePassword from "../api/RecreatePassword";

type RecreatePasswordFormProps = {
  userData: UserData;
};

export default function RecreatePasswordForm(props: RecreatePasswordFormProps) {
  const { userData } = props;

  const INITIAL_STEP = 1;

  const dispatch = useAppDispatch();

  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [loading, setLoading] = useState(false);

  const handleRecreatePassword = (password: string) => {
    setLoading(true);

    const body = { password, token: userData.token };

    recreatePassword(body)
      .then(() => {})
      .catch((error) => {
        console.log("error: ", error);
        if (error.response?.status < 500) {
          const notification: ToastNotification = {
            message: "Algo deu errado com sua requisição. Tente novamente.",
            type: "error",
          };

          dispatch(toastCalled(notification));
        }
      })
      .finally(() => {
        setLoading(false);
        setCurrentStep(2);
      });
  };

  const redirectToSignIn = () => {
    window.location.href = "/signin";
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <CreatePasswordForm loading={loading} onCreatePassword={handleRecreatePassword} />;
      case 2:
        return (
          <>
            <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
              Tudo certo!
            </LqdTypography>
            <LqdTypography sx={{ mb: 7 }} textstyle="p2Paragraph">
              Sua senha foi redefinida com sucesso.
            </LqdTypography>
            <LqdButton onClick={redirectToSignIn}>Entrar</LqdButton>
          </>
        );
    }
  };

  return (
    <Box
      id="begin"
      sx={{ mx: { sm: 0, xs: 3 }, pb: 4, pt: { lg: "220px", xs: "110px" }, px: { lg: "20%", md: "10%", sm: 6, xs: 3 } }}
    >
      {renderCurrentStep()}
    </Box>
  );
}

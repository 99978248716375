import { LqdButton, LqdIconButton, LqdTrashIcon } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { ObjectOf } from "../../../../../../common/types/ObjectOf";
import { LayoutProps } from "../SimulatorFunctionBlocksFunctionInputsRenderer";
import SimulatorFunctionBlocksFunctionVariableInput from "../SimulatorFunctionBlocksFunctionVariableInput";

const alphabet = "abcdefghijklmnopqrstuvwxyz";

const functionLabels: ObjectOf<string> = {
  divide: "Divide por",
  multiply: "Multiplica por",
  subtract: "Subtrai por",
  sum: "Soma com",
};

export default function SimulatorFunctionBlocksFunctionDynamicInputLayout(props: LayoutProps) {
  const {
    editVariableButton,
    func,
    newVariableButton,
    onBlurFunction,
    onUpdateFunction,
    openSelectComponent,
    renderOptions,
    resultInputs,
    variableInputs,
    currentBlock,
  } = props;

  /** Gera chaves alfabéticas em vez de numéricas, começando pelo "a".
   * @param index Índice da chave no array
   * @returns A letra do alfabeto correspondente ao índice
   */
  const generateAlphabetKey = (index: number) => {
    return alphabet[index] || String(index + 1);
  };

  const initialVariables =
    Object.values(func.variables).length > 0
      ? Object.keys(func.variables)
      : Object.keys(variableInputs).map((_, index) => generateAlphabetKey(index));
  const [variables, setVariables] = useState(initialVariables);

  /**Adiciona uma nova variável com a próxima letra do alfabeto.*/
  const addVariable = () => {
    const lastVariableLetter = variables[variables.length - 1];
    const newIndex = alphabet.indexOf(lastVariableLetter) + 1;

    const newVariableKey = generateAlphabetKey(newIndex);
    setVariables([...variables, newVariableKey]);
    onUpdateFunction(newVariableKey, "");
  };

  /** Remove uma variável.
   * @param key Chave da variável a ser removida
   */
  const removeVariable = (key: string) => {
    const newVariables = variables.filter((variable) => variable !== key);

    setVariables(newVariables);
    onUpdateFunction(key, "", true);
  };

  /** Renderiza a label da variável condicionalmente, com base no tipo da função.
   * @param index Índice da variável
   */
  const getVariableLabel = (index: number) => {
    const funcName = func.function_name.split("_")[0];

    if (index === 0) return "A variável";
    if (index === 1) return functionLabels[funcName];

    return `E ${functionLabels[funcName]?.toLowerCase()}`;
  };

  return (
    <Stack spacing={1} sx={{ ml: "80px", mt: 1.5 }}>
      {variables.map((key, index) => (
        <Box key={key} sx={{ display: "flex", width: "100%" }}>
          <Box sx={{ flexBasis: "95%", flexGrow: 1 }}>
            <SimulatorFunctionBlocksFunctionVariableInput
              currentBlock={currentBlock}
              editVariable={editVariableButton}
              func={func}
              isOpen={openSelectComponent}
              isResult={false}
              newOption={newVariableButton}
              onChange={(value) => onUpdateFunction(key, value as string)}
              options={renderOptions["variables"]}
              value={func.variables[key] || ""}
              variableData={{
                key: "a",
                variable: {
                  ...variableInputs[Object.keys(variableInputs)[0]],
                  label: [{ title: getVariableLabel(index), type: "name" }],
                },
              }}
            />
          </Box>
          <Box sx={{ flexBasis: "2%", flexGrow: 0 }} />
          <Box sx={{ flexBasis: "3%", flexGrow: 0, mt: 3.5 }}>
            <LqdIconButton
              disabled={index === 0}
              onClick={() => removeVariable(key)}
              round="true"
              size="small"
              type="ghostIcon"
            >
              <LqdTrashIcon />
            </LqdIconButton>
          </Box>
        </Box>
      ))}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LqdButton buttonsize="small" onClick={addVariable}>
          Nova opção
        </LqdButton>
      </Box>
      {Object.entries(resultInputs).map(([key, variable]) => (
        <SimulatorFunctionBlocksFunctionVariableInput
          currentBlock={currentBlock}
          editVariable={editVariableButton}
          func={func}
          isOpen={openSelectComponent}
          isResult={true}
          key={key}
          newOption={null}
          onBlur={onBlurFunction}
          onChange={(value) => onUpdateFunction(key, value as string, false, true)}
          options={[]}
          value={func.result[key]}
          variableData={{ key, variable }}
        />
      ))}
    </Stack>
  );
}

import { LqdBreadcrumb, LqdButton, LqdCircularLoader } from "@/liquid-components/src";
import { toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useErrorHandler } from "../../../../common/utils/useErrorHandler";
import { onUpdateSimulator } from "../../../simulatorBuilderSlice";
import { SimulatorFunctionBlock } from "../../../types/SimulatorFunctionBlock";
import SimulatorResultCardsForm from "./SimulatorResultCardsForm/SimulatorResultCardsForm";
import SimulatorResultHighlightsForm from "./SimulatorResultHighlightsForm/SimulatorResultHighlightsForm";
import SimulatorResultTableForm from "./SimulatorResultTableForm/SimulatorResultTableForm";

type SimulatorResultForm = {
  blocks: Array<SimulatorFunctionBlock>;
  onClose: (saving: boolean) => void;
};

export default function SimulatorResultForm(props: SimulatorResultForm) {
  const { blocks, onClose } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const [loading, setLoading] = useState<boolean>(false);

  // Validação para impedir que uma tabela seja salva sem que todas as células tenham sido preenchidas
  const resultDataValidation = {
    cards: simulator.dashboard.cards.length > 0,
    highlight: simulator.dashboard.highlight.length > 0,
    tables: simulator.dashboard.tables.every(
      (table) =>
        table.columns.every((column) => column.columnName !== null) &&
        table.rows[0].rowContent[0].data?.label !== "" &&
        table.rows.every((row) => row.rowContent.every((tableCell) => tableCell.data?.label !== "")) &&
        table.rows.every((row) => row.rowContent.every((tableCell) => tableCell.data !== null))
    ),
  };

  const maySaveResults = Object.values(resultDataValidation).every((validation) => validation);

  const updateSimulator = async () => {
    setLoading(true);

    try {
      const requestData = { blocks, simulator: simulator, simulatorId: simulator.simulator_id };
      await dispatch(onUpdateSimulator(requestData)).unwrap();
      onClose(true);
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const notification: ToastNotification = {
        message: `Existem variáveis com nome inválido: ${message.invalid_vars.join(",\n")}`,
        type: "error",
      };

      dispatch(toastCalled(notification));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "rgba(240, 241, 243, 1)", py: 1.5, width: "100%" }}>
        <Box sx={{ ml: "100px" }}>
          <LqdBreadcrumb
            options={[{ name: simulator.name, onClick: () => onClose(false) }, { name: "Resultado" }]}
            separator="/"
          />
        </Box>
      </Box>
      <Stack
        alignItems="center"
        direction="column"
        gap={3}
        sx={{ maxWidth: "980px", mt: 2.5, mx: "auto", width: "100%" }}
      >
        <SimulatorResultCardsForm />
        <SimulatorResultHighlightsForm />
        <SimulatorResultTableForm />
        <Stack alignContent="flex-end" sx={{ pt: 1.5, width: "100%" }}>
          <LqdButton
            disabled={loading || !maySaveResults}
            onClick={updateSimulator}
            sx={{ alignSelf: "flex-end", mb: 3 }}
          >
            {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Salvar"}
          </LqdButton>
        </Stack>
      </Stack>
    </>
  );
}

import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { PeerData } from "../../../../types/AnalysisDetails/PeerData/PeerData";
import { formatDocument, parseString } from "../../../../utils/analysisDetailsDataFormatter";

type PDFPeerDataProps = {
  isFirst: boolean;
  user: PeerData;
};

const styles = StyleSheet.create({
  peerIDPass: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
  },
  peerInnerContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "25px",
    paddingRight: "40px",
  },
  peerLabel: {
    color: "rgb(212, 215, 220)",
    fontSize: "14px",
    marginTop: "5px",
  },
  peerName: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    marginTop: "5px",
  },
  peerPeerType: {
    borderRadius: "20px",
    fontSize: "12px",
    paddingBottom: "5px",
    paddingLeft: "6px",
    paddingRight: "5px",
    paddingTop: "6px",
    textAlign: "center",
  },
});

export default function PDFPeerData(props: PDFPeerDataProps) {
  const { user, isFirst } = props;

  // const getPeerTypeWidth = (label: string) => {
  //   let size = 0;

  //   const splitLabel = label.split("");
  //   splitLabel.forEach(() => (size += 8));

  //   return `${size}px`;
  // };

  return (
    <View style={styles.peerInnerContainer}>
      <Text
        style={{
          ...styles.peerPeerType,
          alignSelf: "flex-start",
          backgroundColor: `${isFirst ? "rgb(33, 36, 42)" : "transparent"}`,
          border: `${isFirst ? "transparent" : "1px solid rgb(127, 135, 152)"}`,
          color: `${isFirst ? "rgb(255, 255, 255)" : "rgb(127, 135, 152)"}`,
        }}
      >
        {user.peer}
      </Text>
      <Text style={styles.peerName}>{user.fullName}</Text>
      <Text style={styles.peerLabel}>User ID</Text>
      <Text style={styles.peerIDPass}>{user.passId}</Text>
      <Text style={styles.peerLabel}>{parseString(user.document).length === 11 ? "CPF" : "CNPJ"}</Text>
      <Text style={styles.peerIDPass}>{formatDocument(user.document)}</Text>
    </View>
  );
}

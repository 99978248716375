import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterTwelve() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        12. ATUALIZAÇÃO DA POLÍTICA
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.1. Esta Política poderá ser alterada ou substituída a qualquer tempo, a exclusivo critério da Liquid, sem
        necessidade de prévia notificação, comprometendo-se a Empresa a manter o inteiro teor deste documento sempre
        atualizado e disponível para acesso através de nosso Site www.askliquid.com.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.2. Recomendamos que você revise periodicamente esta Política, pois as regras e informações sobre o Tratamento
        de seus Dados Pessoais estará sempre vinculado à sua versão mais recente.
      </LqdTypography>
    </>
  );
}

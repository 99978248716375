import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, CircularProgress, Stack } from "@mui/material";
import { parseRawSimulator, parseSimulatorTesterResult } from "@simulatorBuilder/utils/transformSimulatorTypes";
import { useState } from "react";
import { useAppSelector } from "../../../../store";
import { getDateFormat } from "../../../analysisDetails/utils/analysisDetailsDataFormatter";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { testSimulator } from "../../api/simulationTest";
import { SimulatorVariable } from "../../types/SimulatorVariable";
import { SimulatorTesterResult } from "../../types/simulationTester";
import SimulationContentToTest from "./SimulationContentToTest";
import SimulationTestResult from "./SimulationTestResult";

type SimulationTesterProps = {
  id: string;
};

export default function SimulationTester(props: SimulationTesterProps) {
  const { id } = props;

  const handleLiquidErrors = useErrorHandler();

  const simulators = useAppSelector((state) => state.simulatorBuilder.simulators);
  const simulator = simulators[id as string];
  const blocks = [...simulator!.blocks];
  const dashboard = { ...simulator!.dashboard };

  const [reqResult, setReqResult] = useState<SimulatorTesterResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [variables, setVariables] = useState<Array<SimulatorVariable>>([
    ...simulator!.variables.map((variable) => {
      if (variable.name === "data_de_hoje" || variable.name === "date_now") {
        const todaysDate = new Date();
        const todaysTimestamp = getDateFormat(Math.floor(todaysDate.getTime() / 1000));

        const updatedVariable = { ...variable, value: todaysTimestamp };
        return updatedVariable;
      }
      if (variable.name === "data_do_peer_mais_velho" || variable.name === "oldest_peer_date") {
        const DEFAULT_BIRTH_DATE = "01/01/1995";

        const updatedVariable = { ...variable, value: DEFAULT_BIRTH_DATE };
        return updatedVariable;
      }

      return variable;
    }),
  ]);

  const handleChange = (eventTarget: string, variableToChange: SimulatorVariable) => {
    const updatedVariables = variables.map((variable) =>
      variable.name === variableToChange.name ? { ...variable, value: eventTarget } : variable
    );

    setVariables([...updatedVariables]);
  };

  const submitTest = async () => {
    setLoading(true);

    const updatedSimulator = { ...simulator!, variables: variables };
    const body = parseRawSimulator(updatedSimulator, blocks);

    try {
      const { data } = await testSimulator(body);
      const parsedData = parseSimulatorTesterResult(data);
      setReqResult(parsedData);
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const resetTest = () => {
    setReqResult(null);
    setVariables(simulator!.variables);
  };

  return (
    <Box sx={{ boxSizing: "border-box", height: "100vh", p: 3 }}>
      <SimulationContentToTest blocks={blocks} dashboard={dashboard} variables={variables} />
      <LqdTypography sx={{ fontWeight: 600, mt: 3, textAlign: "center" }} textstyle="h2Headline">
        Preencha todas as variáveis primeiro e depois clique em testar:
      </LqdTypography>
      <LqdTypography sx={{ fontWeight: 600 }} textstyle="h4Headline">
        Variáveis:
      </LqdTypography>
      <Stack direction="row" flexWrap="wrap" gap={3} justifyContent="center" spacing={3} sx={{ mb: 3 }}>
        {variables.map((variable) => {
          const desiredVariable =
            variable.is_input === true ||
            variable.name === "data_de_hoje" ||
            variable.name === "data_do_peer_mais_velho" ||
            variable.name === "date_now" ||
            variable.name === "oldest_peer_date";

          return desiredVariable ? (
            <Stack direction="column" gap={1} key={variable.name} sx={{ my: 1 }}>
              <Stack direction="row" spacing={3} sx={{ pl: 2 }}>
                <LqdTypography color="rgba(36, 38, 42, 1)" sx={{ fontWeight: 600 }}>
                  - {variable.name}:
                </LqdTypography>
                <input onChange={(event) => handleChange(event.target.value, variable)} value={variable.value} />
              </Stack>
            </Stack>
          ) : null;
        })}
      </Stack>
      <Stack alignItems="center" direction="row" justifyContent="center" spacing={3}>
        <LqdButton disabled={loading} onClick={submitTest} sx={{ mt: 3 }}>
          {loading ? <CircularProgress size={24} sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Testar"}
        </LqdButton>
        <LqdButton onClick={resetTest} sx={{ mt: 3 }}>
          Resetar
        </LqdButton>
        <LqdTypography
          color="rgba(36, 38, 42, 1)"
          sx={{ display: reqResult !== null ? "auto" : "none" }}
          textstyle="h5Headline"
        >
          Scrolle para baixo!
        </LqdTypography>
      </Stack>
      <SimulationTestResult result={reqResult} />
    </Box>
  );
}

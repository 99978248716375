import { dialogCalled, dialogLoaded } from "@common/commonSlice";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Box } from "@mui/material";
import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorFunctionBlocksFormViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksFormViews";
import { Dispatch, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { SimulatorFunctionBlock } from "../../../../types/SimulatorFunctionBlock";
import { SimulatorVariable } from "../../../../types/SimulatorVariable";
import { SimulatorVariableTemplateType } from "../../../../types/SimulatorVariableTypeTemplate";
import SimulatorFunctionBlocksFormNewVariableModalLabel from "./SimulatorFunctionBlocksFormNewVariableModalLabel";
import SimulatorFunctionBlocksFormNewVariableModalName from "./SimulatorFunctionBlocksFormNewVariableModalName";
import SimulatorFunctionBlocksFormNewVariableModalType from "./SimulatorFunctionBlocksFormNewVariableModalType";
import SimulatorFunctionBlocksFormNewVariableModalValue from "./SimulatorFunctionBlocksFormNewVariableModalValue";

type SimulatorFunctionBlocksFormNewVariableModalProps = {
  blockId: number;
  blocks: Array<SimulatorFunctionBlock>;
  createVariable: () => SimulatorVariable;
  currentView: SimulatorFunctionBlocksFormViews;
  functionId: number;
  functionKey: string;
  handleVariableCloseModal: () => void;
  loading: boolean;
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void;
  setCurrentView: (view: SimulatorFunctionBlocksFormViews) => void;
  setVariableLabel: Dispatch<SetStateAction<string>>;
  setVariableName: (name: string) => void;
  setVariableType: (type: SimulatorVariableTemplateType) => void;
  setVariableValue: (value: boolean | string) => void;
  variableLabel: string;
  variableName: string;
  variableType: SimulatorVariableTemplateType;
  variableValue: boolean | string;
};

export default function SimulatorFunctionBlocksFormNewVariableModal(
  props: SimulatorFunctionBlocksFormNewVariableModalProps
) {
  const {
    blockId,
    blocks,
    createVariable,
    currentView,
    functionId,
    functionKey,
    handleVariableCloseModal,
    loading,
    setBlocks,
    setCurrentView,
    setVariableLabel,
    setVariableName,
    setVariableType,
    setVariableValue,
    variableLabel,
    variableName,
    variableType,
    variableValue,
  } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { selectedVariable, simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulatorVariables = simulatorData!.variables;
  const currentBlock = blocks.find((block) => block.block_id === blockId)!;
  const currentFunction = currentBlock.functions.find((funct) => funct.index === functionId)!;

  const handleGoBack = () => {
    switch (currentView) {
      case "variableType":
        setCurrentView("variableName");
        setVariableType("" as SimulatorVariableTemplateType);
        break;
      case "variableLabel":
        setCurrentView("variableType");
        setVariableType("" as SimulatorVariableTemplateType);
        break;
      case "variableValue":
        setCurrentView("variableLabel");
        setVariableValue("");
        break;
      default:
        break;
    }
  };

  const handleDeleteVariable = () => {
    dispatch(dialogLoaded(true));

    const updatedSimulatorVariables = simulatorVariables.filter((variable) => variable.id !== selectedVariable?.id);
    const updatedFunction = {
      ...currentFunction,
      variables: {
        ...currentFunction?.variables,
        [selectedVariable?.functionKey || functionKey]: "",
      },
    };

    const updatedFunctions = currentBlock!.functions.map((funct) =>
      funct.index === functionId ? updatedFunction : funct
    );

    const updatedBlock = { ...currentBlock!, functions: updatedFunctions };
    const updatedBlocks = blocks.map((block) => (block.block_id === blockId ? updatedBlock : block));

    try {
      setBlocks(updatedBlocks);
      dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocks }));
      dispatch(simulatorBuilderChanged({ key: "variables", value: updatedSimulatorVariables }));
      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogLoaded(false));
      handleVariableCloseModal();
    }
  };

  const handleDeleteModal = () => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Voltar" }, { onClick: () => handleDeleteVariable(), title: "Confirmar Exclusão" }],
        body: "Excluir a variável poderá impedir o funcionamento das funções que a utilizam. Deseja continuar?",
        title: "Atenção",
        type: "default",
      })
    );
  };

  const renderCurrentView = {
    variableLabel: (
      <SimulatorFunctionBlocksFormNewVariableModalLabel
        handleDeleteVariable={handleDeleteModal}
        handleGoBack={handleGoBack}
        setCurrentView={setCurrentView}
        setVariableLabel={setVariableLabel}
        variableLabel={variableLabel}
        variableName={variableName}
      />
    ),
    variableName: (
      <SimulatorFunctionBlocksFormNewVariableModalName
        handleDeleteVariable={handleDeleteModal}
        setCurrentView={setCurrentView}
        setVariableName={setVariableName}
        variableName={variableName}
      />
    ),
    variableType: (
      <SimulatorFunctionBlocksFormNewVariableModalType
        blockId={blockId}
        blocks={blocks}
        functionId={functionId}
        functionKey={functionKey}
        handleDeleteVariable={handleDeleteModal}
        handleGoBack={handleGoBack}
        setCurrentView={setCurrentView}
        setVariableType={setVariableType}
        variableType={variableType}
      />
    ),
    variableValue: (
      <SimulatorFunctionBlocksFormNewVariableModalValue
        blockId={blockId}
        blocks={blocks}
        createVariable={createVariable}
        functionId={functionId}
        functionKey={functionKey}
        handleDeleteVariable={handleDeleteModal}
        handleGoBack={handleGoBack}
        loading={loading}
        setBlocks={setBlocks}
        setVariableValue={setVariableValue}
        variableType={variableType}
        variableValue={variableValue}
      />
    ),
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "874px" }}>{renderCurrentView[currentView]}</Box>
    </Box>
  );
}

import { LqdTextFieldBox, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorFunctionTemplateVariableLabel } from "@simulatorBuilder/types/SimulatorFunctionTemplate";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import formatVariableName from "@simulatorBuilder/utils/formatVariableName";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../store";
import { VariableInputProps } from "./SimulatorFunctionBlocksFunctionInputsRenderer";

const CHARACTER_LIMIT = 35;

export default function SimulatorFunctionBlocksFunctionVariableTextField(props: VariableInputProps) {
  const { onBlur, onChange, value, variableData, func, currentBlock } = props;
  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const [resultVariableLabel, setResultVariableLabel] = useState<string>(
    simulatorData?.variables.find((v) => v.name === value)?.label || ""
  );

  const dispatch = useDispatch();

  const handleResultVariableLabelBlur = (label: string) => {
    // Esse array serve para manter a tipagem dentro do componente do lqd componentes.
    // O retorno do onBlur, o qual executa essa função é: Array<SimulatorVariable>
    // Por isso o retorno do array vazio
    const emptyList: Array<SimulatorVariable> = [];
    // Sim. é uma gambiarra.

    const simulatorVariables = simulatorData!.variables;
    const resultVariable = simulatorVariables.find((v) => v.name === value);

    if (!resultVariable) return emptyList;

    const updatedVariable: SimulatorVariable = { ...resultVariable, label: label };

    const updatedSimulatorVariables = simulatorVariables.map((variable) => {
      return variable.name === resultVariable.name ? updatedVariable : variable;
    });

    const updatedCurrentBlockFuncs = currentBlock.functions.map((funct, index) => {
      // index das funções inicia em 1.
      const targetFunctIndex = index + 1;
      return targetFunctIndex === func.index ? func : funct;
    });

    const updatedCurrentBlock = { ...currentBlock, functions: updatedCurrentBlockFuncs };

    const updatedBlocks = simulatorData!.blocks.map((block) => {
      return block.block_id === updatedCurrentBlock.block_id ? updatedCurrentBlock : block;
    });

    dispatch(simulatorBuilderChanged({ key: "variables", value: [...updatedSimulatorVariables] }));
    dispatch(simulatorBuilderChanged({ key: "blocks", value: [...updatedBlocks] }));

    return emptyList;
  };

  return (
    <Stack alignItems={"flex-end"} flexDirection="row" gap={1}>
      {(variableData.variable.label as Array<SimulatorFunctionTemplateVariableLabel>).map((label) => {
        const inputValue = label.type === "name" ? formatVariableName((value || "").toString()) : resultVariableLabel;

        return (
          <Stack key={label.type} width={"100%"}>
            <LqdTextFieldBox
              disabled={label.type === "label" ? ((value as string) || "").length < 1 : false}
              label={label.title}
              labelType={label.type}
              onBlur={label.type === "name" ? onBlur : handleResultVariableLabelBlur}
              onChange={label.type === "name" ? onChange : setResultVariableLabel}
              placeholder={label.type === "name" ? "nome_da_variavel" : "nome da variável"}
              value={inputValue}
            />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", mt: 1 }}>
              <LqdTypography
                color="rgba(246, 61, 94, 1)"
                sx={{
                  display: inputValue.length > CHARACTER_LIMIT ? "flex" : "none",
                }}
                textstyle="c1Caption"
              >
                Você ultrapassou o limite de {CHARACTER_LIMIT} caracteres, abrevie para continuar.
              </LqdTypography>
              <LqdTypography
                color={inputValue.length > CHARACTER_LIMIT ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)"}
                sx={{ alignSelf: "self-end", opacity: 1, textAlign: "right" }}
                textstyle="p2Paragraph"
              >
                {`${inputValue.length || 0}
                    /${CHARACTER_LIMIT} caracteres`}
              </LqdTypography>
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
}

import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterSix() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        6. CONSULTA DE DADOS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        6.1. Para a viabilização das Soluções da Liquid, Bancos de Dados públicos e/ou de terceiros podem ser acessados
        e utilizados como fontes de informação, dentre os quais destacamos: SERASA, Boa Vista, SPC, SCR, BACEN, Open
        Finance, etc.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        6.2. Serão coletados apenas os Dados necessários para alcançar as finalidades estabelecidas pelo Usuário
        Decisor, nos termos desta Política.
      </LqdTypography>
    </>
  );
}

import { LqdEditIcon, LqdIconButton, LqdLink, LqdToggle, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box, Grid } from "@mui/material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAppDispatch } from "../../../../store";
import { ObjectOf } from "../../../common/types/ObjectOf";
import { editTemplateModalOpened } from "../../adminSlice";
import { SegmentResponse } from "../../types/SegmentResponse";
import { TemplateResponse } from "../../types/TemplateResponse";

type TemplateRowProps = {
  handleTemplateStatusChange: (template: TemplateResponse) => void;
  segments: ObjectOf<SegmentResponse>;
  template: TemplateResponse;
};

export default function TemplateRow(props: TemplateRowProps) {
  const { handleTemplateStatusChange, segments, template } = props;

  const dispatch = useAppDispatch();

  const firstThreeSegments = template.segments.slice(0, 3);
  const extraSegmentsLength = template.segments.length - firstThreeSegments.length;

  return (
    <Grid
      columns={{ lg: 16, md: 20 }}
      container
      sx={{
        "&:hover": { backgroundColor: "rgba(127, 135, 152, 0.08)" },
        borderBottom: "1px solid rgba(65, 70, 103, 0.24)",
        pb: "11px",
        pt: 3,
      }}
    >
      <Grid item lg={5} md={7} sx={{ display: "flex", flexDirection: "column", pl: 3 }}>
        <LqdTypography textstyle="p2Paragraph">{template.name}</LqdTypography>
        <LqdLink
          onClick={() => dispatch(editTemplateModalOpened({ step: 1, template }))}
          sx={{ textDecoration: "none", width: "fit-content" }}
        >
          <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
            Editar
          </LqdTypography>
        </LqdLink>
      </Grid>
      <Grid item lg={3} md={3} sx={{ display: "flex", flexDirection: "column" }}>
        <LqdTypography sx={{ mt: 1 }} textstyle="p2Paragraph">
          {`${template.country}/${template.currency}`}
        </LqdTypography>
      </Grid>
      <Grid item lg={3} md={3} sx={{ display: "flex", flexDirection: "column" }}>
        <LqdTooltip
          placement="left"
          titlebody={
            firstThreeSegments.length > 0 ? (
              <>
                {firstThreeSegments.map((segmentId, index) => (
                  <Box key={`${segmentId}-${index}`}>{segments[segmentId].name}</Box>
                ))}
                {extraSegmentsLength > 0 ? <Box>{`+ ${extraSegmentsLength} outros`}</Box> : null}
              </>
            ) : (
              "Nenhum segmento"
            )
          }
          titleheader="Segmentos"
        >
          <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
            <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", display: "inline" }} textstyle="p2Paragraph">
              {template.segments.length > 9 ? template.segments.length : "0" + template.segments.length}
            </LqdTypography>
            <LqdLink
              onClick={() => dispatch(editTemplateModalOpened({ step: 2, template }))}
              sx={{ textDecoration: "none", width: "fit-content" }}
            >
              <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
                Editar
              </LqdTypography>
            </LqdLink>
          </Box>
        </LqdTooltip>
      </Grid>
      <Grid item lg={2.5} md={4} sx={{ display: "flex", flexDirection: "column" }}>
        <LqdTypography textstyle="p2Paragraph">
          {format(template.createdAt, "dd MMM yyyy", { locale: ptBR })}
        </LqdTypography>
        <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", mt: 0.5 }} textstyle="p2Paragraph">
          {format(template.createdAt, "HH:mm", { locale: ptBR })}
        </LqdTypography>
      </Grid>
      <Grid item lg={2} md={3} sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", pr: 2 }}>
        <LqdToggle active={template.isActive} onChange={() => handleTemplateStatusChange(template)} renderText />
      </Grid>
      <Grid item lg={0.5} md={3} sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", pr: 2 }}>
        <LqdIconButton
          onClick={() => dispatch(editTemplateModalOpened({ step: 3, template }))}
          sx={{ ml: -3, mt: -1 }}
          type="ghostIcon"
        >
          <LqdEditIcon />
        </LqdIconButton>
      </Grid>
    </Grid>
  );
}

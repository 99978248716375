import { ModalStepType, SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { SimulatorVariableTypes } from "@simulatorBuilder/types/SimulatorVariableTypes";
import { Dispatch, SetStateAction, useState } from "react";
import SimulatorVariableModalForm from "./SimulatorVariableModalForm";
import SimulatorVariableTypeForm from "./SimulatorVariableTypeForm";

type SimulatorVariableEditFormProps = {
  modalStep: ModalStepType;
  setModalStep: (modalStep: ModalStepType) => void;
  setOpenEditVariableNameModal: (open: boolean) => void;
  setVariableBeingEdited: Dispatch<SetStateAction<SimulatorVariable | null>>;
  variableBeingEdited: SimulatorVariable | null;
  variableBeingEditedIndex: null | number;
};

export default function SimulatorVariableEditForm(props: SimulatorVariableEditFormProps) {
  const {
    modalStep,
    setModalStep,
    variableBeingEdited,
    variableBeingEditedIndex,
    setOpenEditVariableNameModal,
    setVariableBeingEdited,
  } = props;

  const [selectedType, setSelectedType] = useState<SimulatorVariableTypes | "">("");

  return modalStep === "type" ? (
    <SimulatorVariableTypeForm
      onBack={() => {
        setModalStep("");
        setOpenEditVariableNameModal(false);
      }}
      onNext={() => setModalStep("name")}
      selectedType={selectedType}
      setSelectedType={setSelectedType}
      setVariableBeingEdited={setVariableBeingEdited}
      variable={variableBeingEdited!}
      variableIndex={variableBeingEditedIndex!}
    />
  ) : (
    <SimulatorVariableModalForm
      modalStep={modalStep}
      onBack={() => {
        setModalStep(modalStep === "label" ? "name" : "type");
      }}
      onNext={() => {
        if (modalStep === "name") setModalStep("label");
        if (modalStep === "label") {
          setModalStep("");
          setOpenEditVariableNameModal(false);
        }
      }}
      variable={variableBeingEdited!}
      variableIndex={variableBeingEditedIndex!}
    />
  );
}

import FullscreenDialog from "@common/components/FullscreenDialog";
import { Box } from "@mui/material";
import defineVariableToTableCell from "@simulatorBuilder/utils/defineVariableToTableCell";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { simulatorBuilderChanged } from "../../../../simulatorBuilderSlice";
import {
  SimulatorDashboard,
  SimulatorResultTableColumn,
  SimulatorResultTableFormRenderView,
  SimulatorResultTableRowCells,
} from "../../../../types/SimulatorDashboard";
import SimulatorColumnNameForm from "./SimulatorColumnNameForm";
import SimulatorResultTableComponent from "./SimulatorResultTableComponent";
import createInitialSimulatorTables from "./SimulatorResultTableMethods/createInitialSimulatorTables";
import { existingTablesNewFixedColumn } from "./SimulatorResultTableMethods/existingTablesNewFixedColumn";
import { reorderTableColumns } from "./SimulatorResultTableMethods/handleColumnOrderChange";

type SimulatorResultTableViewProps = {
  handleColumnNameClick: (columnIndex: number, tableIndex: number) => void;
};

// TODO: a coluna deve ser inserida no index respectivo que ela foi "spliçada"

export default function SimulatorResultTableView(props: SimulatorResultTableViewProps) {
  const { handleColumnNameClick } = props;

  const [currentView, setCurrentView] = useState<SimulatorResultTableFormRenderView>("tableView");
  const [tableColumnBeingChanged, setTableColumnBeingChanged] = useState<SimulatorResultTableColumn | null>(null);
  const [editRowProps, setEditRowProps] = useState<{
    columnIndex: number;
    rowIndex: number;
    tableIndex: number;
    tableRowName: string;
  } | null>(null);

  const dispatch = useAppDispatch();

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const tableContent = simulator.dashboard.tables;
  const simulatorDashboard: SimulatorDashboard = {
    cards: simulator.dashboard.cards,
    highlight: simulator.dashboard.highlight,
    tables: [],
  };

  useEffect(() => {
    existingTablesNewFixedColumn(
      tableContent,
      simulatorDashboard,
      dispatch,
      simulatorBuilderChanged,
      createInitialSimulatorTables
    );
  }, []);

  const handleTableChange = (tableColumnName: string, columnIndex: number, rowIndex: number, tableIndex: number) => {
    return defineVariableToTableCell(
      tableColumnName,
      columnIndex,
      rowIndex,
      tableIndex,
      tableContent,
      simulatorDashboard,
      simulator,
      dispatch
    );
  };

  const resetView = () => {
    const { tableRowName, columnIndex, rowIndex, tableIndex } = editRowProps!;
    setCurrentView("tableView");
    setTableColumnBeingChanged(null);
    handleTableChange(tableRowName, columnIndex, rowIndex, tableIndex);
  };

  const renderView = () => {
    if (currentView === "changeColumnName") {
      return (
        <FullscreenDialog
          onClose={() => {
            setCurrentView("tableView");
            setTableColumnBeingChanged(null);
          }}
          open={true}
          title="Editar linha"
        >
          <SimulatorColumnNameForm
            columnNameBeingChanged={tableColumnBeingChanged!}
            firstColumnRow={true}
            resetView={resetView}
            setEditRowProps={setEditRowProps}
          />
        </FullscreenDialog>
      );
    }
    return null;
  };

  /**
   * Add a new column to the table
   * @param tableIndex Table index
   */
  const addNewTableColumn = (tableIndex: number) => {
    const table = { ...tableContent[tableIndex] };
    const tableColumns = [...table.columns];

    const updatedTableContent = [...tableContent.slice(0, tableIndex), ...tableContent.slice(tableIndex + 1)];

    const newColumn = { columnId: uuid(), columnName: null };

    const updatedColumns = [...tableColumns, newColumn];
    const updatedRows = table.rows.map((row) => ({
      ...row,
      rowContent: [...row.rowContent, { data: null, id: newColumn.columnId }],
    }));

    const updatedTable = { ...table, columns: updatedColumns, rows: updatedRows };

    const updatedSimulatorDashboard = {
      ...simulatorDashboard,
      tables: [...updatedTableContent.slice(0, tableIndex), updatedTable, ...updatedTableContent.slice(tableIndex)],
    };

    return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
  };

  /**
   * Add a new row to the table
   * @param tableIndex Table index
   */
  const addNewTableRow = (tableIndex: number) => {
    const table = { ...tableContent[tableIndex] };
    const { rows, columns } = table;

    const updatedTableContent = [...tableContent.slice(0, tableIndex), ...tableContent.slice(tableIndex + 1)];
    const newRowCells: SimulatorResultTableRowCells = columns.map((column) => ({ data: null, id: column.columnId }));

    const updatedRows = [...rows, { rowContent: newRowCells, rowId: rows.length }];
    const updatedTable = { ...table, rows: updatedRows };

    const updatedSimulatorDashboard = {
      ...simulatorDashboard,
      tables: [...updatedTableContent.slice(0, tableIndex), updatedTable, ...updatedTableContent.slice(tableIndex)],
    };

    return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
  };

  /**
   * Remove a table column
   * @param removeColumnId Column id to be removed
   * @param tableIndex Table index
   */
  const deleteTableColumn = (removeColumnId: string, tableIndex: number) => {
    const table = { ...tableContent[tableIndex] };

    const updatedTableContent = [...tableContent.slice(0, tableIndex), ...tableContent.slice(tableIndex + 1)];

    if (table.columns.length === 2) {
      const tableColumnId = table.columns[1].columnId;
      const updatedColumns = [
        ...table.columns.slice(0, 1),
        {
          columnId: tableColumnId,
          columnName: null,
        },
      ];

      const updatedTable = { ...table, columns: updatedColumns };

      const updatedSimulatorDashboard = {
        ...simulatorDashboard,
        tables: [...updatedTableContent.slice(0, tableIndex), updatedTable, ...updatedTableContent.slice(tableIndex)],
      };

      return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
    }

    const tableColumnToRemove = table.columns.find(({ columnId }) => columnId === removeColumnId);
    if (!tableColumnToRemove) return;

    const tableColumnToRemoveIndex = table.columns.indexOf(tableColumnToRemove);
    const updatedColumns = table.columns.filter((_, index) => index !== tableColumnToRemoveIndex);
    const updatedRows = table.rows.map((row) => ({
      ...row,
      rowContent: row.rowContent.filter((_, index) => index !== tableColumnToRemoveIndex),
    }));

    const updatedTable = { ...table, columns: updatedColumns, rows: updatedRows };

    const updatedSimulatorDashboard = {
      ...simulatorDashboard,
      tables: [...updatedTableContent.slice(0, tableIndex), updatedTable, ...updatedTableContent.slice(tableIndex)],
    };

    return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
  };

  /**
   * Remove a table row
   * @param rowIndex Row index
   * @param columnIndex Column index
   * @param tableIndex Table index
   */
  const deleteTableRow = (rowIndex: number, columnIndex: number, tableIndex: number) => {
    const table = { ...tableContent[tableIndex] };
    const updatedTableContent = [...tableContent.slice(0, tableIndex), ...tableContent.slice(tableIndex + 1)];

    let updatedTable;
    let updatedSimulatorDashboard;

    switch (true) {
      case table.rows.length === 1 &&
        table.rows[0].rowContent.filter((tableCell) => tableCell.data !== null).length === 1: {
        const updatedRowContent = table.rows[rowIndex].rowContent.map((cell, index) =>
          index === columnIndex ? { ...cell, data: null } : cell
        );
        const updatedRows = table.rows.map((row, index) =>
          index === rowIndex ? { ...row, rowContent: updatedRowContent } : row
        );
        updatedTable = { ...table, rows: updatedRows };
        updatedSimulatorDashboard = {
          ...simulatorDashboard,
          tables: [...updatedTableContent.slice(0, tableIndex), updatedTable, ...updatedTableContent.slice(tableIndex)],
        };

        return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
      }

      case table.rows.length > 1 &&
        table.rows[rowIndex].rowContent.filter((tableCell) => tableCell.data !== null).length === 1: {
        const updatedRows = table.rows.filter((_, index) => index !== rowIndex);
        updatedTable = { ...table, rows: updatedRows };
        updatedSimulatorDashboard = {
          ...simulatorDashboard,
          tables: [...updatedTableContent.slice(0, tableIndex), updatedTable, ...updatedTableContent.slice(tableIndex)],
        };

        return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
      }

      default: {
        const updatedRowContent = table.rows[rowIndex].rowContent.map((cell, index) =>
          index === columnIndex ? { ...cell, data: null } : cell
        );
        const updatedRows = table.rows.map((row, index) =>
          index === rowIndex ? { ...row, rowContent: updatedRowContent } : row
        );
        updatedTable = { ...table, rows: updatedRows };
        updatedSimulatorDashboard = {
          ...simulatorDashboard,
          tables: [...updatedTableContent.slice(0, tableIndex), updatedTable, ...updatedTableContent.slice(tableIndex)],
        };

        return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
      }
    }
  };

  /**
   * Reseta a coluna de índice 1: Altera o nome e remove os dados da coluna.
   * @param tableIndex Table index
   */
  const resetColumn = (tableIndex: number) => {
    const table = { ...tableContent[tableIndex] };

    const updatedTableContent = [...tableContent.slice(0, tableIndex), ...tableContent.slice(tableIndex + 1)];

    const updatedColumns = table.columns.map((column, index) =>
      index === 1 ? { ...column, columnName: "Nome da coluna" } : column
    );

    const updatedRows = table.rows.map((row) => ({
      ...row,
      rowContent: row.rowContent.map((cell, index) => (index === 1 ? { ...cell, data: null } : cell)),
    }));

    const updatedTable = { ...table, columns: updatedColumns, rows: updatedRows };

    const updatedSimulatorDashboard = {
      ...simulatorDashboard,
      tables: [...updatedTableContent.slice(0, tableIndex), updatedTable, ...updatedTableContent.slice(tableIndex)],
    };

    return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
  };

  const handleReorderColumn = (newOrder: Array<string>, tableIndex: number) => {
    reorderTableColumns(newOrder, tableIndex, tableContent, simulatorDashboard, dispatch, simulatorBuilderChanged);
  };

  return (
    // TODO: passar essa box como um wrapper component
    <>
      <Box sx={{ overflowX: "auto", width: "100%" }}>
        {tableContent.map((table, index) => (
          <SimulatorResultTableComponent
            handleColumnNameClick={handleColumnNameClick}
            handleTableChange={handleTableChange}
            key={index}
            onColumnOrderChange={handleReorderColumn}
            renderView={renderView}
            setCurrentView={setCurrentView}
            setEditRowProps={setEditRowProps}
            setTableColumnBeingChanged={setTableColumnBeingChanged}
            table={table}
            tableIndex={index}
            tableMethods={{
              addColumn: addNewTableColumn,
              addRow: addNewTableRow,
              deleteColumn: deleteTableColumn,
              deleteRow: deleteTableRow,
              resetColumn: resetColumn,
            }}
          />
        ))}
      </Box>
      {renderView()}
    </>
  );
}

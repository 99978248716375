import { LqdSelect, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { SimulatorFunctionTemplateVariableLabel } from "@simulatorBuilder/types/SimulatorFunctionTemplate";
import { cloneElement } from "react";
import { useAppSelector } from "../../../../../../store";
import { ObjectOf } from "../../../../../common/types/ObjectOf";
import { SimulatorVariableTemplateType } from "../../../../types/SimulatorVariableTypeTemplate";
import { VariableInputProps } from "./SimulatorFunctionBlocksFunctionInputsRenderer";

export default function SimulatorFunctionBlocksFunctionVariableSelect(props: VariableInputProps) {
  const { editVariable, func, isOpen, newOption, onChange, options, value, variableData } = props;

  const { functionTemplates } = useAppSelector((state) => state.simulatorBuilder);
  const placeholderText: ObjectOf<string> = {
    blocks: "Selecione ou crie um bloco",
    comparators: "Selecione um comparador",
    variables: "Busque ou crie uma variável",
  };

  const optionsToRender = options.filter((option) =>
    functionTemplates
      .find((template) => template.function_name === func.function_name)
      ?.variables[variableData.key]?.types.includes(option.type as SimulatorVariableTemplateType)
  );

  const variableButton = newOption ? cloneElement(newOption as JSX.Element, { id: variableData.key }) : null;

  // A função comparadora de booleanos não deve possuir label conforme figma. Esta é a única diferença para o layout da função compare default.
  const nullLabel = variableData.variable.label === "";

  const isLabelString = typeof variableData.variable.label;

  return (
    <Box>
      <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1, pt: nullLabel ? 2.5 : 0 }} textstyle="p2Paragraph">
        {isLabelString === "string"
          ? (variableData.variable.label as string)
          : (variableData.variable.label as Array<SimulatorFunctionTemplateVariableLabel>)[0].title}
      </LqdTypography>
      <LqdSelect
        editButton={editVariable}
        functionKey={variableData.key}
        isOpened={isOpen}
        newOptionButton={variableButton}
        onChange={onChange}
        options={optionsToRender}
        placeholder={placeholderText[variableData.variable.select]}
        value={value}
      />
    </Box>
  );
}

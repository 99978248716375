import { LqdButton, LqdDoneFilledIcon, LqdTypography, LqdUserThreeIcon } from "@/liquid-components/src";
import LeftArrow from "@/liquid-components/src/icons/LeftArrow";
import { LqdIconProps } from "@/liquid-components/src/types/IconProps";
import { Box, Divider, Stack } from "@mui/material";
import { onChangingView } from "@signature/signatureSlice";
import { SignatureViews } from "@signature/types/signatureViews";
import { formatSignatureDate } from "@signature/utils/signatureDateFormatter";
import { getDocumentName } from "@signature/utils/signatureDocument";
import { useAppDispatch, useAppSelector } from "../../../store";
import SignatureHeader from "./SignatureHeader";

// TODO: isso não fica aqui, e está fora do padrão dos demais ícones da aplicação.
function HourGlass(props: LqdIconProps) {
  const { size } = props;
  return (
    <svg fill="none" height={size} viewBox="0 0 12 13" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.875 0.316406C1.66875 0.316406 1.5 0.485156 1.5 0.691406C1.5 0.897656 1.66875 1.06641 1.875 1.06641H2.25V2.04141C2.25 3.19922 2.78438 4.29141 3.69844 5.00156L5.38828 6.31641L3.69844 7.63125C2.78438 8.34141 2.25 9.43594 2.25 10.5914V11.5664H1.875C1.66875 11.5664 1.5 11.7352 1.5 11.9414C1.5 12.1477 1.66875 12.3164 1.875 12.3164H2.625H9.375H10.125C10.3313 12.3164 10.5 12.1477 10.5 11.9414C10.5 11.7352 10.3313 11.5664 10.125 11.5664H9.75V10.5914C9.75 9.43359 9.21563 8.34141 8.30156 7.63125L6.61172 6.31641L8.30391 5.00156C9.21797 4.29141 9.75234 3.19922 9.75234 2.04141V1.06641H10.1273C10.3336 1.06641 10.5023 0.897656 10.5023 0.691406C10.5023 0.485156 10.3336 0.316406 10.1273 0.316406H9.375H2.625H1.875ZM9 1.06641V2.04141C9 2.96719 8.57344 3.84141 7.84219 4.40859L6 5.84062L4.15781 4.40859C3.42656 3.84141 3 2.96719 3 2.04141V1.06641H9ZM6 6.79219L7.84219 8.22422C8.57344 8.79141 9 9.66562 9 10.5914V11.5664H3V10.5914C3 9.66562 3.42656 8.79141 4.15781 8.22422L6 6.79219Z"
        fill="rgba(33, 36, 42, 1)"
      />
    </svg>
  );
}

export default function SignaturePendencies() {
  const dispatch = useAppDispatch();
  const { contract, signPendencies, signatureLinkSentDate } = useAppSelector((state) => state.signature);

  const signers = signPendencies.peers;
  const documentsSigned = signers.filter((signer) => signer.signed).length;

  return (
    <>
      <SignatureHeader />
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
          display: "flex",
          height: "68px",
          justifyContent: "space-between",
          padding: "12px 24px",
        }}
      >
        <LqdButton
          onClick={() => dispatch(onChangingView(SignatureViews.DOCUMENT_VIEW))}
          size="medium"
          startIcon={<LeftArrow />}
          type="ghostPrimary"
        >
          <LqdTypography color="rgba(79, 85, 98, 1)">Voltar</LqdTypography>
        </LqdButton>
      </Box>
      <Box sx={{ ml: 6, mr: 7.5, padding: 2 }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2 }} textstyle="h4Headline">
          Assinaturas
        </LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)">DOCUMENTO</LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ fontWeight: 600, my: 1 }}>
          {getDocumentName(contract.portalDocumentUrl)}
        </LqdTypography>
        {signatureLinkSentDate > 0 ? (
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 3 }}>
            {`Enviado para assinatura em ${formatSignatureDate(signatureLinkSentDate)}`}
          </LqdTypography>
        ) : null}
        <Stack alignItems="center" direction="row" spacing={1} sx={{ mb: 1 }}>
          <LqdUserThreeIcon />
          <LqdTypography color="rgba(33, 36, 42, 1)">
            {`${documentsSigned}/${signers.length} Assinatura${signers.length === 1 ? "" : "s"}`}
          </LqdTypography>
        </Stack>
        <Divider sx={{ border: "1px solid rgba(220, 221, 224, 1)", mt: 1 }} />
        {signers.map((signer, index) => (
          <>
            <Stack alignItems="center" direction="row" key={signer.pass_id} sx={{ pb: 1, pt: 1.5, px: 2 }}>
              {signer.signed ? (
                <Box sx={{ marginRight: "12px" }}>
                  <LqdDoneFilledIcon color="rgba(52, 199, 89, 1)" size={35} />
                </Box>
              ) : (
                <Box
                  sx={{
                    background: "rgba(255, 204, 0, 1)",
                    border: "2px solid rgba(255, 204, 0, 1)",
                    borderRadius: "50%",
                    display: "inline-flex",
                    marginRight: "12px",
                    padding: "5px",
                  }}
                >
                  <HourGlass size={17} />
                </Box>
              )}
              <Box>
                <LqdTypography color="rgba(33, 36, 42, 1)">{signer.name}</LqdTypography>
                <LqdTypography color="rgba(127, 135, 152, 1)">
                  {signer.signed
                    ? `Assinatura Realizada em ${formatSignatureDate(signer.signature_timestamp)}`
                    : "Assinatura Pendente"}
                </LqdTypography>
              </Box>
            </Stack>
            <Divider
              sx={{
                border: "1px solid rgba(220, 221, 224, 1)",
                display: index === signers.length - 1 ? "none" : "auto",
                mt: 1,
              }}
            />
          </>
        ))}
      </Box>
    </>
  );
}

import axios, { AxiosRequestConfig } from "axios";
import { ReactNode, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { toastCalled } from "../commonSlice";
import useAuth from "../hooks/useAuth";
import { ToastNotification } from "../types/ToastNotification";
import { getNewAccessToken } from "./apiUtils";

type HttpAxiosProviderProps = {
  children: ReactNode;
  dispatch: AppDispatch;
  notification: ToastNotification;
};

interface OriginalRequestWithRetry extends AxiosRequestConfig {
  _retry?: boolean;
}

export default function HttpAxiosProvider({ children, notification }: HttpAxiosProviderProps) {
  const auth = useAuth();

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest: OriginalRequestWithRetry = error.config;

        if (!error.response) {
          console.log("error: ", error);

          const notification: ToastNotification = {
            message: `"Info" toasts have a set default message.`,
            type: "info",
          };

          dispatch(toastCalled(notification));

          return Promise.reject(error);
        }
        // Caso o refresh-token tenha expirado
        if (error.config.url?.match("refresh-token") && error.response?.status === 400) {
          auth.signOut();
          return Promise.reject("");
        }
        // Caso o access token tenha expirado
        if (error.response?.status === 403) {
          if (originalRequest.headers && !originalRequest._retry) {
            try {
              originalRequest._retry = true;
              const newAccessToken = await getNewAccessToken();
              auth.setAccessToken(newAccessToken);
              originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;
              return axios(originalRequest);
            } catch (error) {
              /**
               * Se chegar aqui é porque a request getNewAccessToken falhou.
               * Retornar um Promise.resolve() evita que ela dispare um unhandled promise rejection error,
               * que fazia um toast aparecer antes do sign out.
               */
              return Promise.resolve();
            }
          } else {
            auth.signOut();
          }
        }
        // Caso o erro seja interno (500 ou mais)
        else if (error.response?.status && error.response.status >= 500) {
          console.log("error: ", error.response);

          const notification: ToastNotification = {
            message: `"Info" toasts have a set default message.`,
            type: "info",
          };

          dispatch(toastCalled(notification));
        } else {
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [auth.setAccessToken, dispatch, notification]);

  return <div>{children}</div>;
}

import { LqdTypography } from "@/liquid-components/src";
import { Grid, Stack } from "@mui/material";
import { ObjectOf } from "../../../common/types/ObjectOf";
import {
  SimulatorResultCard,
  SimulatorResultHighlight,
  SimulatorResultTableData,
} from "../../types/SimulatorDashboard";
import { SimulatorFunctionBlock } from "../../types/SimulatorFunctionBlock";
import { SimulatorVariable } from "../../types/SimulatorVariable";

import { convertSimulationKeys } from "../../types/simulationTester";
import { dashboardRender } from "./SimulationTesterDashboardRender";

type SimulationContentToTestProps = {
  blocks: Array<SimulatorFunctionBlock>;
  dashboard: {
    cards: Array<SimulatorResultCard>;
    highlight: Array<SimulatorResultHighlight>;
    tables: Array<SimulatorResultTableData>;
  };
  variables: Array<SimulatorVariable>;
};

function SimulationContentToTest(props: SimulationContentToTestProps) {
  const { blocks, dashboard, variables } = props;

  const functionRenderer = (
    functionKey: string,
    value: number | string | ObjectOf<string> | ObjectOf<number | string>
  ) => {
    const formattedFunctionKey = convertSimulationKeys(functionKey);

    switch (functionKey) {
      case "result":
        return (
          <LqdTypography
            color="rgba(36, 38, 42, 1)"
            key={functionKey}
            sx={{ pl: 2 }}
          >{`${formattedFunctionKey} : ${JSON.stringify(value, null, 2)}`}</LqdTypography>
        );
      case "variables":
        return (
          <LqdTypography
            color="rgba(36, 38, 42, 1)"
            key={functionKey}
            sx={{ pl: 2 }}
          >{`${formattedFunctionKey} : ${JSON.stringify(value, null, 2)}`}</LqdTypography>
        );
      default:
        return (
          <LqdTypography
            color="rgba(36, 38, 42, 1)"
            key={functionKey}
            sx={{ pl: 2 }}
          >{`${formattedFunctionKey} : ${value}`}</LqdTypography>
        );
    }
  };

  return (
    <Grid container sx={{ overflowX: { lg: "hidden", sm: "auto" } }}>
      <Grid item sx={{ maxHeight: "800px", overflowY: "auto", px: 3 }} xs={4}>
        <LqdTypography
          color="rgba(36, 38, 42, 1)"
          sx={{ backdropFilter: "blur(120px)", fontWeight: 600, position: "sticky", textAlign: "center", top: 0 }}
          textstyle="h2Headline"
        >
          Variáveis padrão / Variáveis constantes:
        </LqdTypography>
        {variables.map((variable) => {
          if (!variable.is_input) {
            return (
              <Stack
                key={variable.name}
                sx={{
                  border: "1px solid rgba(36, 38, 42, 1)",
                  borderRadius: "20px",
                  minWidth: "300px",
                  my: 2,
                  p: 3,
                  width: "fit-content",
                }}
              >
                <Stack direction="column" sx={{ boxSizing: "border-box" }}>
                  <LqdTypography color="rgba(36, 38, 42, 1)">
                    <span style={{ fontWeight: 600 }}>Nome: </span>
                    {variable.name}
                  </LqdTypography>
                  <LqdTypography color="rgba(36, 38, 42, 1)">
                    <span style={{ fontWeight: 600 }}>Tipo: </span>
                    {variable.type}
                  </LqdTypography>
                  <LqdTypography color="rgba(36, 38, 42, 1)">
                    <span style={{ fontWeight: 600 }}>Valor: </span>
                    {variable.value}
                  </LqdTypography>
                </Stack>
              </Stack>
            );
          }
        })}
        {/* TODO: copiar a lógica das datas do board para esse titulo ocupar a posição sticky */}
        <LqdTypography color="rgba(36, 38, 42, 1)" sx={{ fontWeight: 600, textAlign: "center" }} textstyle="h2Headline">
          Variáveis criadas:
        </LqdTypography>
        {variables.map((variable) => {
          if (variable.is_input) {
            return (
              <Stack
                key={variable.name}
                sx={{
                  border: "1px solid rgba(36, 38, 42, 1)",
                  borderRadius: "20px",
                  minWidth: "300px",
                  my: 2,
                  p: 3,
                  width: "fit-content",
                }}
              >
                <Stack direction="column" sx={{ boxSizing: "border-box" }}>
                  <LqdTypography color="rgba(36, 38, 42, 1)">
                    <span style={{ fontWeight: 600 }}>Label: </span>
                    {variable.label}
                  </LqdTypography>
                  <LqdTypography color="rgba(36, 38, 42, 1)">
                    <span style={{ fontWeight: 600 }}>Nome: </span>
                    {variable.name}
                  </LqdTypography>
                  <LqdTypography color="rgba(36, 38, 42, 1)">
                    <span style={{ fontWeight: 600 }}>Tipo: </span>
                    {variable.type}
                  </LqdTypography>
                  <LqdTypography color="rgba(36, 38, 42, 1)">
                    <span style={{ fontWeight: 600 }}>Valor: </span>
                    {variable.value}
                  </LqdTypography>
                </Stack>
              </Stack>
            );
          }
        })}
      </Grid>

      <Grid
        item
        sx={{
          maxHeight: "800px",
          overflowY: "auto",
          px: 3,
        }}
        xs={4}
      >
        <LqdTypography
          color="rgba(36, 38, 42, 1)"
          sx={{ backdropFilter: "blur(120px)", fontWeight: 600, position: "sticky", textAlign: "center", top: 0 }}
          textstyle="h2Headline"
        >
          Blocos existentes:
        </LqdTypography>
        {blocks.map((block) => (
          <Stack
            key={block.name}
            sx={{
              border: "1px solid rgba(36, 38, 42, 1)",
              borderRadius: "20px",
              minWidth: "300px",
              my: 2,
              p: 3,
              width: "fit-content",
            }}
          >
            <LqdTypography color="rgba(36, 38, 42, 1)" textstyle="h5Headline">{`${block.name}:`}</LqdTypography>
            {block.functions.map((eachFunction) => (
              <Stack
                key={eachFunction.function_name}
                sx={{
                  border: "1px solid rgba(36, 38, 42, 1)",
                  borderRadius: "20px",
                  minWidth: "300px",
                  my: 2,
                  p: 3,
                  width: "fit-content",
                }}
              >
                {Object.entries(eachFunction).map(([functionData, value]) => functionRenderer(functionData, value))}
              </Stack>
            ))}
          </Stack>
        ))}
      </Grid>

      <Grid
        item
        sx={{
          maxHeight: "800px",
          overflowY: "auto",
          px: 3,
        }}
        xs={4}
      >
        <LqdTypography
          color="rgba(36, 38, 42, 1)"
          sx={{ backdropFilter: "blur(120px)", fontWeight: 600, position: "sticky", textAlign: "center", top: 0 }}
          textstyle="h2Headline"
        >
          Resultados Existentes:
        </LqdTypography>
        {dashboardRender(dashboard)}
      </Grid>
    </Grid>
  );
}

export default SimulationContentToTest;

import { LqdCircularLoader, LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { onGetAutomationResults, selectedAutomationChanged } from "../automationsSlice";
import AutomationAnalysisCard from "./AutomationDetailsCard";
import AutomationPagination from "./AutomationPagination";

export default function AutomationDetailsView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { automationId } = useParams();
  const { automationResults, automationResultsTotalPages, automations, selectedAutomation } = useAppSelector(
    (state) => state.automations
  );
  const [getingResults, setGetingResults] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const automation = automationId ? automations[automationId] : null;

  useEffect(() => {
    const notFoundAutomation = !automationId || automationId === undefined || !automation || automation === undefined;
    if (notFoundAutomation) {
      navigate("/automations");
    }
  }, [automationId]);

  // Carrega a lista de results do currentPage
  useEffect(() => {
    // Seta a selectedAutomation, caso não esteja setada
    if (!selectedAutomation && automation) {
      dispatch(selectedAutomationChanged(automation));
    }
    // Só exibe o loader caso nenhum result tenha  sido carregado ainda
    if (selectedAutomation) {
      setGetingResults(true);
      dispatch(onGetAutomationResults(currentPage)).then(() => setGetingResults(false));
    }
  }, [currentPage, selectedAutomation, automations]);

  const onCurrentPageChange = (newPage: number) => {
    if (newPage <= automationResultsTotalPages) {
      setCurrentPage(newPage);
    }
  };

  const onPrevPageClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onNextPageClick = () => {
    if (currentPage < automationResultsTotalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderContent = () =>
    automationResults.length ? (
      <Stack sx={{ height: "100%", maxWidth: "1135px", px: 4 }}>
        {/* TODO: Transformar em componente de tabela LqdTable */}
        <table>
          <tr>
            <td style={{ padding: "20px 0", width: "25%" }} />
            <td style={{ padding: "20px 0", width: "15%" }}>
              <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
                Status
              </LqdTypography>
            </td>
            <td style={{ padding: "20px 0", width: "20%" }}>
              <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
                ID da conexão
              </LqdTypography>
            </td>
            <td style={{ padding: "20px 0", width: "15%" }}>
              <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
                Link da conexão
              </LqdTypography>
            </td>
          </tr>
          {automationResults.map((result, index) => (
            <AutomationAnalysisCard automationResult={result} key={index} />
          ))}
        </table>
        <Stack alignItems="flex-end" sx={{ pb: "100px", pt: 10 }}>
          <AutomationPagination
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
            onNextPageClick={onNextPageClick}
            onPrevPageClick={onPrevPageClick}
            totalPages={automationResultsTotalPages}
          />
        </Stack>
      </Stack>
    ) : (
      <LqdTypography color="rgba(127, 135, 152, 1)" textAlign="center" textstyle="h4Headline">
        Não existem conexões criadas com esta automação.
      </LqdTypography>
    );

  return getingResults ? <LqdCircularLoader /> : renderContent();
}

import { DefaultMessageResponse } from "@common/types/DefaultMessageResponse";
import { post } from "../../common/utils/apiUtils";

/**
 * Exporta um PDF de uma conexão, enviando para os e-mails selecionados.
 * @param b64String Dados do PDF no formato base64.
 * @param emails Lista de e-mails que receberão o PDF.
 * @param peers Lista de nomes de peers da análise.
 * @param productName Nome do produto utilizado na análise.
 * @returns Mensagem de sucesso padrão.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const exportPDF = (b64String: string, emails: Array<string>, peers: Array<string>, productName: string) =>
  post<DefaultMessageResponse>("/files/pdf", {
    b64_string: b64String,
    emails,
    peers,
    product_name: productName,
  });

import {
  LqdButton,
  LqdCheckboxMenu,
  LqdDownloadIcon,
  LqdEmailIcon,
  LqdIconButton,
  LqdTypography,
} from "@/liquid-components/src";
import useAnalysisDetails from "@analysis/hooks/useAnalysisDetails";
import { capitalizeString } from "@common/utils/capitalize";
import { Stack } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { validateEmail } from "../../../common/utils/validateEmail";
import { exportPDF } from "../../api/ExportPDF";
import { AnalysisDashboardPDFProps } from "./AnalysisDashboardPDFButtons";

export default function AnalysisDashboardPDFEmailButton(props: AnalysisDashboardPDFProps) {
  const { allOptions, loading, menuItems, pdfInstance, resetOptions, selectedOptions, setSelectedOptions } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const allOptionsLabel = t("Todos");

  const { analysisDetails } = useAnalysisDetails();
  const { currentUser, users } = useAppSelector((state) => state.users);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [loadingState, setLoadingState] = useState(false);
  const shouldOpenDropdown = Boolean(anchorEl);

  const emailList = [
    { label: allOptionsLabel, value: allOptionsLabel },
    { label: currentUser!.fullname, value: currentUser!.username },
    ...Object.values(users)
      .map((user) => ({ label: user.name, value: user.email }))
      .filter((user) => user.value !== currentUser!.username),
  ];
  const peerList = Object.values(analysisDetails.peers).map((peer) => capitalizeString(peer.fullName)) || [];
  const getGroupProducts = useAppSelector((state) => state.products.groupProducts);
  const groupProducts = Object.values(getGroupProducts);
  const productName = groupProducts.find((product) => product.id === analysisDetails?.productId)?.name;

  const allOptionsEmail = [...emailList.map((email) => email.value).filter((email) => validateEmail(email))];

  const resetState = () => {
    resetOptions();
    setCurrentStep(1);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!anchorEl) resetState();
    setLoadingState(false);
  }, [anchorEl]);

  const handleExportPDFClick = async () => {
    if (pdfInstance.error) console.log("error: ", pdfInstance.error);
    // As duas linhas abaixo são usadas para debugar o PDF. Ao descomentá-las, o código abre o PDF em uma nova guia, em vez de enviar um e-mail.
    // else window.open(pdfInstance.url as string, "_blank");
    // return;

    setLoadingState(true);

    const reader = new FileReader();
    reader.readAsArrayBuffer(pdfInstance.blob!);

    try {
      reader.onloadend = async () => {
        const b64String = btoa(
          new Uint8Array(reader.result as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), "")
        );

        await exportPDF(
          b64String,
          selectedOptions.filter((email) => validateEmail(email)),
          peerList,
          productName as string
        );

        const notification: ToastNotification = {
          message: t("O PDF foi enviado com sucesso para o(s) e-mail(s) selecionado(s)."),
          type: "success",
        };

        dispatch(toastCalled(notification));

        resetState();
      };
    } catch (error) {
      handleLiquidErrors(error, t("Houve um erro ao exportar o PDF. Tente novamente mais tarde."));
    }
  };

  return (
    <>
      <LqdButton
        onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        startIcon={<LqdEmailIcon size={20} />}
        sx={{ display: { md: "inline-flex", xs: "none" } }}
        type="outlineTertiary"
      >
        {t("Enviar por E-mail")}
      </LqdButton>
      <LqdIconButton
        onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        sx={{ display: { md: "none", xs: "flex" }, flexDirection: "column", ml: 2, p: 1.5, width: "100%" }}
        type="ghostIcon"
      >
        <Stack alignItems="center" direction="row" spacing={1.5} sx={{ width: "100%" }}>
          <LqdDownloadIcon color="rgba(127, 135, 152, 1)" />
          <LqdTypography color="rgba(101, 110, 127, 1)" textstyle="p2Paragraph">
            Enviar por e-mail
          </LqdTypography>
        </Stack>
      </LqdIconButton>
      <LqdCheckboxMenu
        allOptions={currentStep === 1 ? allOptions : allOptionsEmail}
        anchorEl={anchorEl}
        currentStep={currentStep}
        loading={loading || loadingState}
        menuItems={currentStep === 1 ? menuItems : emailList}
        onConfirmMethod={handleExportPDFClick}
        openMenu={shouldOpenDropdown}
        selectedOptions={selectedOptions}
        setAnchorEl={setAnchorEl}
        setCurrentStep={setCurrentStep}
        setSelectedOptions={setSelectedOptions}
        type="export"
      />
    </>
  );
}

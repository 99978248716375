import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterTen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        10. SEGURANÇA
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        10.1. A Liquid adota todas as medidas técnicas e organizacionais aptas a proteger Dados Pessoais tratados
        através de suas Soluções.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        10.1.1. Atualmente, coletamos a biometria facial do Contratante para utilizá-la como mecanismo de controle de
        acesso e assinatura biométrica, com o objetivo de garantir maior segurança no uso de nossas Soluções.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        10.2. O desenvolvimento, implementação e atualização das medidas técnicas utilizadas pela Liquid levarão em
        consideração o estado atual da tecnologia, a escala e o volume das operações que serão realizadas, assim como os
        mecanismos de segurança e mitigação de riscos compatíveis com as boas práticas de compliance digital.
      </LqdTypography>
    </>
  );
}

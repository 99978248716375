import { LqdButton } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { AdminPagePanels } from "../../types/AdminPagePanels";

type TenantConfigPanelButtonsProps = {
  setCurrentPanel: (panel: AdminPagePanels) => void;
};

export default function TenantConfigPanelButtons(props: TenantConfigPanelButtonsProps) {
  const { setCurrentPanel } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "60px 50px", justifyContent: "space-between", ml: 8 }}>
      <Box>
        <LqdButton
          onClick={() => setCurrentPanel(AdminPagePanels.SIMULATOR_PARAMETRIZATION)}
          sx={{ mr: 4, width: "380px" }}
        >
          Parametrização de Simuladores
        </LqdButton>
        <LqdButton onClick={() => setCurrentPanel(AdminPagePanels.PROJECT_CONCLUSION_DATE)} sx={{ width: "380px" }}>
          Cadastro de Data de Conclusão da Obra
        </LqdButton>
      </Box>
      <Box>
        <LqdButton onClick={() => setCurrentPanel(AdminPagePanels.ANALYSIS_DELETION)} sx={{ mr: 4, width: "380px" }}>
          Exclusão de Conexões
        </LqdButton>
        <LqdButton onClick={() => setCurrentPanel(AdminPagePanels.PRODUCT_DELETION)} sx={{ width: "380px" }}>
          Exclusão de Produtos
        </LqdButton>
      </Box>
      <Box>
        <LqdButton onClick={() => setCurrentPanel(AdminPagePanels.GROUP_DELETION)} sx={{ mr: 4, width: "380px" }}>
          Exclusão de Grupos
        </LqdButton>
        <LqdButton onClick={() => setCurrentPanel(AdminPagePanels.USER_DELETION)} sx={{ width: "380px" }}>
          Exclusão de Usuários
        </LqdButton>
      </Box>
    </Box>
  );
}

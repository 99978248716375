import { LqdButton, LqdCheckboxMenu, LqdDownloadIcon, LqdIconButton, LqdTypography } from "@/liquid-components/src";
import useAnalysisDetails from "@analysis/hooks/useAnalysisDetails";
import { extractFirstAndLastName } from "@common/utils/nameExtractor";
import { Stack } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import { AnalysisDashboardPDFProps } from "./AnalysisDashboardPDFButtons";

export default function AnalysisDashboardPDFDownloadButton(props: AnalysisDashboardPDFProps) {
  const { allOptions, loading, menuItems, pdfInstance, resetOptions, selectedOptions, setSelectedOptions } = props;

  const { t } = useTranslation();

  const { analysisDetails } = useAnalysisDetails();
  // const { tenantProducts } = useAppSelector((state) => state.products);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const shouldOpenDropdown = Boolean(anchorEl);
  const getGroupProducts = useAppSelector((state) => state.products.groupProducts);
  const groupProducts = Object.values(getGroupProducts);

  const productName = groupProducts.find((product) => product.id === analysisDetails?.productId)?.name;
  const downloadPDFClick = async () => {
    if (pdfInstance.error) console.log("error: ", pdfInstance.error);
    // As duas linhas abaixo são usadas para debugar o PDF. Ao descomentá-las, o código abre o PDF em uma nova guia, em vez de realizar o download.
    // else window.open(pdfInstance.url as string, "_blank");
    // return;

    const peerNames = Object.values(analysisDetails.peers).map((peer) => extractFirstAndLastName(peer.fullName));
    const concatenatedNames = peerNames.join("_+_");
    const fileName = `Consulta_Liquid_${concatenatedNames}_-_${productName}.pdf`;

    const link = document.createElement("a");
    link.href = pdfInstance.url!;
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    if (!anchorEl) resetOptions();
  }, [anchorEl]);

  return (
    <>
      <LqdButton
        onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        startIcon={<LqdDownloadIcon size={20} />}
        sx={{ display: { md: "inline-flex", xs: "none" } }}
        type="outlineTertiary"
      >
        {t("Baixar PDF")}
      </LqdButton>
      <LqdIconButton
        onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        sx={{ display: { md: "none", xs: "flex" }, flexDirection: "column", ml: 2, p: 1.5, width: "100%" }}
        type="ghostIcon"
      >
        <Stack alignItems="center" direction="row" spacing={1.5} sx={{ width: "100%" }}>
          <LqdDownloadIcon color="rgba(127, 135, 152, 1)" />
          <LqdTypography color="rgba(101, 110, 127, 1)" textstyle="p2Paragraph">
            Baixar PDF
          </LqdTypography>
        </Stack>
      </LqdIconButton>
      <LqdCheckboxMenu
        allOptions={allOptions}
        anchorEl={anchorEl}
        currentStep={1} // Passed as a prop, but not used
        loading={loading}
        menuItems={menuItems}
        onConfirmMethod={downloadPDFClick}
        openMenu={shouldOpenDropdown}
        selectedOptions={selectedOptions}
        setAnchorEl={setAnchorEl}
        setCurrentStep={() => {}} // Passed as a prop, but not used
        setSelectedOptions={setSelectedOptions}
        type="download"
      />
    </>
  );
}

import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterZero() {
  return (
    <>
      <LqdTypography textstyle="h5Headline">POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" textAlign="justify" textstyle="p2Paragraph">
        Atualizada em 18 de março de 2024.
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" textAlign="justify" textstyle="p2Paragraph">
        A LIQUID DO BRASIL LTDA. (CNPJ: 52.099.247/0001-00), doravante podendo ser referida apenas como “Liquid”,
        “Empresa” ou “Nós”, tem como missão disponibilizar infraestrutura digital de automatização de decisões e
        análises aptas a trazer inteligência, segurança, assertividade e previsibilidade nas tomadas de decisões, sejam
        elas voltadas aos negócios ou pessoais.
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" textAlign="justify" textstyle="p2Paragraph">
        Combinamos inteligência artificial e interatividade visando descomplicar operações e proporcionar a melhor
        experiência.
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" textAlign="justify" textstyle="p2Paragraph">
        Para tanto, processamos diversos tipos de informações, sendo que algumas delas caracterizam Dados Pessoais.
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" textAlign="justify" textstyle="p2Paragraph">
        Esta Política de Privacidade e Proteção de Dados Pessoais (“Política”) tem como objetivo descrever como a Liquid
        trata Dados Pessoais nas operações envolvendo os seus produtos e serviços (“Soluções”).
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" textAlign="justify" textstyle="p2Paragraph">
        Pedimos que leia esta Política com atenção, pois a adesão ou o uso de qualquer uma de nossas Soluções implicará
        ciência e concordância com todo o seu conteúdo.
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" textAlign="justify" textstyle="p2Paragraph">
        Vamos lá?
      </LqdTypography>
    </>
  );
}

import { LqdButton, LqdCircularLoader, LqdDownTwoIcon, LqdIconButton, LqdTypography } from "@/liquid-components/src";
import { MenuItem, Select, Stack, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { simulatorBuilderChanged } from "../simulatorBuilderSlice";

type SimulatorFormStepNameProps = {
  editing: boolean;
  loading: boolean;
  onConfirm: () => void;
  sx?: SxProps;
};

export default function SimulatorFormStepTenant(props: SimulatorFormStepNameProps) {
  const { editing, loading, onConfirm, sx } = props;

  const { duplicating, simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const { tenants } = useAppSelector((state) => state.admin);
  const simulator = simulatorData!;

  const dispatch = useAppDispatch();

  const [selectedTenant, setSelectedTenant] = useState<{ code: string; contact: { name: string } } | null>(
    simulator.tenant_code
      ? {
          code: simulator.tenant_code,
          contact: { name: simulator.tenant_name },
        }
      : null
  );
  const [tenantId, setTenantId] = useState<string | null>(simulator.tenant_code ? simulator.tenant_code : null);

  const maySubmit = Boolean(tenantId) && !loading;

  useEffect(() => {
    if (tenantId) {
      const tenant = tenants[tenantId];
      setSelectedTenant(tenant);
      dispatch(simulatorBuilderChanged({ key: "tenant_code", value: tenant.code }));
      dispatch(simulatorBuilderChanged({ key: "tenant_name", value: tenant.contact.name }));
    }
  }, [tenantId]);

  const handleUpdateTenant = () => {
    onConfirm();
  };

  const renderButtonContent = () => {
    switch (true) {
      case loading:
        return <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />;
      case editing:
        return "Atualizar";
      case duplicating:
        return "Duplicar";
      default:
        return "Avançar";
    }
  };

  const buttonContent = renderButtonContent();

  return (
    <Stack
      justifyContent="center"
      sx={{ height: "100%", margin: "auto", maxWidth: "874px", px: { sm: 0, xs: 3 }, width: "100%" }}
    >
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
        O simulador será associado a qual empresa?
      </LqdTypography>
      {/* TODO: criar um component select reaproveitável */}
      <Select
        displayEmpty
        IconComponent={(props) => (
          <LqdIconButton type="ghostPrimary" {...props} sx={{ ...sx, top: "0 !important" }}>
            <LqdDownTwoIcon />
          </LqdIconButton>
        )}
        MenuProps={{
          sx: {
            ".MuiPaper-root": { boxShadow: "none" },
            borderRadius: "20px",
            height: "50%",
            mt: 1,
          },
        }}
        onChange={(event) => setTenantId(event.target.value)}
        renderValue={() =>
          selectedTenant ? (
            <LqdTypography
              color="rgba(33, 36, 42, 1)"
              sx={{
                mb: 1.5,
                ml: 2,
                padding: 0,
              }}
              textstyle="h5Headline"
            >
              {selectedTenant.contact.name}
            </LqdTypography>
          ) : (
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ p: 2 }} textstyle="h5Headline">
              Selecione a empresa
            </LqdTypography>
          )
        }
        sx={{
          "& .MuiSelect-select": {
            borderRadius: "20px",
            boxShadow: selectedTenant ? "0px 30px 40px -20px rgba(0, 0, 0, 0.2)" : "none",
          },
          "& .MuiSelect-select:focus": { backgroundColor: "transparent" },
          "&.Mui-focused": { borderBottom: "2px solid rgba(0, 0, 0, 0)" },
          "&:after": {
            borderBottom: !selectedTenant ? "2px solid rgba(41, 176, 160, 1)" : "2px solid rgba(88, 207, 182, 1)",
          },
          "&:before": {
            borderBottom: !selectedTenant ? "2px solid rgba(212, 215, 220, 1)" : "2px solid rgba(88, 207, 182, 0.5)",
          },
          "&:hover": {
            borderBottom: !selectedTenant ? "2px solid rgba(65, 70, 103, 1)" : "2px solid rgba(0, 0, 0, 0)",
          },
          "&:not(.Mui-disabled):hover::before": {
            borderBottom: !selectedTenant ? "1px solid rgba(212, 215, 220, 1)" : "2px solid rgba(88, 207, 182, 1)",
          },
          alignItems: "flex-end",
          borderBottom: "2px solid rgba(0, 0, 0, 0)",
          height: "56px",
          minWidth: { md: "700px", xs: "300px" },
          mt: 1.5,
        }}
        value={selectedTenant ? selectedTenant.code : ""}
        variant="standard"
      >
        {Object.values(tenants).map((tenant) => (
          <MenuItem
            key={tenant.code}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(255, 255, 255, 1)",
                border: "3px solid rgba(88, 207, 182, 1)",
                boxShadow: "0px 30px 40px -20px rgba(0, 0, 0, 0.2)",
              },
              "&.MuiMenuItem-root": { backgroundColor: "rgba(247, 247, 248, 1)", mb: 2 },
              "&.MuiMenuItem-root:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)",
                boxShadow: "0px 30px 40px -20px rgba(0, 0, 0, 0.2)",
              },
              ":last-child": { mb: 0 },
              backgroundColor: "rgba(191, 196, 205, 0.12)",
              borderRadius: 5,
              boxSizing: "content-box",
              display: { md: "flex", xs: "block" },
              height: "48px",
              justifyContent: "space-between",
              mr: { md: 0, xs: 0.3 },
              px: 3,
              py: "30px",
              width: { md: "700px", xs: "100%" },
            }}
            value={tenant.code}
          >
            {tenant.contact.name}
          </MenuItem>
        ))}
      </Select>
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
        <LqdButton disabled={!maySubmit} onClick={handleUpdateTenant}>
          {buttonContent}
        </LqdButton>
      </Stack>
    </Stack>
  );
}

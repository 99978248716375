import { useRef, useEffect } from "react";

export const useScrollFadeEffect = () => {
  const scrollBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollBoxRef.current) {
        const element = scrollBoxRef.current;
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        const fadeElement = element.nextSibling as HTMLElement | null;

        if (fadeElement) {
          if (scrollTop + clientHeight >= scrollHeight) {
            fadeElement.style.opacity = "0";
          } else {
            fadeElement.style.opacity = "1";
          }
        }
      }
    };

    if (scrollBoxRef.current) {
      const element = scrollBoxRef.current;
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollBoxRef.current]);
  // TODO: retornar o fadeElement (nextSibling) diretamente aqui, e só chamar nos componentes necessários. Com height parametrizável.
  return scrollBoxRef;
};

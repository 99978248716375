import { createElement } from "react";
import { AnalysisDetails } from "../../../types/AnalysisDetails/Analysis/AnalysisDetails";
import { LiquidPassCNPJPartnersRaw } from "../../../types/AnalysisDetails/LiquidPass/LiquidPassPartnershipsRaw";
import { ProviderData } from "../../../types/AnalysisDetails/Provider/ProviderData";
import { SerasaCNPJPPaymentResp } from "../../../types/AnalysisDetails/SerasaCNPJ/SerasaCNPJPPaymentResp";
import { SerasaCNPJRefinResponse } from "../../../types/AnalysisDetails/SerasaCNPJ/SerasaCNPJRefinResponse";
import PDFLiquidpassAddressesTable from "./Body/LiquidPass/PDFLiquidpassAddressesTable";
import PDFLiquidpassBlockedSerasa from "./Body/LiquidPass/PDFLiquidpassBlockedSerasa";
import PDFLiquidpassCNPJBusinessInfo from "./Body/LiquidPass/PDFLiquidpassCNPJBusinessInfo";
import PDFLiquidpassCNPJGeneralInfoContainer from "./Body/LiquidPass/PDFLiquidpassCNPJGeneralInfoContainer";
import PDFLiquidpassCNPJShareHoldingsTable from "./Body/LiquidPass/PDFLiquidpassCNPJShareHoldingsTable";
// import PDFLiquidpassVehiclesTable from "./Body/LiquidPass/PDFLiquidpassVehiclesTable";
import { PeerData } from "../../../types/AnalysisDetails/PeerData/PeerData";
import PDFLiquidpassWarningCard from "./Body/LiquidPass/PDFLiquidpassWarningCard";
import PDFNotFoundTable from "./Body/PDFNotFoundTable";
import PDFCNPJSerasaCheckTable from "./Body/Serasa/PDFCNPJSerasaCheckTable";
import PDFCNPJSerasaJudicialProcess from "./Body/Serasa/PDFCNPJSerasaJudicialProcess";
import PDFCNPJSerasaPaymentTable from "./Body/Serasa/PDFCNPJSerasaPaymentTable";
import PDFCNPJSerasaProtestsTable from "./Body/Serasa/PDFCNPJSerasaProtestsTable";
import PDFCNPJSerasaRestrictions from "./Body/Serasa/PDFCNPJSerasaRestrictions";

type PDFCNPJDashboardProps = {
  analysisDetails: AnalysisDetails;
  user: PeerData;
};

type TableProps<T> = {
  currentPage: number;
  lastPage: number;
  tableData: Array<T>;
  tableLength: number;
  tableOccurrences?: number;
  tableValue?: number;
};

export default function PDFCNPJDashboard(props: PDFCNPJDashboardProps) {
  const { analysisDetails, user } = props;

  const liquidPassCNPJ = analysisDetails.result.bureau?.[user.document]
    .liquidpass_cnpj as ProviderData["liquidpass_cnpj"];

  function renderTable<T>(
    data: Array<T> | undefined,
    tableComponent: (props: TableProps<T>) => JSX.Element,
    notFoundTitle: string,
    value?: number,
    totalOccurrences?: number
  ) {
    if (data && data.length && Object.values(data).length > 0) {
      const pageSize = 16;
      const pageCount = Math.ceil(data.length / pageSize);
      const pages = [];

      for (let i = 0; i < pageCount; i += 1) {
        const startIndex = i * pageSize;
        const endIndex = startIndex + pageSize;
        const pageData = data.slice(startIndex, endIndex);

        pages.push(
          createElement(tableComponent, {
            currentPage: i + 1,
            key: i,
            lastPage: pageCount,
            tableData: pageData,
            tableLength: data.length,
            tableOccurrences: totalOccurrences,
            tableValue: value,
          })
        );
      }

      return pages;
    } else {
      return <PDFNotFoundTable notFoundTitle={notFoundTitle} />;
    }
  }

  const renderAddressesTable = () => {
    return renderTable(liquidPassCNPJ?.addresses, PDFLiquidpassAddressesTable, "Não foram encontrados Endereços");
  };

  // const renderVehiclesTable = () => {
  //   return renderTable(liquidPassCNPJ?.vehicles, PDFLiquidpassVehiclesTable, "Não foram encontrados Veículos");
  // };

  const renderShareHoldingsTable = () => {
    const getRawDocument = (cnpj: string): string => {
      const rawCnpj = cnpj.replace(/\D/g, "");
      return rawCnpj;
    };

    const liquidPassPartnerships =
      liquidPassCNPJ?.partnerships.filter((partnership) => {
        const rawDocument = getRawDocument(partnership.CompanyDocument);
        return rawDocument === user.document;
      }) || [];

    const partnershipsPartners = liquidPassPartnerships
      .map((partnership) => partnership.Partners)
      .reduce((acc, curr) => acc.concat(curr), []);

    return renderTable(
      partnershipsPartners as Array<LiquidPassCNPJPartnersRaw>,
      PDFLiquidpassCNPJShareHoldingsTable,
      "Não foram encontradas Empresas com Sociedade"
    );
  };

  const serasaCNPJ = analysisDetails.result.bureau?.[user.document].serasa_cnpj as ProviderData["serasa_cnpj"];
  const serasaCNPJReport = serasaCNPJ?.report;

  const getPendingPaymentResponses = (): SerasaCNPJPPaymentResp => {
    if (!serasaCNPJReport) return [] as unknown as SerasaCNPJPPaymentResp;

    const pefinSummary = serasaCNPJReport?.pending_payment.pefin?.summary || { balance: 0, count: 0 };

    const refinSummary = serasaCNPJReport?.pending_payment.refin?.summary || { balance: 0, count: 0 };

    const colRecSummary = serasaCNPJReport?.pending_payment.collectionRecords?.summary || { balance: 0, count: 0 };

    const pendingPayments = Object.values(serasaCNPJReport?.pending_payment || []);
    const validPendingPayments: Array<SerasaCNPJRefinResponse> = [];

    pendingPayments.forEach((pendingPayment) => {
      const pendingPaymentValues = Object.values(pendingPayment);
      pendingPaymentValues.forEach((pendingPaymentValue) =>
        Array.isArray(pendingPaymentValue) ? validPendingPayments.push(...pendingPaymentValue) : null
      );
    });

    const totalOccurrences = pefinSummary.count + refinSummary.count + colRecSummary.count;
    const totalValue = pefinSummary.balance + refinSummary.balance + colRecSummary.balance;

    return { pendingPaymentResponses: validPendingPayments, totalOccurrences, totalValue };
  };

  const pendingPaymentResponses = getPendingPaymentResponses();

  const renderSerasaPaymentTable = () => {
    const pendingPaymentTotalOccurrences = pendingPaymentResponses.totalOccurrences;
    const pendingPaymentTotalValue = pendingPaymentResponses.totalValue;

    return renderTable(
      pendingPaymentResponses.pendingPaymentResponses,
      PDFCNPJSerasaPaymentTable,
      "Não foram encontrados Pagamentos Pendentes",
      pendingPaymentTotalValue,
      pendingPaymentTotalOccurrences
    );
  };

  const renderSerasaCheckTable = () => {
    const checkTotalOccurrences = serasaCNPJReport?.check.summary.count;
    const checkTotalValue = serasaCNPJReport?.check.summary.balance;

    return renderTable(
      serasaCNPJReport?.check.checkResponse,
      PDFCNPJSerasaCheckTable,
      "Não foram encontradas Restrições de Cheques sem Fundo",
      checkTotalValue,
      checkTotalOccurrences
    );
  };

  const renderSerasaProtestsTable = () => {
    const protestsTotalOccurrences = serasaCNPJReport?.notary.summary.count;
    const protestsTotalValue = serasaCNPJReport?.notary.summary.balance;

    return renderTable(
      serasaCNPJReport?.notary.notaryResponse,
      PDFCNPJSerasaProtestsTable,
      "Não foram encontrados Protestos",
      protestsTotalValue,
      protestsTotalOccurrences
    );
  };

  const renderCourtLawsuitsTable = () => {
    const courtLawsuitsTotalOccurrences = serasaCNPJReport?.judgement_filings.summary.count;
    const courtLawsuitsTotalValue = serasaCNPJReport?.judgement_filings.summary.balance;

    return renderTable(
      serasaCNPJReport?.judgement_filings.judgementFilingsResponse,
      PDFCNPJSerasaJudicialProcess,
      "Não foram encontradas Ações Judiciais",
      courtLawsuitsTotalValue,
      courtLawsuitsTotalOccurrences
    );
  };

  return (
    <>
      {liquidPassCNPJ ? (
        <>
          <PDFLiquidpassCNPJGeneralInfoContainer provider={liquidPassCNPJ} />
          {renderAddressesTable()}
          <PDFLiquidpassCNPJBusinessInfo provider={liquidPassCNPJ} />
          {/*renderVehiclesTable()*/}
          <PDFLiquidpassWarningCard />
          {renderShareHoldingsTable()}
        </>
      ) : null}
      {serasaCNPJ && serasaCNPJ.exec_error ? <PDFLiquidpassBlockedSerasa user={user} /> : null}
      {serasaCNPJ && !serasaCNPJ.exec_error ? (
        <>
          <PDFCNPJSerasaRestrictions serasaCNPJ={serasaCNPJ} />
          {renderSerasaPaymentTable()}
          {renderSerasaCheckTable()}
          {renderSerasaProtestsTable()}
          {renderCourtLawsuitsTable()}
        </>
      ) : null}
    </>
  );
}

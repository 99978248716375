import { LqdDownIcon, LqdTypography } from "@/liquid-components/src";
import { Box, MenuItem, Select } from "@mui/material";
import { useAppSelector } from "../../../../../../store";
import { SimulatorResultTable } from "../../../../types/SimulatorDashboard";

type SimulatorResultTableEmptyLineProps = {
  columnIndex: number;
  handleTableChange: (tableColumnName: string, columnIndex: number, rowIndex: number, tableIndex: number) => void;
  lastColumn: boolean;
  rowIndex: number;
  selectedVariable: { data: SimulatorResultTable | null; id: string };
  tableIndex: number;
};

export default function SimulatorResultTableEmptyLine(props: SimulatorResultTableEmptyLineProps) {
  const { columnIndex, handleTableChange, lastColumn, rowIndex, selectedVariable, tableIndex } = props;

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  return (
    <Box
      sx={{
        alignItems: "center",
        borderBottom: "1px solid rgba(212, 215, 220, 1)",
        borderRight: lastColumn ? "none" : "1px solid rgba(212, 215, 220, 1)",
        display: "flex",
        maxWidth: "290px",
        width: "290px",
      }}
    >
      <Select
        displayEmpty
        fullWidth
        IconComponent={LqdDownIcon}
        inputProps={{
          sx: {
            ":active": { boxShadow: "0px 0px 0px 4px rgba(235, 235, 245, 0.70)" },
            ":focus": { borderColor: "rgba(107, 114, 128, 1)" },
            ":hover": {
              borderColor: "rgba(107, 114, 128, 1)",
            },
            alignItems: "center",
            backgroundColor: "rgba(127, 135, 152, 0.04)",
            display: "flex",
            pl: "12px !important",
          },
        }}
        MenuProps={{
          MenuListProps: { sx: { ".MuiMenuItem-root.Mui-selected": { backgroundColor: "transparent" }, p: 0 } },
          PaperProps: {
            sx: {
              border: "1px solid rgba(33, 36, 42, 1)",
              boxSizing: "border-box",
              maxHeight: "400px",
              mt: "10px",
              pl: 2,
              pr: "9px",
              py: 3,
            },
          },
        }}
        onChange={(event) => handleTableChange(event.target.value as string, columnIndex, rowIndex, tableIndex)}
        renderValue={() => "Selecione uma variável"}
        sx={{
          ".MuiInput-input": { height: "48px !important", p: 0 },
          ".MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input": {
            backgroundColor: "rgba(249, 249, 250, 1)",
            borderLeft: "none",
            borderRight: "none",
          },
          "::after": { display: "none" },
          "::before": { display: "none" },
          color: "rgba(156, 163, 175, 1)",
          mb: "10px",
          svg: { pointerEvents: "none", position: "absolute", right: "12px" },
        }}
        value={selectedVariable.data ? selectedVariable.data.name : ""}
        variant="standard"
      >
        {simulator.variables.map((variable, index) => (
          <MenuItem key={variable.name} sx={{ mt: index > 0 ? 3 : 0 }} value={variable.name}>
            <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
              {variable.label}
            </LqdTypography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store";

export default function useSimulatorBuilder() {
  const { simulatorId } = useParams<{ simulatorId: string }>();

  const { simulatorData, simulators } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData || simulators[simulatorId!];

  const defaultSimulatorVariables = simulator.variables.slice(0, 10);
  const simulatorVariablesWithoutDefault = simulator.variables.slice(10);
  const simulatorVariablesWithoutDefaultAndInputs = simulatorVariablesWithoutDefault.filter(
    (variable) => !variable.is_input
  );

  return {
    defaultSimulatorVariables,
    simulator,
    simulatorVariablesWithoutDefault,
    simulatorVariablesWithoutDefaultAndInputs,
  };
}

import { ReactNode } from "react";

interface MarkerProps {
  children: ReactNode;
  className?: string;
  id: string;
}

const Marker = ({ id, className, children }: MarkerProps) => (
  <marker
    className={className || "react-flow__arrowhead"}
    id={id}
    markerHeight="24"
    markerUnits="userSpaceOnUse"
    markerWidth="24"
    orient="auto"
    refX="0"
    refY="0"
    viewBox="-10 -10 20 20"
  >
    {children}
  </marker>
);

interface MarkerDefinitionsProps {
  color: string;
  id: string;
}

export function MarkerDefinition(props: MarkerDefinitionsProps) {
  const { color, id } = props;

  return (
    <svg>
      <defs>
        <Marker id={id}>
          <polyline
            fill={color}
            points="-12,-6 0,0 -12,6 -12,-6"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </Marker>
      </defs>
    </svg>
  );
}

import {
  LqdButton,
  LqdCreatorIcon,
  LqdDownloadIcon,
  LqdIconButton,
  LqdMenusIcon,
  LqdObservationIcon,
  LqdPlusIcon,
  LqdRightTwoIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Box, Button, Stack } from "@mui/material";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { downloadDocument, getDocumentName } from "@signature/utils/signatureDocument";
import { PDFDocumentProxy } from "pdfjs-dist";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { onChangingView, onOpenDocumentOptionsMenuModal } from "../signatureSlice";
import { SignatureViews } from "../types/signatureViews";
import SignatureDocumentOptionsMenu from "./SignatureDocumentOptionsMenu";
import SignatureHeader from "./SignatureHeader";

export default function DocumentView() {
  const dispatch = useAppDispatch();
  const { contract, documentFile, signPendencies, peerData, showDocumentOptionsMenu } = useAppSelector(
    (state) => state.signature
  );

  const canvasRefs = useRef<Array<HTMLCanvasElement | null>>([]);
  const [scale, setScale] = useState(1.0);

  const signers = signPendencies.peers;
  const documentsSigned = signers.filter((signer) => signer.signed).length;
  const user = peerData.pass_id;
  const hasUserSigned = signers.find((signer) => signer.pass_id === user)?.signed;

  useEffect(() => {
    if (documentFile) {
      (async () => {
        await renderPages(documentFile, scale);
      })();
    }
  }, [documentFile, scale]);

  const renderPages = async (pdf: PDFDocumentProxy, scale: number) => {
    if (!canvasRefs.current.length) {
      canvasRefs.current = new Array(pdf.numPages).fill(null);
    }

    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ dontFlip: false, scale });
      const canvas = canvasRefs.current[pageNumber - 1];
      if (canvas) {
        const context = canvas.getContext("2d");
        if (context) {
          const outputScale = window.devicePixelRatio || 1;

          canvas.width = Math.floor(viewport.width * outputScale);
          canvas.height = Math.floor(viewport.height * outputScale);
          canvas.style.width = `${Math.floor(viewport.width)}px`;
          canvas.style.height = `${Math.floor(viewport.height)}px`;

          const transform = [outputScale, 0, 0, outputScale, 0, 0];

          const renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
        }
      }
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        background: "rgba(240, 240, 240, 1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <SignatureHeader />
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
          display: "flex",
          height: "68px",
          justifyContent: "center",
          marginBottom: "24px",
          overflow: "auto",
          padding: "12px 24px",
          width: "100%",
        }}
      >
        <Stack alignItems="center" direction="row">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: hasUserSigned ? "rgba(rgba(52, 199, 89, 1))" : "rgba(240, 241, 243, 1)",
              borderRadius: "50%",
              height: "40px",
              mr: 1.25,
              width: "40px",
            }}
          >
            <LqdObservationIcon color={hasUserSigned ? "rgba(255, 255, 255, 1)" : "rgba(50, 50, 50, 1)"} />
          </Stack>
          <Stack sx={{ mr: "42px" }}>
            <LqdTypography color="rgba(127, 135, 152, 1)">{getDocumentName(contract.portalDocumentUrl)}</LqdTypography>
            <Stack alignItems="center" direction="row">
              <LqdTypography color="rgba(33, 36, 42, 1)">
                {`${documentsSigned}/${signers.length} Assinatura${signers.length === 1 ? "" : "s"}`}
              </LqdTypography>
            </Stack>
          </Stack>
          <LqdIconButton onClick={() => dispatch(onChangingView(SignatureViews.SIGNATURE_PENDENCIES))} type="ghostIcon">
            <LqdRightTwoIcon color="rgba(127, 135, 152, 1)" />
          </LqdIconButton>
        </Stack>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          background: "rgba(240, 240, 240, 1)",
          display: "flex",
          height: {
            sm: "100vh",
            xs: "80vh",
          },
          justifyContent: "flex-end",
          marginRight: "80px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            background: "rgba(155, 162, 175, 1)",
            borderRadius: "12px",
            display: {
              sm: "flex",
              xs: "none",
            },
            flexDirection: "column",
            gap: "5px",
            left: "20px",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
          }}
        >
          <Button
            onClick={() => setScale(scale + 0.1)}
            sx={{
              color: "rgba(255, 255, 255, 1)",
              minHeight: "40px",
              minWidth: "40px",
            }}
          >
            <LqdPlusIcon />
          </Button>
          <Button
            onClick={() => setScale(scale - 0.1)}
            sx={{
              color: "rgba(255, 255, 255, 1)",
              minHeight: "40px",
              minWidth: "40px",
            }}
          >
            <LqdMenusIcon />
          </Button>
        </Box>

        <Box
          sx={{
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            marginLeft: {
              sm: "80px",
            },
            overflowY: "scroll",
            position: "relative",
            width: {
              sm: "100%",
              xs: "85%",
            },
            zIndex: 0,
          }}
        >
          {Array.from({ length: documentFile?.numPages || 0 }, (_, index) => (
            <canvas
              key={index}
              ref={(el) => (canvasRefs.current[index] = el)}
              style={{
                borderRadius: "24px",
                marginBottom: "20px",
              }}
            />
          ))}
          <Box sx={{ minHeight: "250px" }} />
        </Box>
      </Box>
      <Box
        sx={{
          bottom: "20px",
          left: "50%",
          position: "absolute",
          transform: "translateX(-50%)",
          zIndex: 1,
        }}
      >
        <LqdButton
          onClick={() => dispatch(onOpenDocumentOptionsMenuModal())}
          sx={{
            background: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(127, 135, 152, 1)",
            color: "rgba(127, 135, 152, 1)",
            display: {
              xm: "inline",
              xs: "none",
            },
            margin: "0 10px",
            minWidth: "100px",
          }}
        >
          Opções
        </LqdButton>

        {contract.status === 3 ? (
          <LqdButton
            endIcon={<LqdDownloadIcon />}
            onClick={() => downloadDocument(contract.portalDocumentUrl)}
            sx={{
              background: "rgba(33, 36, 42, 1)",
              color: "rgba(255, 255, 255, 1)",
              margin: "0 10px",
              width: "387px",
            }}
          >
            Baixar documento assinado
          </LqdButton>
        ) : (
          <LqdButton
            disabled={contract.status === 2}
            endIcon={<LqdCreatorIcon />}
            onClick={() => dispatch(onChangingView(SignatureViews.START_SIGNING))}
            sx={{
              background: "rgba(52, 199, 89, 1)",
              color: "rgba(255, 255, 255, 1)",
              margin: "0 10px",
              width: "387px",
            }}
          >
            {contract.status === 1 ? "Assinar" : "Você já assinou esse documento!"}
          </LqdButton>
        )}
      </Box>
      {showDocumentOptionsMenu ? <SignatureDocumentOptionsMenu /> : null}
    </Box>
  );
}

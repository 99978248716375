import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterEight() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        8. TÉRMINO DO TRATAMENTO
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        8.1. Os Dados Pessoais do Usuário Analisado serão tratados enquanto houver finalidade específica e legítima para
        tanto. Em alguns casos, precisaremos manter Dados para cumprir com obrigações legais (art. 7º, II, LGPD) ou
        exercer regularmente os nossos direitos (art. 7º, VI, LGPD).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        8.2. Caso os Dados sejam eventualmente tratados mediante consentimento, o Usuário Analisado poderá, através da
        própria Plataforma Liquid ou de manifestação expressa a ser encaminhada ao endereço de e-mail dpo@askliquid.com,
        revogar o consentimento fornecido, o que implicará na interrupção do Tratamento dentro do prazo de até 15 dias,
        contados da solicitação.
      </LqdTypography>
    </>
  );
}

import { AnalysisPendencyRaw } from "@analysis/types/AnalysisDetails/Analysis/AnalysisPendencyRaw";
import { AnalysisPendencySignChoices } from "@analysis/types/AnalysisDetails/Analysis/AnalysisPendencySignChoices";
import { AnalysisPendencyUploadChoices } from "@analysis/types/AnalysisDetails/Analysis/AnalysisPendencyUploadChoices";
import { put } from "../../common/utils/apiUtils";

export async function updateChoices({
  analysisId,
  pendencyId,
  updatedChoices,
  updatedValue,
}: {
  analysisId: string;
  pendencyId: string;
  updatedChoices: AnalysisPendencySignChoices | AnalysisPendencyUploadChoices;
  updatedValue: string;
}) {
  return put<AnalysisPendencyRaw>(`/pendencies/${analysisId}/${pendencyId}/choices`, {
    choices: updatedChoices,
    value: updatedValue,
  });
}

import { themes } from "@/liquid-components/src";
import { ThemeProvider } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import SmallDialog from "./features/common/components/SmallDialog/SmallDialog";
import { ToastNotification } from "./features/common/components/ToastNotification";
import HttpProvider from "./features/common/utils/http";
import i18n from "./i18n";
import RoutesProvider from "./routes";
import { useAppDispatch, useAppSelector } from "./store";

export default function App() {
  const dispatch = useAppDispatch();

  const { toastNotification } = useAppSelector((state) => state.common);

  return (
    <div className="App">
      <ThemeProvider theme={themes.Liquid}>
        <HttpProvider dispatch={dispatch} notification={toastNotification}>
          <I18nextProvider i18n={i18n}>
            <RoutesProvider />
            <SmallDialog />
          </I18nextProvider>
          <ToastNotification />
        </HttpProvider>
      </ThemeProvider>
    </div>
  );
}

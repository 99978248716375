import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterNine() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        9. QUAIS SÃO OS DIREITOS DOS TITULARES DOS DADOS PESSOAIS?
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        9.1. Através dos canais disponibilizados pela Liquid, os Titulares poderão realizar solicitações sobre:
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        a) confirmação da existência de Tratamento de Dados Pessoais;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        b) acessos às informações referentes aos Dados Pessoais que possuímos;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        c) correção de Dados incompletos, inexatos ou desatualizados;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        d) anonimização, bloqueio ou eliminação dos Dados desnecessários ou excessivos;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        e) portabilidade dos Dados para terceiros, dentro dos limites legais e conforme as orientações da ANPD;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        f) eliminação dos Dados Pessoais;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        g) informações das entidades públicas e privadas com as quais compartilhamos seus Dados Pessoais;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        h) informações sobre a possibilidade de não fornecer consentimento para o Tratamento de seus Dados e sobre as
        consequências desta opção;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        i) revogação do consentimento, nos termos do item 8.2. acima.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        9.2. O Usuário Analisado tem o direito a solicitar a revisão de decisões tomadas unicamente com base em
        Tratamento automatizado de seus Dados Pessoais que afetem seus interesses, incluídas as decisões destinadas a
        definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        9.2.1. Considerando que as decisões automatizadas tomadas pela Liquid AI se baseiam nos Parâmetros estabelecidos
        pelo Usuário Decisor, a Liquid lhe encaminhará eventuais solicitações do Usuário Analisado para que forneça
        informações claras e adequadas a respeito dos critérios e procedimentos utilizados para estabelecimento de
        referidos Parâmetros, resguardados os segredos comercial e industrial.
      </LqdTypography>
    </>
  );
}

import { LqdLink, LqdToggle, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { capitalizeString } from "@common/utils/capitalize";
import { stringInitials } from "@common/utils/initials";
import { Avatar, Box, Grid, useTheme } from "@mui/material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store";
import Group from "../../groups/types/Group";
import { GroupResponse } from "../../superadmin/types/GroupResponse";
import { User } from "../../users/types/User";
import { ObjectOf } from "../types/ObjectOf";

type UserRowProps = {
  groups: ObjectOf<Group>;
  handleToggleUser: (confirm: boolean) => void;
  handleUserNotificationToggle: (user: User) => void;
  onCancelInvite: () => void;
  onEditUser: (step: number) => void;
  onResendInvite: () => void;
  user: User;
};

export default function UserRow(props: UserRowProps) {
  const { groups, handleToggleUser, handleUserNotificationToggle, onCancelInvite, onEditUser, onResendInvite, user } =
    props;

  const navigate = useNavigate();
  const theme = useTheme();

  const { currentUser } = useAppSelector((state) => state.users);
  const groupsAdmin = useAppSelector((state) => state.admin.groups);
  const { tenants } = useAppSelector((state) => state.admin);

  const belongsToCurrentUser = user.email === currentUser?.username;
  const userGroups = user.groups.map((groupId) => groups[groupId]);

  let adminGroups: Array<GroupResponse> = [];

  if (currentUser?.userRole === "super") {
    adminGroups = Object.values(groupsAdmin).filter((group) =>
      group.users.find((userEmail) => userEmail === user?.email)
    );
  }

  const firstThreeUserGroups = userGroups.slice(0, 3);
  const extraUserGroupsLength = userGroups.length - firstThreeUserGroups.length;

  const firstThreeAdminGroups = adminGroups.slice(0, 3);
  const extraAdminGroupsLength = adminGroups.length - firstThreeAdminGroups.length;

  const renderGroupsTooltip = () => {
    switch (true) {
      case currentUser?.userRole === "super":
        return firstThreeAdminGroups.length > 0 ? (
          <>
            {firstThreeAdminGroups.map((group) => (
              <Box key={group.code}>{`${group.name} [${tenants[group.tenantCode].contact.name}]`}</Box>
            ))}
            {extraAdminGroupsLength > 0 ? <Box>{`+ ${extraAdminGroupsLength} outros`}</Box> : null}
          </>
        ) : (
          "Nenhum grupo"
        );
      case currentUser?.userRole === "admin":
        return firstThreeUserGroups.length > 0 ? (
          <>
            {firstThreeUserGroups.map((group) => (
              <Box key={group?.id}>{group?.title}</Box>
            ))}
            {extraUserGroupsLength > 0 ? <Box>{`+ ${extraUserGroupsLength} outros`}</Box> : null}
          </>
        ) : (
          "Nenhum grupo"
        );
    }
  };

  const renderUserRowContent = () => {
    switch (true) {
      case user.pending:
        return (
          <>
            <LqdTooltip
              placement="left"
              titlebody={
                <>
                  O usuário ainda não acessou a plataforma.
                  <Box onClick={onCancelInvite} sx={{ cursor: "pointer", mt: 1 }}>
                    <LqdTypography color="rgba(240, 241, 243, 1)" textstyle="p2Paragraph">
                      {"Cancelar convite >"}
                    </LqdTypography>
                  </Box>
                </>
              }
              titleheader="Convite pendente"
            >
              <LqdTypography
                sx={{
                  border: "1px solid rgba(65, 70, 103, 1)",
                  borderRadius: "12px",
                  color: "rgba(33, 36, 42, 1)",
                  mb: "4px",
                  p: 0.5,
                  width: "fit-content",
                }}
                textstyle="buttonXS"
              >
                Pendente
              </LqdTypography>
            </LqdTooltip>
            <LqdLink onClick={() => onResendInvite()} sx={{ display: "inline-fblock", textDecoration: "none" }}>
              <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                Reenviar
              </LqdTypography>
            </LqdLink>
          </>
        );
      case belongsToCurrentUser:
        return (
          <LqdTooltip
            placement="left"
            titlebody={`Não é possível inativar seu próprio usuário. Apenas outro usuário, com Perfil Admin, pode
            realizar esta ação.`}
            titleheader="Seu status"
          >
            <LqdToggle active={user.active} disabled onChange={() => {}} />
          </LqdTooltip>
        );
      default:
        return <LqdToggle active={user.active} onChange={() => handleToggleUser(!user.active)} renderText />;
    }
  };

  return (
    <Grid
      className="lqd-user-row"
      columns={{ lg: 16, md: 20 }}
      container
      spacing={{ lg: 0, md: 0, xs: 5 }}
      sx={{ borderBottom: "1px solid rgba(103, 66, 65, 0.24)", pb: 1, pl: 3, pt: 3 }}
      width={{ lg: "100%", xs: "fit-content" }}
      wrap="nowrap"
    >
      <Grid item lg={6} md={7} sx={{ display: "flex", pl: 3 }}>
        <Avatar sx={{ backgroundColor: "rgba(65, 70, 103, 0.12)", color: theme.palette.primary.main }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
            {stringInitials(user.name.trim()).toUpperCase()}
          </LqdTypography>
        </Avatar>
        <Box sx={{ boxSizing: "border-box", flex: "1 1", px: 2 }}>
          <LqdTypography
            sx={{ color: "rgba(33, 36, 42, 1)", minWidth: { lg: "450px", xs: "200px" } }}
            textstyle="p2Paragraph"
          >
            {user.name}
          </LqdTypography>
          <LqdTypography
            sx={{
              color: "rgba(127, 135, 152, 1)",
              display: "block",
              maxWidth: "calc(100% - 32px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            textstyle="c1Caption"
          >
            {user.email}
          </LqdTypography>
          <LqdLink onClick={() => onEditUser(1)} sx={{ textDecoration: "none" }}>
            <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
              Editar
            </LqdTypography>
          </LqdLink>
        </Box>
      </Grid>
      <Grid item lg={2} md={2}>
        {belongsToCurrentUser ? (
          <LqdTooltip placement="left" titlebody="Não é possível alterar seu próprio perfil." titleheader="Seu perfil">
            <>
              <LqdTypography
                color="rgba(33, 36, 42, 1)"
                sx={{ minWidth: { lg: "150px", xs: "0px" } }}
                textstyle="p2Paragraph"
              >
                {capitalizeString(user.profile)}
              </LqdTypography>
              <LqdTypography color="rgba(217, 217, 217, 1)" sx={{ mt: "2px" }} textstyle="p2Paragraph">
                Editar
              </LqdTypography>
            </>
          </LqdTooltip>
        ) : (
          <Box>
            <LqdTypography
              color="rgba(33, 36, 42, 1)"
              sx={{
                minWidth: { lg: "150px", xs: "0px" },
              }}
              textstyle="p2Paragraph"
            >
              {capitalizeString(user.profile)}
            </LqdTypography>
            <LqdLink onClick={() => onEditUser(2)} sx={{ textDecoration: "none" }}>
              <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
                Editar
              </LqdTypography>
            </LqdLink>
          </Box>
        )}
      </Grid>
      <Grid item lg={2} md={3}>
        <LqdTooltip
          placement="left"
          titlebody={
            <>
              {renderGroupsTooltip()}
              <Box
                onClick={() => navigate("/config/groups")}
                sx={{ cursor: "pointer", minWidth: { lg: "250px", xs: "0px" }, mt: 1 }}
              >
                <LqdTypography color="rgba(240, 241, 243, 1)" textstyle="p2Paragraph">
                  {"Ver detalhes >"}
                </LqdTypography>
              </Box>
            </>
          }
          titleheader="Grupos"
        >
          <Box sx={{ display: "inline-flex", flexDirection: "column", minWidth: { lg: "200px", xs: "0px" } }}>
            <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", display: "inline" }} textstyle="p2Paragraph">
              {userGroups.length > 9 ? userGroups.length : "0" + userGroups.length}
            </LqdTypography>
            <LqdLink onClick={() => onEditUser(3)} sx={{ textDecoration: "none" }}>
              <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
                Editar
              </LqdTypography>
            </LqdLink>
          </Box>
        </LqdTooltip>
      </Grid>
      <Grid item lg={2.5} md={4} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph" width="90px">
          {format(user.created, "dd MMM yyyy", { locale: ptBR })}
        </LqdTypography>
        <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", mt: 0.5 }} textstyle="p2Paragraph">
          {format(user.created, "HH:mm", { locale: ptBR })}
        </LqdTypography>
      </Grid>
      <Grid item lg={1.5} md={4} sx={{ height: "100%", minWidth: { xs: "100px" } }}>
        {renderUserRowContent()}
      </Grid>
      {!user.pending ? (
        <Grid item lg={2} md={4} sx={{ height: "100%", minWidth: { xs: "100px" } }}>
          <LqdToggle active={user.notifications} onChange={() => handleUserNotificationToggle(user)} renderText />
        </Grid>
      ) : null}
    </Grid>
  );
}

import { LqdIconButton, LqdLink, LqdToggle, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Divider, Grid } from "@mui/material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Fragment, RefObject, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductRow from "../../groups/components/ProductRow";
import UserCard from "../../groups/components/UserCard";
import Group from "../../groups/types/Group";
import { Product } from "../../products/types/Product";
import { User } from "../../users/types/User";
import { ObjectOf } from "../types/ObjectOf";
import NoDataGroupRow from "./NoDataGroupRow";

type GroupRowProps = {
  group: Group;
  groupRowKey: string;
  onEditGroup: (step: number) => void;
  onToggleButtonClick: (confirm: boolean) => Promise<void>;
  products: ObjectOf<Product>;
  refToTop: RefObject<HTMLDivElement>;
  selectedGroupRowKey: string;
  setSelectedGroupRowKey: (groupRowKey: string) => void;
  users: ObjectOf<User>;
};

export default function GroupRow(props: GroupRowProps) {
  const {
    group,
    groupRowKey,
    onEditGroup,
    onToggleButtonClick,
    products,
    refToTop,
    selectedGroupRowKey,
    setSelectedGroupRowKey,
    users,
  } = props;

  const navigate = useNavigate();

  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (selectedGroupRowKey === groupRowKey) {
      setShowDetails(true);
      refToTop.current && refToTop.current.scrollIntoView({ block: "nearest" });
    } else setShowDetails(false);
  }, [selectedGroupRowKey]);

  const groupProducts = group.products.map((productId) => products[productId]?.name);
  const firstThreeProducts = groupProducts.slice(0, 3);
  const extraProductsLength = groupProducts.length - firstThreeProducts.length;

  const groupUsers = group.users.map((userEmail) => users[userEmail]);
  const firstThreeUsers = groupUsers.slice(0, 3);
  const extraUsersLength = groupUsers.length - firstThreeUsers.length;

  return (
    <>
      <Grid
        className="lqd-group-row"
        columns={{ lg: 16, md: 20 }}
        container
        sx={{
          "&:hover": { backgroundColor: "rgba(127, 135, 152, 0.08)" },
          backgroundColor: showDetails ? "rgba(127, 135, 152, 0.16)" : "none",
          borderBottom: "1px solid rgba(65, 70, 103, 0.24)",
          pb: "11px",
          pt: 3,
        }}
      >
        <Grid item lg={6} md={7} sx={{ display: "flex", flexDirection: "column", pl: 3 }}>
          <LqdTypography color="rgba(33, 36, 42, 1) " textstyle="p2Paragraph">
            {group.title}
          </LqdTypography>
          <LqdLink onClick={() => onEditGroup(1)} sx={{ textDecoration: "none", width: "fit-content" }}>
            <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
              Editar
            </LqdTypography>
          </LqdLink>
        </Grid>
        <Grid item lg={2} md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <LqdTooltip
            placement="left"
            titlebody={
              firstThreeProducts.length > 0 ? (
                <>
                  {firstThreeProducts.map((product, index) => (
                    <Box key={`${product}-${index}`}>{product}</Box>
                  ))}
                  {extraProductsLength > 0 ? `+ ${extraProductsLength} outros` : null}
                </>
              ) : (
                "Nenhum produto"
              )
            }
            titleheader="Produtos"
          >
            <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
              <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", display: "inline" }} textstyle="p2Paragraph">
                {groupProducts.length > 9 ? groupProducts.length : "0" + groupProducts.length}
              </LqdTypography>
              <LqdLink onClick={() => onEditGroup(2)} sx={{ textDecoration: "none", width: "fit-content" }}>
                <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
                  Editar
                </LqdTypography>
              </LqdLink>
            </Box>
          </LqdTooltip>
        </Grid>
        <Grid item lg={2.5} md={3} sx={{ display: "flex", flexDirection: "column" }}>
          <LqdTooltip
            placement="left"
            titlebody={
              <>
                {firstThreeUsers.length > 0 ? (
                  <>
                    {firstThreeUsers.map((user) => (
                      <Box key={user?.email}>{user?.name}</Box>
                    ))}
                    {extraUsersLength > 0 ? <Box>{`+ ${extraUsersLength} outros`}</Box> : null}
                  </>
                ) : (
                  "Nenhum usuário"
                )}
                <Box onClick={() => navigate("/config/users")} sx={{ cursor: "pointer", fontSize: "14px", mt: 1 }}>
                  {"Ver detalhes >"}
                </Box>
              </>
            }
            titleheader="Usuários"
          >
            <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
              <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", display: "inline" }} textstyle="p2Paragraph">
                {groupUsers.length > 9 ? groupUsers.length : "0" + groupUsers.length}
              </LqdTypography>
              <LqdLink onClick={() => onEditGroup(3)} sx={{ textDecoration: "none", width: "fit-content" }}>
                <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
                  Editar
                </LqdTypography>
              </LqdLink>
            </Box>
          </LqdTooltip>
        </Grid>
        <Grid item lg={3.5} md={4} sx={{ display: "flex", flexDirection: "column" }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
            {format(group.created, "dd MMM yyyy", { locale: ptBR })}
          </LqdTypography>
          <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", mt: 0.5 }} textstyle="p2Paragraph">
            {format(group.created, "HH:mm", { locale: ptBR })}
          </LqdTypography>
        </Grid>
        <Grid item lg={2} md={3} sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", pr: 2 }}>
          <Box className="lqd-group-toggle-button">
            <LqdToggle
              active={group.active}
              onChange={async () => await onToggleButtonClick(!group.active)}
              renderText
            />
          </Box>
          <LqdIconButton
            onClick={() => {
              selectedGroupRowKey === groupRowKey ? setSelectedGroupRowKey("") : setSelectedGroupRowKey(groupRowKey);
            }}
            type="ghostIcon"
          >
            {showDetails ? <ArrowDropUp /> : <ArrowDropDown />}
          </LqdIconButton>
        </Grid>
      </Grid>
      {showDetails ? (
        <Box
          sx={{ display: "flex", flexDirection: "column", maxHeight: "55vh", ml: 3, overflowY: "auto", width: "95%" }}
        >
          <Box>
            <Box
              sx={{
                backdropFilter: "blur(20px)",
                height: "78px !important",
                left: 0,
                position: "sticky",
                top: 0,
                width: "100%",
                zIndex: 1,
              }}
            >
              <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ boxSizing: "border-box", py: 3 }}>
                Produtos
              </LqdTypography>
            </Box>
            {group.products.length ? (
              group.products.map((productId) => {
                const product = (products as unknown as ObjectOf<Product>)[productId];
                return (
                  <Box
                    key={product.id}
                    sx={{
                      "&:hover": { backgroundColor: "rgba(127, 135, 152, 0.16)" },
                      backgroundColor: "rgba(127, 135, 152, 0.04)",
                    }}
                  >
                    <ProductRow checkbox={<Box />} product={product} />
                  </Box>
                );
              })
            ) : (
              <NoDataGroupRow text="Não há produtos neste grupo." />
            )}
            <Box
              sx={{
                backdropFilter: "blur(20px)",
                height: "78px !important",
                left: 0,
                position: "sticky",
                top: 0,
                width: "100%",
                zIndex: 1,
              }}
            >
              <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ boxSizing: "border-box", py: 3 }}>
                Usuários
              </LqdTypography>
            </Box>
            {group.users.length ? (
              group.users.map((userIdEmail) => {
                const user = (users as unknown as ObjectOf<User>)[userIdEmail];
                return (
                  <Fragment key={user?.email}>
                    <Box
                      sx={{
                        "&:hover": { backgroundColor: "rgba(127, 135, 152, 0.16)" },
                        backgroundColor: "rgba(127, 135, 152, 0.04)",
                      }}
                    >
                      <UserCard
                        added={false}
                        hideButton
                        onAddUserClick={() => {}}
                        onRemoveUserClick={() => {}}
                        user={user}
                      />
                    </Box>
                    <Divider sx={{ ml: 3, width: "95%" }} />
                  </Fragment>
                );
              })
            ) : (
              <NoDataGroupRow text="Não há usuários neste grupo." />
            )}
            <Divider sx={{ width: "100%" }} />
          </Box>
        </Box>
      ) : null}
    </>
  );
}

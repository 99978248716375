import {
  LqdIconButton,
  LqdLeftArrowIcon,
  LqdRightArrowIcon,
  LqdSelect,
  LqdTextField,
  LqdTypography,
} from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import newContractsPerMonthOptions from "../../data/newContractsPerMonthOptions";

export type Step3Form = {
  tenantMainActivity: string;
  tenantNewContractsPerMonth: string | undefined;
};

type SignUpFormStep3Props = {
  defaultValues?: Step3Form;
  onGoBack: () => void;
  onSubmit: (form: Step3Form) => void;
  realEstateActivitiesList: Array<{ label: string; value: string }>;
  tenantSegment: string;
};

export default function SignUpFormStep3(props: SignUpFormStep3Props) {
  const { defaultValues, onGoBack, onSubmit, realEstateActivitiesList, tenantSegment } = props;

  const [tenantMainActivity, setTenantMainActivity] = useState(defaultValues?.tenantMainActivity || "");
  const [tenantNewContractsPerMonth, setTenantNewContractsPerMonth] = useState<string>(
    defaultValues?.tenantNewContractsPerMonth || (null as unknown as string)
  );

  let tenantNewContractsPerMonthDefaultIndex: number | undefined = newContractsPerMonthOptions.findIndex(
    (contractsPerMonthOption) => contractsPerMonthOption.value === defaultValues?.tenantNewContractsPerMonth
  );
  if (tenantNewContractsPerMonthDefaultIndex === -1) {
    tenantNewContractsPerMonthDefaultIndex = undefined;
  }

  const renderTenantActivityInput = () => {
    switch (tenantSegment) {
      case "Real-estate":
        return (
          <>
            <LqdTypography sx={{ display: "block", mb: 1 }} textstyle="c1Caption">
              Atividade Principal
            </LqdTypography>
            <LqdSelect
              onChange={setTenantMainActivity}
              options={realEstateActivitiesList}
              placeholder="Escolha a atividade principal"
              value={tenantMainActivity}
            />
          </>
        );
      default:
        return (
          <>
            <LqdTypography
              sx={{ borderColor: "rgba(212, 215, 220, 1)", color: "rgba(101, 110, 127, 1)", display: "block", mb: 1 }}
              textstyle="c1Caption"
            >
              Atividade principal da empresa
            </LqdTypography>
            <LqdTextField
              onChange={(event) => setTenantMainActivity(event.target.value)}
              placeholder="Digite"
              sx={{ borderColor: "rgba(212, 215, 220, 1)", color: "rgba(101, 110, 127, 1)" }}
              value={tenantMainActivity}
            />
          </>
        );
    }
  };

  const maySubmit = tenantMainActivity && tenantNewContractsPerMonth !== null;

  return (
    <form onSubmit={() => onSubmit({ tenantMainActivity, tenantNewContractsPerMonth })}>
      {renderTenantActivityInput()}
      <LqdTypography
        sx={{ borderColor: "rgba(212, 215, 220, 1)", color: "rgba(101, 110, 127, 1)", display: "block", mb: 1 }}
        textstyle="c1Caption"
      >
        Volume médio de novos contratos
      </LqdTypography>
      <LqdSelect
        onChange={setTenantNewContractsPerMonth}
        options={newContractsPerMonthOptions}
        placeholder="Quantidade por mês"
        value={tenantNewContractsPerMonth}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <LqdIconButton buttonsize="large" onClick={onGoBack} type="outlinePrimary">
          <LqdLeftArrowIcon />
        </LqdIconButton>
        <LqdIconButton
          disabled={!maySubmit}
          onClick={() => onSubmit({ tenantMainActivity, tenantNewContractsPerMonth })}
        >
          <LqdRightArrowIcon />
        </LqdIconButton>
      </Box>
    </form>
  );
}

import { LqdEditIcon, LqdIconButton, LqdToggle, LqdTypography } from "@/liquid-components/src";
import { Grid } from "@mui/material";
import { format } from "date-fns";
import { Simulator } from "../types/Simulator";

type SimulatorRowProps = {
  onDuplicateClick: () => void;
  onEditClick: () => void;
  simulator: Simulator;
};

/** Componente responsável por uma linha da listagem da página de gerenciamento de simuladores. */
export default function SimulatorRow(props: SimulatorRowProps) {
  const { onDuplicateClick, onEditClick, simulator } = props;

  return (
    <Grid
      className="lqd-tenant-row"
      columns={{ lg: 14, md: 20 }}
      container
      sx={{
        "&:hover": { backgroundColor: "rgba(127, 135, 152, 0.08)" },
        ".MuiGrid-item": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 3,
        },
        borderBottom: "1px solid rgba(65, 70, 103, 0.24)",
      }}
    >
      <Grid item lg={4} md={7}>
        <LqdTypography textstyle="p2Paragraph">{simulator.name}</LqdTypography>
      </Grid>
      <Grid item md={3}>
        <LqdTypography sx={{ display: "inline" }} textstyle="p2Paragraph">
          {simulator.tenant_name}
        </LqdTypography>
      </Grid>
      <Grid item lg={3} md={3}>
        <LqdTypography textstyle="p2Paragraph">{format(simulator.created_at * 1000, "dd MMM yyyy")}</LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
          {format(simulator.created_at * 1000, "HH:mm")}
        </LqdTypography>
      </Grid>
      <Grid item>
        {/* TODO: adicionar onChange para desativar/ativar simuladores */}
        <LqdToggle active={simulator.is_active} onChange={() => {}} renderText />
      </Grid>
      <Grid sx={{ alignItems: "center", display: "flex", pl: 12 }}>
        <LqdTypography
          color="rgba(127, 135, 152, 1)"
          onClick={onDuplicateClick}
          sx={{ "&:hover": { color: "rgba(65, 70, 103, 1)", cursor: "pointer" } }}
          textstyle="p2Paragraph"
        >
          Duplicar
        </LqdTypography>
      </Grid>
      <Grid item>
        <LqdIconButton onClick={onEditClick} type="ghostIcon">
          <LqdEditIcon />
        </LqdIconButton>
      </Grid>
    </Grid>
  );
}

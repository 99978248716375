import { LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";

type NoDataGroupRowProps = {
  text: string;
};

export default function NoDataGroupRow(props: NoDataGroupRowProps) {
  const { text } = props;

  return (
    <Box
      sx={{
        backgroundColor: "rgba(127, 135, 152, 0.04)",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        py: 4,
      }}
    >
      <LqdTypography color="secondary" sx={{ textAlign: "center" }}>
        {text}
      </LqdTypography>
    </Box>
  );
}

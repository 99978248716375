import { LqdDownIcon, LqdTypography } from "@/liquid-components/src";
import { Box, MenuItem, Select, SxProps } from "@mui/material";
import { useAppSelector } from "../../../../../store";
import { SimulatorVariable } from "../../../types/SimulatorVariable";

type SimulatorResultEmptyCardProps = {
  handleChange: (variableName: string, index: number) => void;
  index: number;
  selectedVariable: { content: SimulatorVariable | null; id: number };
  wrapper: SxProps;
};

export default function SimulatorEmptyCard(props: SimulatorResultEmptyCardProps) {
  const { handleChange, index, selectedVariable, wrapper } = props;

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  return (
    <Box sx={wrapper}>
      <Select
        displayEmpty
        fullWidth
        IconComponent={LqdDownIcon}
        inputProps={{
          sx: {
            ":active": { boxShadow: "0px 0px 0px 4px rgba(235, 235, 245, 0.70)" },
            ":focus": { borderColor: "rgba(107, 114, 128, 1)" },
            ":hover": {
              borderColor: "rgba(107, 114, 128, 1)",
            },
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 1)",
            border: "1px solid rgba(229, 231, 235, 1)",
            borderRadius: "8px",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04)",
            display: "flex",
            pl: "12px !important",
          },
        }}
        MenuProps={{
          MenuListProps: { sx: { ".MuiMenuItem-root.Mui-selected": { backgroundColor: "transparent" }, p: 0 } },
          PaperProps: {
            sx: {
              border: "1px solid rgba(33, 36, 42, 1)",
              borderRadius: "8px",
              boxSizing: "border-box",
              maxHeight: "400px",
              mt: "10px",
              pl: 2,
              pr: "9px",
              py: 3,
            },
          },
        }}
        onChange={(event) => handleChange(event.target.value as string, index)}
        renderValue={() => "Selecione uma variável"}
        sx={{
          ".MuiInput-input": { height: "48px !important", p: 0 },
          ".MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input": {
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
          },
          "::after": { display: "none" },
          "::before": { display: "none" },
          color: "rgba(156, 163, 175, 1)",
          mb: "10px",
          svg: { pointerEvents: "none", position: "absolute", right: "12px" },
        }}
        value={selectedVariable.content ? selectedVariable.content.name : ""}
        variant="standard"
      >
        {simulator.variables.map((variable, index) => (
          <MenuItem key={variable.name} sx={{ mt: index > 0 ? 3 : 0 }} value={variable.name}>
            <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
              {variable.label}
            </LqdTypography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

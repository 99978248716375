import { LqdButton, LqdCircularLoader, LqdLink, LqdTypography } from "@/liquid-components/src";
import { Box, Divider, Grid } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { toastCalled } from "../../../../common/commonSlice";
import { ToastNotification } from "../../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../../common/utils/useErrorHandler";
import { createTenantModalStepGoTo, editTenantModalStepGoTo } from "../../../adminSlice";
import { onCreateTenant, onLoadTenants } from "../../../adminSliceThunks";
import { TenantCreateRaw } from "../../../types/TenantCreateRaw";

type TenantFormStep4Props = {
  checkedProviders: Array<string>;
  checkedSegments: Array<string>;
  onClose: () => void;
  tenantName: string;
};

export default function TenantFormStep4(props: TenantFormStep4Props) {
  const { checkedProviders, checkedSegments, onClose, tenantName } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const { createTenant, segments, providers } = useAppSelector((state) => state.admin);

  const [loading, setLoading] = useState(false);

  const globalProvidersIds = Object.keys(providers).filter((providerId) => providers[providerId].global);
  const uniqueProviderList = [...new Set([...checkedProviders, ...globalProvidersIds])];

  const handleSubmitTenant = async () => {
    setLoading(true);

    try {
      const tenantToCreate: TenantCreateRaw = {
        business_activity: "",
        contact: { email: "", name: tenantName, phone: "" },
        country: "",
        document: { type: "", value: "" },
        new_contracts_per_month: "",
        providers: checkedProviders,
        segments: checkedSegments,
      };
      await dispatch(onCreateTenant(tenantToCreate));
      await dispatch(onLoadTenants());

      const notification: ToastNotification = {
        message: "A empresa foi criada com sucesso!",
        type: "success",
      };

      onClose();
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const onGoToStep = (step: 1 | 2 | 3 | 4) => () => {
    dispatch(createTenant ? createTenantModalStepGoTo(step) : editTenantModalStepGoTo(step));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 5, pt: { lg: 7, md: 4 } }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: { lg: 7.5, md: 5 } }} textstyle="h4Headline">
        Resumo
      </LqdTypography>
      <Box sx={{ maxHeight: "46.5vh", overflowY: "auto", width: { lg: "60vw", md: "80vw" } }}>
        <Grid container sx={{ pb: 1.5 }}>
          <Grid item xs={6}>
            <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", display: "inline-block" }} textstyle="p2Paragraph">
              Nome da Empresa
            </LqdTypography>
            <LqdLink onClick={onGoToStep(1)} sx={{ display: "inline-block", ml: 1, textDecoration: "none" }}>
              <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                Editar
              </LqdTypography>
            </LqdLink>
            <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }}>{tenantName}</LqdTypography>
          </Grid>
          <Grid item sx={{ borderLeft: "1px solid rgba(0, 0, 0, 0.12)", pl: 2 }} xs={6}>
            <Box sx={{ width: "100%" }}>
              <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", display: "inline-block" }} textstyle="p2Paragraph">
                Segmentos
              </LqdTypography>
              <LqdLink onClick={onGoToStep(2)} sx={{ display: "inline-block", ml: 1, textDecoration: "none" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  Editar
                </LqdTypography>
              </LqdLink>
              {checkedSegments.map((segmentId) => (
                <LqdTypography
                  color="rgba(33, 36, 42, 1)"
                  key={segmentId}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {segments[segmentId].name}
                </LqdTypography>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2 }} />
        <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", display: "inline-block" }} textstyle="p2Paragraph">
          Providers
        </LqdTypography>
        <LqdLink onClick={onGoToStep(3)} sx={{ display: "inline-block", ml: 1, textDecoration: "none" }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
            Editar
          </LqdTypography>
        </LqdLink>
        {uniqueProviderList.map((providerId) => (
          <LqdTypography
            key={providerId}
            sx={{
              color: "rgba(33, 36, 42, 1)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {providers[providerId].title}
          </LqdTypography>
        ))}
      </Box>
      <Box sx={{ mt: 7.5, textAlign: "right" }}>
        <LqdButton disabled={loading} onClick={handleSubmitTenant}>
          {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Criar empresa"}
        </LqdButton>
      </Box>
    </Box>
  );
}

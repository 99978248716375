import {
  LqdIconButton,
  LqdLeftArrowIcon,
  LqdPhoneInput,
  LqdSelect,
  LqdTextField,
  LqdTypography,
} from "@/liquid-components/src";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { validateEmail } from "../../../common/utils/validateEmail";
import { TenantCountry } from "../../types/TenantCountry";

export type Step1Form = {
  email: string;
  name: string;
  phoneDDI: string;
  phoneNumber: string;
  tenantCountry: "" | TenantCountry;
};

type SignUpFormStep1Props = {
  defaultValues?: Step1Form;
  onSubmit: (form: Step1Form) => void;
};

const countryOptions = [
  { label: "Brasil", value: "brazil" },
  { label: "Portugal", value: "portugal" },
];

const DDIOptions = ["+55", "+351"];

export default function SignUpFormStep1(props: SignUpFormStep1Props) {
  const { defaultValues, onSubmit } = props;

  const [email, setEmail] = useState(defaultValues?.email || "");
  const [emailError, setEmailError] = useState(false);
  const [name, setName] = useState(defaultValues?.name || "");
  const [phoneDDI, setPhoneDDI] = useState("+55");
  const [phoneNumber, setPhoneNumber] = useState(defaultValues?.phoneNumber || "");
  const [tenantCountry, setTenantCountry] = useState<TenantCountry>(
    defaultValues?.tenantCountry || ("" as TenantCountry)
  );

  useEffect(() => {
    if (!email) setEmailError(false);
  }, [email]);

  const handleEmailBlur = () => {
    if (email) {
      const emailIsValid = validateEmail(email);
      setEmailError(!emailIsValid);
    }
  };

  const maySubmit = name && email && !emailError && tenantCountry && phoneDDI && phoneNumber.length > 8;

  return (
    <form>
      <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ display: "block" }} textstyle="c1Caption">
        Nome
      </LqdTypography>
      <LqdTextField
        onChange={(event) => setName(event.target.value)}
        placeholder="Digite"
        sx={{ borderColor: "rgba(212, 215, 220, 1)", color: "rgba(101, 110, 127, 1)" }}
        value={name}
      />
      <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ display: "block" }} textstyle="c1Caption">
        E-mail
      </LqdTypography>
      <LqdTextField
        error={emailError ? !!email : false}
        label={
          emailError && !!email ? (
            <LqdTypography color="error" textstyle="c1Caption">
              E-mail inválido. Tente novamente.
            </LqdTypography>
          ) : null
        }
        onBlur={handleEmailBlur}
        onChange={(event) => setEmail(event.target.value)}
        placeholder="Digite"
        sx={{ borderColor: "rgba(212, 215, 220, 1)", color: "rgba(101, 110, 127, 1)" }}
        value={email}
      />
      <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ display: "block", mb: "8px" }} textstyle="c1Caption">
        Origem da Empresa
      </LqdTypography>
      <LqdSelect
        onChange={setTenantCountry as React.Dispatch<React.SetStateAction<string>>}
        options={countryOptions}
        placeholder="País"
        value={tenantCountry}
      />
      <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ display: "block" }} textstyle="c1Caption">
        Contato
      </LqdTypography>
      <LqdPhoneInput
        DDI={phoneDDI}
        DDIOptions={DDIOptions}
        onDDIChange={(value: string) => setPhoneDDI(value)}
        onNumberChange={(value: string) => setPhoneNumber(value)}
        phoneNumber={phoneNumber || ""}
        placeholder="Digite"
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
        <LqdIconButton
          disabled={!maySubmit}
          onClick={() => onSubmit({ email, name, phoneDDI, phoneNumber, tenantCountry })}
        >
          <LqdLeftArrowIcon />
        </LqdIconButton>
      </Box>
    </form>
  );
}

import { LqdEditIcon, LqdIconButton, LqdLink, LqdToggle, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box, Grid } from "@mui/material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { handleGlobalProviders } from "../../../products/utils/handleGlobalProviders";
import { editTenantModalOpened, onSelectTenant } from "../../adminSlice";
import { AdminPagePanels } from "../../types/AdminPagePanels";
import { TenantResponse } from "../../types/TenantResponse";

type TenantRowProps = {
  handleTenantStatusChange: (tenant: TenantResponse) => void;
  setCurrentPanel: (panel: AdminPagePanels) => void;
  tenant: TenantResponse;
};

export default function TenantRow(props: TenantRowProps) {
  const { handleTenantStatusChange, setCurrentPanel, tenant } = props;

  const dispatch = useAppDispatch();

  const { providers, segments } = useAppSelector((state) => state.admin);

  const uniqueProviderList = handleGlobalProviders(Object.values(providers), tenant.providers);

  const providersLength = uniqueProviderList.length > 9 ? uniqueProviderList.length : "0" + uniqueProviderList.length;

  const firstThreeProviders = uniqueProviderList.slice(0, 3);
  const extraProvidersLength = uniqueProviderList.length - firstThreeProviders.length;

  const segmentsLength = tenant.segments.length > 9 ? tenant.segments.length : "0" + tenant.segments.length;
  const firstThreeSegments = tenant.segments.slice(0, 3);
  const extraSegmentsLength = tenant.segments.length - firstThreeSegments.length;

  const handleSelectTenant = () => {
    dispatch(onSelectTenant(tenant.code));
    setCurrentPanel(AdminPagePanels.TENANT_CONFIG);
  };

  return (
    <Grid
      className="lqd-tenant-row"
      columns={{ lg: 14, md: 20 }}
      container
      sx={{
        "&:hover": { backgroundColor: "rgba(127, 135, 152, 0.08)" },
        borderBottom: "1px solid rgba(65, 70, 103, 0.24)",
        pb: "11px",
        pt: 3,
      }}
    >
      <Grid item lg={5.5} md={7} sx={{ display: "flex", flexDirection: "column", pl: 3 }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
          {tenant.contact.name}
        </LqdTypography>
        <LqdLink
          onClick={() => dispatch(editTenantModalOpened({ step: 1, tenant: tenant }))}
          sx={{ textDecoration: "none", width: "fit-content" }}
        >
          <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
            Editar
          </LqdTypography>
        </LqdLink>
      </Grid>
      <Grid item lg={2} md={3} sx={{ display: "flex", flexDirection: "column" }}>
        <LqdTooltip
          placement="left"
          titlebody={
            firstThreeSegments.length > 0 ? (
              <>
                {firstThreeSegments.map((segmentId, index) => (
                  <Box key={`${segmentId}-${index}`}>{segments[segmentId].name}</Box>
                ))}
                {extraSegmentsLength > 0 ? <Box>{`+ ${extraSegmentsLength} outros`}</Box> : null}
              </>
            ) : (
              "Nenhum segmento"
            )
          }
          titleheader="Segmentos"
        >
          <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ display: "inline" }} textstyle="p2Paragraph">
              {segmentsLength}
            </LqdTypography>
            <LqdLink
              onClick={() => dispatch(editTenantModalOpened({ step: 2, tenant: tenant }))}
              sx={{ textDecoration: "none", width: "fit-content" }}
            >
              <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
                Editar
              </LqdTypography>
            </LqdLink>
          </Box>
        </LqdTooltip>
      </Grid>
      <Grid item lg={2} md={3} sx={{ display: "flex", flexDirection: "column" }}>
        <LqdTooltip
          placement="left"
          titlebody={
            firstThreeProviders.length > 0 ? (
              <>
                {firstThreeProviders.map((providerId, index) => (
                  <Box key={`${providerId}-${index}`}>{providers[providerId].title}</Box>
                ))}
                {extraProvidersLength > 0 ? <Box>{`+ ${extraProvidersLength} outros`}</Box> : null}
              </>
            ) : (
              "Nenhum provider"
            )
          }
          titleheader="Providers"
        >
          <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ display: "inline" }} textstyle="p2Paragraph">
              {providersLength}
            </LqdTypography>
            <LqdLink
              onClick={() => dispatch(editTenantModalOpened({ step: 3, tenant: tenant }))}
              sx={{ textDecoration: "none", width: "fit-content" }}
            >
              <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                Editar
              </LqdTypography>
            </LqdLink>
          </Box>
        </LqdTooltip>
      </Grid>
      <Grid item lg={2} md={4} sx={{ display: "flex", flexDirection: "column" }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
          {tenant.createdAt ? format(tenant.createdAt, "dd MMM yyyy", { locale: ptBR }) : "Data não encontrada!"}
        </LqdTypography>
        <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", mt: 0.5 }} textstyle="p2Paragraph">
          {tenant.createdAt ? format(tenant.createdAt, "HH:mm", { locale: ptBR }) : "Hora não encontrada!"}
        </LqdTypography>
      </Grid>
      <Grid item lg={2} md={3} sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
        <LqdToggle active={!tenant.isBlocked} onChange={() => handleTenantStatusChange(tenant)} renderText />
      </Grid>
      <Grid item lg={0.5} md={3} sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
        <LqdIconButton onClick={handleSelectTenant} sx={{ ml: -3, mt: -1 }} type="ghostIcon">
          <LqdEditIcon />
        </LqdIconButton>
      </Grid>
    </Grid>
  );
}

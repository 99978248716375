import { AnalysisColumn } from "../types/AnalysisColumn";
import { AnalysisStatus } from "../types/AnalysisStatus";

export function getAnalysisColumn(status: AnalysisStatus): AnalysisColumn {
  switch (status) {
    case AnalysisStatus.ONBOARDING:
      return AnalysisColumn.ONBOARDING;
    case AnalysisStatus.MODERATION:
      return AnalysisColumn.MODERATION;
    case AnalysisStatus.MATCH:
    case AnalysisStatus.UNMATCH:
      return AnalysisColumn.COMPLETED;
    default:
      return AnalysisColumn.ONBOARDING;
  }
}

import { LqdButton, LqdCircularLoader, LqdInput, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { createGroupModalStepNext, editGroupFormStepChangedTo } from "../groupsSlice";

type GroupFormStep1Props = {
  editing?: boolean;
  loading?: boolean;
  onSubmit?: () => void;
  setTitle: (value: string) => void;
  title: string;
};

const CHAR_LIMIT = 25;

export default function GroupFormStep1(props: GroupFormStep1Props) {
  const { editing, loading, onSubmit, setTitle, title } = props;

  const dispatch = useAppDispatch();

  const { groups, groupBeingEdited } = useAppSelector((state) => state.groups);

  const [hasGroupNameAlteration, setHasGroupNameAlteration] = useState<boolean>(false);

  const groupTitles = Object.values(groups).map((group) => group.title);
  const groupExists = groupTitles.some((groupTitle) => groupTitle.trim().toLowerCase() === title.trim().toLowerCase());
  const exceededNameLength = title.length > CHAR_LIMIT;

  const handleTitleChange = (newTitle: string) => {
    setHasGroupNameAlteration(true);
    setTitle(newTitle);
  };

  /** Avança de step se estiver no modo criação; vai pro último step se estiver no modo edição. */
  const handleSubmit = () => {
    if (onSubmit) {
      return onSubmit();
    }

    if (editing) {
      dispatch(editGroupFormStepChangedTo(4));
    } else {
      dispatch(createGroupModalStepNext());
    }
  };

  const handleNameError = () => {
    switch (true) {
      case loading:
        return "";
      case exceededNameLength:
        return t(`Você ultrapassou o limite de {{limit}} caracteres. Abrevie para continuar.`, { limit: CHAR_LIMIT });
      case groupExists:
        return t("Já existe um grupo com este nome.");
      default:
        return "";
    }
  };

  let maySubmit = title.length >= 3 && !groupExists && !exceededNameLength;
  // Impede o submit caso o novo nome do grupo seja igual ao nome do grupo original
  maySubmit = editing ? maySubmit && title !== groupBeingEdited?.title : maySubmit;

  return (
    <Box sx={{ mt: 20, px: 5, width: { lg: "60vw", md: "80vw" } }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: 3 }} textstyle="h5Headline">
        {t("Nome do grupo")}
      </LqdTypography>
      <LqdInput
        alertMessage={hasGroupNameAlteration ? handleNameError() : ""}
        characterLimit={CHAR_LIMIT}
        maskType="string"
        onConfirm={handleSubmit}
        onConfirmValidation={Boolean(maySubmit && !loading)}
        setValue={handleTitleChange}
        showCharacterLimit
        value={title}
      />
      <Box sx={{ mt: 3, textAlign: "right" }}>
        <LqdButton disabled={!maySubmit || loading} onClick={handleSubmit}>
          {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : t("Avançar")}
        </LqdButton>
      </Box>
    </Box>
  );
}

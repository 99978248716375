import { onUpdateProduct } from "@products/productsSlice";
import { createAsyncThunk, createSlice, isRejected, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
} from "react-flow-renderer";
import { ObjectOf } from "../common/types/ObjectOf";
import getKey from "../common/utils/getKey";
import { Product } from "../products/types/Product";
import getNodeCategories from "./api/getNodeCategories";
import { getNodesList } from "./api/getNodesList";
import { loadRules } from "./api/loadRules";
import { upsertRules } from "./api/upsertRules";
import { LoopInRulesError } from "./errors/LoopInRules";
import { NullRulesError } from "./errors/NullRules";
import { SaveRulesError } from "./errors/SaveRules";
import { StartNodeNotFoundError } from "./errors/StartNodeNotFound";
import Kpi from "./types/Kpi";
import NodeCategory from "./types/NodeCategory";
import { ProviderNodeData } from "./types/NodeData";
import Proponent from "./types/Proponent";
import RawNode from "./types/RawNode";
import getRawNodes from "./utils/getRawNodes";
import getRules from "./utils/getRules";
import { validateEdges } from "./utils/validateEdges";

interface ProductBuilderState {
  activeNode: Node | null; // Nó ativo, pra ser aberto na ConfigSidebar
  autoSave: boolean; // Indica se é pra ter autosave
  badNodes: Array<string>; // Nós que precisam ser corrigidos (lista de IDs)
  builderIsReady: boolean; // Indica se os dados do builder ja foram carregados
  edges: Array<Edge>; // Edges do react-flow
  initialRules: Array<RawNode>;
  nodeCategories: Array<NodeCategory>; // Categorias de nós, exibidas na AddNodeSidebar
  nodes: Array<Node>; // Nós do react-flow
  proponents: Array<Proponent>; // Lista de proponentes do produto
  saving: boolean; // Indica se está salvando as rules
}

const initialState: ProductBuilderState = {
  activeNode: null,
  autoSave: false,
  badNodes: [],
  builderIsReady: false,
  edges: [],
  initialRules: [],
  nodeCategories: [],
  nodes: [],
  proponents: [],
  saving: false,
};

/** Carrega as categorias de nós para inicializar o builder na edição/creação de templates. */
export const onLoadProductBuilderForTemplate = createAsyncThunk(
  "productBuilder/onLoadProductBuilderForTemplate",
  async () => {
    const promises = [getNodeCategories()];
    const [nodeCategories] = await Promise.all(promises);
    return { nodeCategories };
  }
);

/** Carrega as categorias de nós e rules de um produto quando abre a paǵina do builder. */
export const onLoadProductBuilder = createAsyncThunk("productBuilder/loadProductBuilder", async (productId: string) => {
  const promises = [getNodeCategories(), loadRules(productId), getNodesList()];

  const [nodeCategories, loadRulesRes] = await Promise.all(promises);

  const rulesData = (
    loadRulesRes as AxiosResponse<{
      data: null | { auto_save: boolean; edges: Array<Edge>; rules: Array<RawNode> };
    }>
  ).data.data;

  if (!rulesData) {
    throw new NullRulesError();
  }

  const { rules: rawNodes, edges, auto_save } = rulesData;
  const { nodes, proponents } = getRules(rawNodes, nodeCategories as Array<NodeCategory>);

  return { autoSave: auto_save, edges, nodeCategories, nodes, proponents };
});

/** Salva as rules, por autosave ou publish. */
export const onSaveRules = createAsyncThunk(
  "productBuilder/onSaveRules",
  async (
    {
      autoSave,
      edges,
      nodes,
      product,
      proponents,
      publish,
      simulatorKpis,
    }: {
      autoSave: boolean;
      edges: Array<Edge>;
      nodes: Array<Node>;
      product: Product;
      proponents: Array<Proponent>;
      publish?: boolean;
      simulatorKpis: ObjectOf<Array<Kpi> | undefined>;
    },
    { dispatch }
  ) => {
    const validProponents = proponents.filter((proponent) => proponent.name && proponent.type.length > 0);
    const validEdges = validateEdges(edges, nodes, validProponents);
    const promises = [];

    try {
      const rawNodes = getRawNodes(nodes, validEdges, validProponents, simulatorKpis);
      promises.push(upsertRules(rawNodes, edges, product.id, autoSave));

      if (publish) {
        promises.push(upsertRules(rawNodes, edges, product.id, autoSave, publish), dispatch(onUpdateProduct(product)));
      }

      await Promise.all(promises);
      return { autoSave, edges, nodes, proponents };
    } catch (error) {
      const errorData = error as AxiosError;

      const { bad_connections, message } = errorData.response?.data || {};
      const status = errorData.response?.status;

      if (message === "Start node not found") {
        throw new StartNodeNotFoundError({ horizontal: "right" });
      }
      if (message === "There is a loop in rules") {
        throw new LoopInRulesError({ horizontal: "right" });
      }
      if (status === 422) {
        return { badNodes: bad_connections };
      }
      throw new SaveRulesError({ horizontal: "right" });
    }
  }
);

const productBuilderSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(onLoadProductBuilder.fulfilled, (state, action) => {
        const { autoSave, edges, nodeCategories, nodes, proponents } = action.payload;
        state.autoSave = autoSave;
        state.builderIsReady = true;
        state.edges = edges;
        state.initialRules = getRawNodes(nodes, edges, proponents, {});
        state.nodeCategories = nodeCategories as Array<NodeCategory>;
        state.nodes = nodes;
        state.proponents = proponents;
        state.saving = false;
      })
      .addCase(onSaveRules.pending, (state) => {
        state.saving = true;
      })
      .addCase(onSaveRules.fulfilled, (state, action) => {
        state.badNodes = [];
        state.saving = false;
        // Se houve erro de validação dos nós
        if (action.payload.badNodes) {
          const badNodes = action.payload.badNodes;
          state.badNodes = badNodes;
        }
      })
      .addCase(onLoadProductBuilderForTemplate.fulfilled, (state, action) => {
        const { nodeCategories } = action.payload;
        state.builderIsReady = true;
        state.nodeCategories = nodeCategories as Array<NodeCategory>;
        state.saving = false;
      })
      .addMatcher(isRejected, (state) => {
        state.saving = false;
      }),
  initialState,
  name: "productBuilder",
  reducers: {
    activeNodeChanged(state, action: PayloadAction<Node | null>) {
      const activeNode = action.payload;
      state.activeNode = activeNode;
    },

    autoSaveChanged(state, action: PayloadAction<boolean>) {
      const autoSave = action.payload;
      state.autoSave = autoSave;
    },

    connectionAdded(state, action: PayloadAction<Connection>) {
      const connection = action.payload;
      const edges = addEdge(connection, state.edges);
      const validEdges = validateEdges(edges, state.nodes, state.proponents);
      state.edges = validEdges;
    },

    edgeRemoved(state, action: PayloadAction<string>) {
      const edgeId = action.payload;
      const edgeIndex = state.edges.findIndex((edge) => edge.id === edgeId);
      state.edges.splice(edgeIndex, 1);
    },

    edgesChanged(state, action: PayloadAction<Array<EdgeChange>>) {
      const changes = action.payload;
      const changedEdges = applyEdgeChanges(changes, state.edges);
      const validEdges = validateEdges(changedEdges, state.nodes, state.proponents);
      state.edges = validEdges;
    },

    edgesUpdated(state, action: PayloadAction<Array<Edge>>) {
      const edges = action.payload;
      state.edges = edges;
    },

    initialRulesChanged(state, action: PayloadAction<Array<RawNode>>) {
      state.initialRules = action.payload;
    },

    nodeDuplicated(state, action: PayloadAction<Node>) {
      const nodeToDuplicate = action.payload;
      const duplicatedNode = {
        data: JSON.parse(JSON.stringify(nodeToDuplicate.data)),
        id: "node" + getKey(),
        position: {
          x: nodeToDuplicate.position.x + 300,
          y: nodeToDuplicate.position.y,
        },
        type: nodeToDuplicate.type,
      };
      // Atribui um novo id às conditions do node, se houver alguma
      const duplicatedNodeAsProvider = duplicatedNode.data as ProviderNodeData;
      const duplicatedNodeAsProviderConditions = duplicatedNodeAsProvider.custom_data?.conditions;
      if (duplicatedNodeAsProviderConditions?.length) {
        duplicatedNodeAsProvider.custom_data.conditions = duplicatedNodeAsProviderConditions.map((condition) => ({
          ...condition,
          id: getKey(),
        }));
      }
      state.nodes.push(duplicatedNode);
    },

    nodeRemoved(state, action: PayloadAction<string>) {
      const nodeId = action.payload;
      const nodeIndex = state.nodes.findIndex((node) => node.id === nodeId);
      if (nodeIndex >= 0) {
        state.nodes.splice(nodeIndex, 1);
      }
    },

    nodeUpdated(state, action: PayloadAction<Node>) {
      const updatedNode = action.payload;
      const nodeIndex = state.nodes.findIndex((node) => node.id === updatedNode.id);
      if (nodeIndex >= 0) {
        const updatedNodes = Array.from(state.nodes);
        updatedNodes.splice(nodeIndex, 1, updatedNode);
        state.nodes = updatedNodes;
      }
    },

    nodesAdded(state, action: PayloadAction<Array<Node>>) {
      const nodes = action.payload;
      state.nodes = [...state.nodes, ...nodes];
    },

    nodesChanged(state, action: PayloadAction<Array<NodeChange>>) {
      const changes = action.payload;
      state.nodes = applyNodeChanges(changes, state.nodes);
    },

    proponentsChanged(state, action: PayloadAction<Array<Proponent>>) {
      const proponents = action.payload;
      state.proponents = proponents;
    },
    resetProductBuilderState(state) {
      state.activeNode = null;
      state.autoSave = false;
      state.badNodes = [];
      state.builderIsReady = false;
      state.edges = [];
      state.nodeCategories = [];
      state.nodes = [];
      state.proponents = [];
      state.saving = false;
    },
  },
});

export const {
  activeNodeChanged,
  autoSaveChanged,
  connectionAdded,
  edgeRemoved,
  edgesChanged,
  edgesUpdated,
  initialRulesChanged,
  nodeDuplicated,
  nodeRemoved,
  nodeUpdated,
  nodesAdded,
  nodesChanged,
  proponentsChanged,
  resetProductBuilderState,
} = productBuilderSlice.actions;

export default productBuilderSlice.reducer;

import { LqdTypography } from "@/liquid-components/src";
import LiquidAILogo from "@common/components/LiquidAILogo";
import useWindowSize from "@common/hooks/useWindowSize";
import { Box, Stack } from "@mui/material";
import expiredImg from "../assets/expired_link_img.svg";
import SignatureHeader from "./SignatureHeader";

type SomethingWentWrongProps = {
  message: string;
};

function SomethingWentWrong(props: SomethingWentWrongProps) {
  const { message } = props;
  const { width } = useWindowSize();

  return (
    <>
      <SignatureHeader />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          height: "calc(100vh - 60px)",
          justifyContent: "center",
          marginLeft: "48px",
          marginRight: "60px",
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Stack sx={{ maxWidth: "530px" }}>
            <img
              alt="expired link"
              src={expiredImg}
              style={{ display: width === "xs" ? "block" : "none", height: "255px", marginLeft: "16px" }}
            />
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 4 }} textstyle="h3Headline">
              {message}
            </LqdTypography>
            <LqdTypography color="rgba(33, 36, 42, 1)">
              Precisa de ajuda? Envie um e-mail para suporte@askliquid.com.
            </LqdTypography>
          </Stack>
          <LiquidAILogo color="rgba(127, 135, 152, 1)" width={150} />
        </Box>
        <img
          alt="expired link"
          src={expiredImg}
          style={{ display: width === "xs" ? "none" : "block", marginLeft: "16px" }}
        />
      </Box>
    </>
  );
}

export default SomethingWentWrong;

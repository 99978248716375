import {
  LqdActionIcon,
  LqdCheckboxOffIcon,
  LqdCheckboxOnIcon,
  LqdChip,
  LqdDownIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { capitalizeString } from "@common/utils/capitalize";
import { stringInitials } from "@common/utils/initials";
import { Avatar, Box, MenuItem, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled } from "../../common/commonSlice";
import {
  newAutomationFormEventChanged,
  newAutomationFormEventFieldValueChanged,
  newAutomationFormFilterAdded,
  newAutomationFormFilterRemoved,
} from "../automationsSlice";
import { automationFilterDictionary } from "../helpers/automationFilterDictionary";
import { AutomationFilter } from "../types/AutomationFilter";
import AutomationActionCardMenu from "./AutomationActionCardMenu";
import AutomationFilterForm from "./AutomationFilterForm";
import AutomationSelect from "./AutomationSelect";
import AutomationTextField from "./AutomationTextField";

type AutomationActionCardProps = {
  actionIndex: number;
};

export default function AutomationActionCard(props: AutomationActionCardProps) {
  const { actionIndex } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const { users } = useAppSelector((state) => state.users);
  const { automationModules } = useAppSelector((state) => state.automations);
  const newAutomationForm = useAppSelector((state) => state.automations.newAutomationForm)!;

  const automationModule = automationModules[newAutomationForm.origin_module];
  const [selectedEventName, setSelectedEventName] = useState<string | null>(
    newAutomationForm.actions[actionIndex].event.name || null
  );

  const selectedEvent = newAutomationForm.actions[actionIndex].event;
  const selectedEventTemplate = automationModule.action.events.find((event) => event.name === selectedEventName);

  // Value do select de lista de emails de usuários
  const usersListSelectValue = (fieldKey: string) =>
    (newAutomationForm.actions[actionIndex].event.informations[fieldKey] as Array<string>) || [];

  // Permite a adição de filtros caso haja um evento selecionado e o campo do evento esteja preenchido
  const canAddFilters =
    selectedEvent &&
    selectedEventTemplate &&
    Boolean(newAutomationForm.actions[actionIndex].event.informations[selectedEventTemplate.fields[0].key]);

  const selectedEventChanged = (eventName: string) => {
    setSelectedEventName(eventName);
    const newEventTemplate = automationModule.action.events.find((event) => event.name === eventName);
    if (!newEventTemplate) {
      throw new Error("Could not find new event template");
    }
    dispatch(newAutomationFormEventChanged({ actionIndex, eventTemplate: newEventTemplate }));
  };

  const onRemoveFilterClick = (filterIndex: number) => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Voltar" }, { onClick: () => onRemoveFilterConfirm(filterIndex), title: "Excluir filtro" }],
        body: "Tem certeza de que deseja excluir o filtro selecionado?",
        title: "Exclusão de Filtro",
        type: "default",
      })
    );
  };

  const onRemoveFilterConfirm = (filterIndex: number) => {
    dispatch(newAutomationFormFilterRemoved({ actionIndex, filterIndex }));
    dispatch(dialogCalled(null));
  };

  const onAddFilterClick = () => {
    if (canAddFilters) {
      const filter: AutomationFilter = { condition: null, key: "", value: null };
      dispatch(newAutomationFormFilterAdded({ actionIndex, filter }));
    }
  };

  // Função que renderiza a lista de nomes de usuário dentro do select
  const renderUsersList = (emailList: Array<string>) => (
    <span style={{ overflow: "hidden", textOverflow: "ellipsis", width: "80%" }}>
      {emailList.map((email) => (users[email] ? users[email].name : email)).join(", ")}
    </span>
  );

  const linearGradientDivider = `repeating-linear-gradient(to right, rgba(212, 215, 220, 1), rgba(212, 215, 220, 1) 3px,
    transparent 3px, transparent 5px)`;

  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{ backgroundColor: "rgba(249, 250, 251, 1)", borderRadius: "20px", px: 2.5, py: 4 }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "rgba(33, 36, 42, 1)",
          border: "1px solid rgba(56, 61, 70, 1)",
          borderRadius: "50%",
          display: { md: "flex", xs: "none" },
          height: "34px",
          width: "34px",
        }}
      >
        <LqdActionIcon color="rgba(255, 255, 255, 1)" size={20} />
      </Stack>
      <Box sx={{ flex: 1, flexBasis: 0, pr: 6 }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="h4Headline">
            {t("Ação")}
          </LqdTypography>
          <AutomationActionCardMenu actionIndex={actionIndex} />
        </Stack>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 3 }} textstyle="c1Caption">
          {t("Qual evento é executado.")}
        </LqdTypography>
        {newAutomationForm?.actions[actionIndex].filters.map((filter, index) => (
          <AutomationFilterForm
            actionIndex={actionIndex}
            filter={filter}
            filterIndex={index}
            key={index}
            onRemoveClick={() => onRemoveFilterClick(index)}
            template={automationModule}
          />
        ))}
        <Box
          sx={{
            "::before, ::after": {
              backgroundImage: linearGradientDivider,
              content: '""',
              flex: 1,
              height: "1px",
              opacity: canAddFilters ? 1 : 0.5,
            },
            alignItems: "center",
            display: "flex",
            my: 3,
          }}
        >
          <Box
            onClick={onAddFilterClick}
            sx={{
              borderColor: "rgba(199, 203, 209, 1)",
              borderRadius: "32px",
              borderStyle: "dashed",
              borderWidth: "1px",
              cursor: canAddFilters ? "pointer" : "normal",
              mx: 2,
              opacity: canAddFilters ? 1 : 0.5,
              px: 2,
              py: 1,
            }}
          >
            <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
              {t("Adicionar filtro")}
            </LqdTypography>
          </Box>
        </Box>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="p2Paragraph">
          {t("Evento")}
        </LqdTypography>
        <AutomationSelect
          fullWidth
          onChange={(event) => selectedEventChanged(event.target.value as string)}
          renderValue={() => (
            <LqdTypography color={selectedEventName ? "inherit" : "rgba(156, 163, 175, 1)"} textstyle="p2Paragraph">
              {selectedEventName ? automationFilterDictionary[selectedEventName] : "Selecionar evento"}
            </LqdTypography>
          )}
          value={selectedEventName}
        >
          {automationModule.action.events.map((event) => (
            <MenuItem key={event.name} value={event.name}>
              {automationFilterDictionary[event.name]}
            </MenuItem>
          ))}
        </AutomationSelect>
        {selectedEventTemplate
          ? selectedEventTemplate.fields.map((field) => (
              <Box key={field.key} sx={{ mt: 1.5, pl: "42px" }}>
                <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="p2Paragraph">
                  {automationFilterDictionary[field.key]}
                </LqdTypography>
                {field.type === "users_list" ? (
                  <AutomationSelect
                    fullWidth
                    IconComponent={LqdDownIcon}
                    MenuProps={{
                      MenuListProps: { sx: { py: 0 } },
                      PaperProps: {
                        sx: {
                          border: "1px solid rgba(33, 36, 42, 1)",
                          borderRadius: "8px",
                          maxHeight: "400px",
                          p: 1.5,
                        },
                      },
                    }}
                    multiple
                    onChange={({ target: { value } }) =>
                      dispatch(
                        newAutomationFormEventFieldValueChanged({
                          actionIndex,
                          fieldKey: field.key,
                          value: typeof value === "string" ? (value as string).split(",") : (value as string),
                        })
                      )
                    }
                    renderValue={(value) =>
                      newAutomationForm.actions[actionIndex].event.informations[field.key]?.length ? (
                        renderUsersList(value as Array<string>)
                      ) : (
                        <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
                          {automationFilterDictionary[`${field.key}__placeholder`]}
                        </LqdTypography>
                      )
                    }
                    value={usersListSelectValue(field.key)}
                  >
                    {Object.values(users).map((user, index) => (
                      <MenuItem
                        key={user.email}
                        sx={{
                          "&.Mui-selected": { backgroundColor: "transparent" },
                          borderTop: index ? "1px solid rgba(212, 215, 220, 1)" : "none",
                          py: 3,
                        }}
                        value={user.email}
                      >
                        <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                          <Stack alignItems="center" direction="row" spacing={2.5}>
                            {usersListSelectValue(field.key).find((email) => email === user.email) ? (
                              <LqdCheckboxOnIcon color="rgba(155, 162, 175, 1)" />
                            ) : (
                              <LqdCheckboxOffIcon color="rgba(155, 162, 175, 1)" />
                            )}
                            <Avatar sx={{ backgroundColor: "rgba(65, 70, 103, 0.12)", color: palette.primary.main }}>
                              <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                                {stringInitials(user.name.trim()).toUpperCase()}
                              </LqdTypography>
                            </Avatar>
                            <Box>
                              <LqdTypography textstyle="p2Paragraph">{user.name}</LqdTypography>
                              <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
                                {user.email}
                              </LqdTypography>
                            </Box>
                          </Stack>
                        </Stack>
                        <LqdChip
                          label={capitalizeString(user.userRole)}
                          sx={{
                            ".MuiChip-label": { fontSize: "12px", p: 0 },
                            borderColor: "rgba(33, 36, 42, 1)",
                            boxSizing: "border-box",
                            height: "32px",
                            p: "10px",
                            width: "auto",
                          }}
                        />
                      </MenuItem>
                    ))}
                  </AutomationSelect>
                ) : (
                  <AutomationTextField
                    fullWidth
                    onChange={(event) =>
                      dispatch(
                        newAutomationFormEventFieldValueChanged({
                          actionIndex,
                          fieldKey: field.key,
                          value: event.target.value,
                        })
                      )
                    }
                    placeholder={automationFilterDictionary[`${field.key}__placeholder`]}
                    value={newAutomationForm.actions[actionIndex].event.informations[field.key]}
                  />
                )}
              </Box>
            ))
          : null}
      </Box>
    </Stack>
  );
}

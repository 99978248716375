import { LqdButton, LqdPlusIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../common/commonSlice";
import UserRow from "../../common/components/UserRow";
import { ToastNotification } from "../../common/types/ToastNotification";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import { onLoadGroups } from "../../groups/groupsSlice";
import cancelInvite from "../api/cancelInvite";
import inviteUser from "../api/inviteUser";
import updateUser from "../api/updateUser";
import CreateUserForm from "../components/CreateUserForm";
import EditUserForm from "../components/EditUserForm";
import UsersRouteTableHeader from "../components/UsersRouteTableHeader";
import { User } from "../types/User";
import { createUserModalOpened, editUserModalOpened, onLoadUsers } from "../usersSlice";
import { getUserRaw } from "../utils/getUserRaw";

export default function UsersPage() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const [searchParams] = useSearchParams();

  const allGroups = useAppSelector((state) => state.groups.groups);
  const allUsers = useAppSelector((state) => state.users.users);

  const groups = Object.values(allGroups);
  const users = Object.values(allUsers);

  useEffect(() => {
    const update = searchParams.get("update");
    if (update === "success") {
      const notification: ToastNotification = {
        message: t("Usuário criado com sucesso"),
        type: "success",
      };

      dispatch(toastCalled(notification));
      window.history.replaceState(null, "Liquid", "/config/users");
    }
  }, [searchParams]);

  const getUserGroups = (user: User) => {
    const userGroups = groups.filter((group) => group.users.includes(user.email)).map((group) => group.id);
    return userGroups;
  };

  const handleToggleUser = async (user: User, confirmed?: boolean) => {
    if (user.active && !confirmed) {
      dispatch(
        dialogCalled({
          actions: [{ title: "Cancelar" }, { onClick: () => onConfirmInactivateUser(user), title: "Confirmar" }],
          body: t("Tem certeza que deseja desativar o usuário?"),
          title: t("Desativar usuário"),
          type: "default",
        })
      );
    }

    if (confirmed) {
      const updatedUser = { ...user, active: !user.active, groups: getUserGroups(user) };
      const userRaw = getUserRaw(updatedUser);
      dispatch(dialogLoaded(true));

      try {
        await updateUser(userRaw);
        await dispatch(onLoadUsers());
      } catch (error) {
        handleLiquidErrors(error, t("Houve um erro ao ativar o convite. Tente novamente."));
      } finally {
        dispatch(dialogCalled(null));
      }
    }
  };

  const onConfirmInactivateUser = async (user: User) => {
    await handleToggleUser(user, true);
    dispatch(dialogCalled(null));
  };

  const onResendInvite = (user: User) => {
    dispatch(
      dialogCalled({
        actions: [{ title: t("Cancelar") }, { onClick: () => onConfirmResendInvite(user), title: t("Confirmar") }],
        body: t("Tem certeza que deseja reenviar o convite?"),
        title: t("Reenviar convite"),
        type: "default",
      })
    );
  };

  const onCancelInvite = (user: User) => {
    dispatch(
      dialogCalled({
        actions: [{ title: t("Cancelar") }, { onClick: () => onConfirmCancelInvite(user), title: "Confirmar" }],
        body: t("Tem certeza que deseja cancelar o convite?"),
        title: t("Cancelar convite"),
        type: "default",
      })
    );
  };

  const onChangeNotification = (user: User) => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Cancelar" }, { onClick: () => onConfirmChangeNotification(user), title: "Confirmar" }],
        body: t("Tem certeza que deseja alterar as preferências de notificação deste usuário?"),
        title: "Preferências de Notificação",
        type: "default",
      })
    );
  };

  const onConfirmResendInvite = async (user: User) => {
    dispatch(dialogLoaded(true));

    try {
      await inviteUser(user.email);

      const notification: ToastNotification = {
        message: t("Convite reenviado com sucesso."),
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error, t("Houve um erro ao reenviar o convite. Tente novamente."));
    } finally {
      dispatch(dialogCalled(null));
    }
  };

  const onConfirmCancelInvite = async (user: User) => {
    dispatch(dialogLoaded(true));

    try {
      await cancelInvite(user.email);

      const promises = [dispatch(onLoadUsers()), dispatch(onLoadGroups())];
      await Promise.all(promises);

      const notification: ToastNotification = {
        message: t("Convite cancelado com sucesso."),
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error, t("Houve um erro ao cancelar o convite. Tente novamente."));
    } finally {
      dispatch(dialogCalled(null));
    }
  };

  const onConfirmChangeNotification = async (user: User) => {
    const updatedUser = { ...user, groups: getUserGroups(user), notifications: !user.notifications };
    const userRaw = getUserRaw(updatedUser);

    try {
      dispatch(dialogLoaded(true));
      await updateUser(userRaw);
      await dispatch(onLoadUsers());

      const notification: ToastNotification = {
        message: t("Preferências de notificação modificadas com sucesso."),
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error, t("Houve um erro ao modificar as preferências de notificação. Tente novamente."));
    } finally {
      dispatch(dialogCalled(null));
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          pb: 3,
          pl: { xs: 3 },
          pr: { xs: 3 },
          pt: 6,
          px: { lg: "100px", md: 6, sm: 4 },
        }}
      >
        <LqdTypography className="lqd-users-page-title" sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h4Headline">
          {t("Usuários")}
        </LqdTypography>
        <LqdTooltip
          placement="left"
          titlebody={t("Envie um convite para mais pessoas do seu time utilizarem a plataforma.")}
          titleheader={t("Adicionar um usuário")}
        >
          <LqdButton onClick={() => dispatch(createUserModalOpened())} startIcon={<LqdPlusIcon />}>
            Novo Usuário
          </LqdButton>
        </LqdTooltip>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "470px",
          mt: 8,
          p: { lg: 8, md: 6, sm: 4, xs: 0 },
          pt: { lg: 0 },
        }}
      >
        {users.length > 0 ? (
          <>
            <UsersRouteTableHeader />
            <Box sx={{ height: "380px", mr: 8, overflow: "auto", pl: 4, width: "70vw" }}>
              {users.map((user) => (
                <UserRow
                  groups={allGroups}
                  handleToggleUser={(confirm) => handleToggleUser(user, confirm)}
                  handleUserNotificationToggle={() => onChangeNotification(user)}
                  key={user.email}
                  onCancelInvite={() => onCancelInvite(user)}
                  onEditUser={(step) => dispatch(editUserModalOpened({ step, user }))}
                  onResendInvite={() => onResendInvite(user)}
                  user={user}
                />
              ))}
            </Box>
          </>
        ) : (
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ textAlign: "center" }} textstyle="h4Headline">
            {t("Nenhum usuário foi criado até o momento.")}
          </LqdTypography>
        )}
      </Box>
      <CreateUserForm />
      <EditUserForm />
    </>
  );
}

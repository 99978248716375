import { LqdTypography } from "@/liquid-components/src";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";

type AutomationPaginationProps = {
  currentPage: number;
  onCurrentPageChange: (value: number) => void;
  onNextPageClick: () => void;
  onPrevPageClick: () => void;
  totalPages: number;
};

export default function AutomationPagination({
  currentPage,
  totalPages,
  onCurrentPageChange,
  onNextPageClick,
  onPrevPageClick,
}: AutomationPaginationProps) {
  const [inputValue, setInputValue] = useState(currentPage.toString());
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const prevButtonDisabled = currentPage === 1;
  const nextButtonDisabled = currentPage === totalPages;

  const onSubmit = (force?: boolean) => {
    const numberValue = Number(inputValue);
    const valueIsNumber = !isNaN(numberValue);
    if (valueIsNumber && (!inputIsFocused || force)) {
      onCurrentPageChange(numberValue);
    }
  };

  /**
   * Faz o submit do valor do input toda vez que houver blur
   */
  useEffect(() => {
    onSubmit();
  }, [inputValue, inputIsFocused]);

  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <input
        onBlur={() => {
          if (inputValue === "") {
            setInputValue(currentPage.toString());
          }
          setInputIsFocused(false);
        }}
        onChange={(event) => setInputValue(event.target.value)}
        onFocus={() => setInputIsFocused(true)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSubmit(true);
          }
        }}
        style={{
          backgroundColor: inputIsFocused ? "rgba(240, 241, 243, 1)" : "rgba(255, 255, 255, 1)",
          border: "none",
          borderRadius: "4px",
          color: Number(inputValue) > totalPages ? "rgba(211, 44, 90, 1)" : "rgba(33, 36, 42, 1)",
          display: "flex",
          fontSize: "14px",
          height: "2.5rem",
          justifyContent: "center",
          outline: "none",
          textAlign: "center",
          width: "2.5rem",
        }}
        type="text"
        value={inputValue}
      />
      <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
        de {totalPages}
      </LqdTypography>
      <Stack direction="row">
        <Stack
          alignItems="center"
          justifyContent="center"
          onClick={onPrevPageClick}
          sx={{
            backgroundColor: prevButtonDisabled ? "rgba(240, 241, 243, 1)" : "rgba(255, 255, 255, 1)",
            border: prevButtonDisabled ? "1px solid rgba(155, 162, 175, 1)" : "1px solid rgba(127, 135, 152, 1)",
            borderRadius: "4px 0px 0px 4px",
            cursor: prevButtonDisabled ? "normal" : "pointer",
            height: "40px",
            width: "40px",
          }}
        >
          <ArrowLeft sx={{ color: prevButtonDisabled ? "rgba(155, 162, 175, 1)" : "rgba(127, 135, 152, 1)" }} />
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          onClick={onNextPageClick}
          sx={{
            backgroundColor: nextButtonDisabled ? "rgba(240, 241, 243, 1)" : "rgba(255, 255, 255, 1)",
            border: nextButtonDisabled ? "1px solid rgba(155, 162, 175, 1)" : "1px solid rgba(127, 135, 152, 1)",
            borderRadius: "0px 4px 4px 0px",
            cursor: nextButtonDisabled ? "normal" : "pointer",
            height: "40px",
            left: "-1px",
            position: "relative",
            width: "40px",
          }}
        >
          <ArrowRight sx={{ color: nextButtonDisabled ? "rgba(155, 162, 175, 1)" : "rgba(127, 135, 152, 1)" }} />
        </Stack>
      </Stack>
    </Stack>
  );
}

import { LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import { LayoutProps } from "../SimulatorFunctionBlocksFunctionInputsRenderer";
import SimulatorFunctionBlocksFunctionVariableInput from "../SimulatorFunctionBlocksFunctionVariableInput";

export default function SimulatorFunctionBlocksFunctionMultipleInputLayout(props: LayoutProps) {
  const {
    editVariableButton,
    func,
    newBlockButton,
    newVariableButton,
    onBlurFunction,
    onUpdateFunction,
    openSelectComponent,
    renderOptions,
    resultInputs,
    variableInputs,
    currentBlock,
  } = props;

  return (
    <>
      <LqdTypography color="rgba(79, 85, 98, 1)" sx={{ mt: 2.5 }}>
        Variáveis
      </LqdTypography>
      <Stack spacing={1} sx={{ ml: 5, mt: 1 }}>
        {Object.entries(variableInputs).map(([key, variable]) => (
          <SimulatorFunctionBlocksFunctionVariableInput
            currentBlock={currentBlock}
            editVariable={editVariableButton}
            func={func}
            isOpen={openSelectComponent}
            isResult={false}
            key={key}
            newOption={variable.select === "variables" ? newVariableButton : newBlockButton}
            onChange={(value) => onUpdateFunction(key, value as string)}
            options={renderOptions[variable.select]}
            value={func.variables[key]}
            variableData={{ key, variable }}
          />
        ))}
      </Stack>
      <LqdTypography color="rgba(79, 85, 98, 1)" sx={{ mt: 2.5 }}>
        Resultados
      </LqdTypography>
      <Stack spacing={1.5} sx={{ ml: 5, mt: 1 }}>
        {Object.entries(resultInputs).map(([key, variable]) => (
          <SimulatorFunctionBlocksFunctionVariableInput
            currentBlock={currentBlock}
            editVariable={editVariableButton}
            func={func}
            isOpen={openSelectComponent}
            isResult={true}
            key={key}
            newOption={null}
            onBlur={onBlurFunction}
            onChange={(value) => onUpdateFunction(key, value as string, false, true)}
            options={[]}
            value={func.result[key]}
            variableData={{ key, variable }}
          />
        ))}
      </Stack>
    </>
  );
}

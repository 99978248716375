import { Product } from "@products/types/Product";
import React from "react";
import { useAppSelector } from "../../../../store";
import SmallDialogConfirmation from "./SmallDialogConfirmation";
import SmallDialogDate from "./SmallDialogDate";
import SmallDialogDefault from "./SmallDialogDefault";
import SmallDialogList from "./SmallDialogList";
import SmallDialogLoading from "./SmallDialogLoading";
import SmallDialogMFA from "./SmallDialogMFA";
import SmallDialogSearch from "./SmallDialogSearch";

export interface SmallDialogAction {
  onClick?: (arg?: number | string | Array<string>, arg2?: string | number, arg3?: string | number) => void;
  title: string;
}

export type SmallDialogItem = {
  labelIcon?: JSX.Element;
  labelOne: string;
  labelTwo: null | string;
  valueOne: string;
  valueTwo: null | string;
};

export interface SmallDialogConfig {
  actions: Array<SmallDialogAction>; // Botões do modal
  blank?: boolean;
  body: string; // Texto principal do modal
  bodySecundary?: string; // Texto secundário do modal, quando necessário
  children?: React.ReactNode;
  confirmationString?: string; // String de confirmação para modais do tipo confirmation
  currentProduct?: Product;
  emptyLabel?: string; // Texto exibido quando a lista de itens do modal está vazia, no caso de um modal do tipo list
  fixedRange?: boolean; // boleano definir se a data irá ser customizável ou não
  itemList?: Array<SmallDialogItem>; // Lista de itens renderizados no modal, no caso de um modal do tipo list
  qrCodeURL?: string; // URL do QR Code para modais do tipo MFA
  searchStates?: {
    confirmationModalText: {
      body: string;
      title: string;
    };
    defaultCheckedItems: Array<string>;
    noResultsMessage: string;
    setDefaultCheckedItems: React.Dispatch<React.SetStateAction<Array<string>>>;
  }; // Estados necessários para modais do tipo search
  selectedOption?: 1 | 2;
  specialValidation?: (value: string) => boolean; // Validação específica de input em modais do tipo confirmation
  title: string; // Título do modal
  type: "confirmation" | "date" | "default" | "list" | "loading" | "mfa" | "search"; // Tipo do modal
}

export default function SmallDialog() {
  const { dialogContent } = useAppSelector((state) => state.common);

  const dialogLookup = {
    confirmation: <SmallDialogConfirmation />,
    date: <SmallDialogDate />,
    default: <SmallDialogDefault />,
    list: <SmallDialogList />,
    loading: <SmallDialogLoading />,
    mfa: <SmallDialogMFA />,
    search: <SmallDialogSearch />,
  };

  return dialogLookup[dialogContent.type];
}

import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { SimulatorUpdate } from "@simulatorBuilder/simulatorBuilderSlice";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import {
  SimulatorDashboard,
  SimulatorResultTableData,
  SimulatorResultTableRowCells,
} from "@simulatorBuilder/types/SimulatorDashboard";
import { Dispatch } from "react";
import { v4 as uuid } from "uuid";

export function existingTablesNewFixedColumn(
  tableContent: Array<SimulatorResultTableData>,
  simulatorDashboard: SimulatorDashboard,
  dispatch: Dispatch<unknown>,
  simulatorBuilderChanged: ActionCreatorWithPayload<
    SimulatorUpdate<keyof Simulator>,
    "simulatorBuilder/simulatorBuilderChanged"
  >,
  createInitialSimulatorTables: (tableContent: Array<SimulatorResultTableData>) => Array<SimulatorResultTableData>
) {
  const notInitializedTables = tableContent.filter((table) => table.rows[0].rowContent[0].data?.type !== "textDefault");

  if (tableContent.length > 0 && notInitializedTables.length > 0) {
    const updatedTables = notInitializedTables.map((table) => {
      const newColumnId = uuid();
      const updatedColumns = [{ columnId: newColumnId, columnName: "Nome da coluna" }, ...table.columns];

      const updatedRows = table.rows.map((row) => {
        const updatedRowContent: SimulatorResultTableRowCells = [
          {
            data: {
              column: 0,
              label: "",
              line: 0,
              name: "",
              type: "textDefault",
              value: "",
            },
            id: newColumnId,
          },
          ...row.rowContent.map((cell) => ({
            ...cell,
            data: {
              ...cell.data,
              column: (cell.data?.column ?? 0) + 1,
              label: cell.data?.label ?? "",
              line: cell.data?.line ?? 0,
              name: cell.data?.name ?? "",
              type: cell.data?.type ?? "",
              value: cell.data?.value ?? "",
            },
          })),
        ];

        return {
          ...row,
          rowContent: updatedRowContent,
        };
      });

      return {
        ...table,
        columns: updatedColumns,
        rows: updatedRows,
      };
    });

    const updatedSimulatorTables: Array<SimulatorResultTableData> = tableContent.map((table) => {
      const updatedTable = updatedTables.find((t) => table.rows.some((row) => row.rowId === t.rows[0].rowId));

      if (updatedTable) {
        return {
          ...table,
          columns: updatedTable.columns,
          rows: table.rows.map((row) => {
            const updatedRow = updatedTable.rows.find((r) => r.rowId === row.rowId);
            if (updatedRow) {
              return {
                ...row,
                rowContent: updatedRow.rowContent,
              };
            }
            return row;
          }),
        };
      }
      return table;
    });

    simulatorDashboard["tables"] = updatedSimulatorTables;
    dispatch(simulatorBuilderChanged({ key: "dashboard", value: simulatorDashboard }));
  } else {
    simulatorDashboard["tables"] = createInitialSimulatorTables(tableContent);
    dispatch(simulatorBuilderChanged({ key: "dashboard", value: simulatorDashboard }));
  }
}

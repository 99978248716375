import { OpenCreditReportDataRaw } from "@account/types/OpenCreditReportDataRaw";
import { get } from "../../common/utils/apiUtils";

/**
 * Endpoint para buscar os dados financeiros/analíticos de um determinado tenant.
 * @param startDate Data de início para filtrar os dados.
 * @param endDate Data de fim para filtrar os dados.
 * @returns Array de OpenCreditReportDataRaw.
 * @remarks Endpoint ADMIN.
 */
export const getOpenCreditReportData = (startDate: string, endDate: string) => {
  return get<Array<OpenCreditReportDataRaw>>(`/open-credit/report?start_date=${startDate}&end_date=${endDate}`);
};

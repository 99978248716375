import { LqdButton, LqdLink, LqdTypography } from "@/liquid-components/src";
import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../../store";
import { toastCalled } from "../../common/commonSlice";
import CreatePasswordForm from "../../common/components/CreatePasswordForm";
import { ToastNotification } from "../../common/types/ToastNotification";
import createPassword from "../api/CreatePassword";
import UserData from "../types/UserData";

type FirstSignInFormProps = {
  userData: UserData;
};

export default function FirstSignInForm(props: FirstSignInFormProps) {
  const { userData } = props;

  const INITIAL_STEP = 1;

  const dispatch = useAppDispatch();

  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [loading, setLoading] = useState(false);

  const { fullname, tenant_code, token, username } = userData;

  const redirectToSignIn = () => {
    window.location.href = "/signin";
  };

  const handleCreatePassword = (password: string) => {
    setLoading(true);
    createPassword(tenant_code, { password, token, username })
      .then(() => {
        setCurrentStep(3);
      })
      .catch((error) => {
        console.log("error: ", error);
        if (error.response?.status < 500) {
          const notification: ToastNotification = {
            message: "Algo deu errado com sua requisição. Tente novamente.",
            type: "error",
          };

          dispatch(toastCalled(notification));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
              Oi, {fullname.split(" ")[0]}!
            </LqdTypography>
            <LqdTypography sx={{ mb: 8 }} textstyle="p2Paragraph">
              Confirme seu nome e usuário para acessar a plataforma.
            </LqdTypography>
            <LqdTypography color="secondary">{fullname}</LqdTypography>
            <Divider sx={{ my: 1 }} />
            <LqdTypography>{username}</LqdTypography>
            <LqdButton onClick={() => setCurrentStep(2)} sx={{ mb: 4, mt: 8 }}>
              Confirmar
            </LqdButton>
            <LqdTypography color="secondary" sx={{ textAlign: "center" }} textstyle="p2Paragraph">
              Não é você? <LqdLink href="/first-signin-error">Cancelar</LqdLink>
            </LqdTypography>
          </>
        );
      case 2:
        return <CreatePasswordForm loading={loading} onCreatePassword={handleCreatePassword} />;
      case 3:
        return (
          <>
            <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
              Pronto!
            </LqdTypography>
            <LqdTypography sx={{ mb: 7 }} textstyle="p2Paragraph">
              Sua senha foi criada com sucesso.
            </LqdTypography>
            <LqdButton onClick={redirectToSignIn}>Login</LqdButton>
          </>
        );
    }
  };

  return (
    <Box
      id="begin"
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        mx: { sm: 0, xs: 3 },
        px: { lg: "20%", md: "10%", sm: 6, xs: 3 },
      }}
    >
      {renderCurrentStep()}
    </Box>
  );
}

import { Box } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { onClearAllAnalysis, onLoadAnalysisList, onLoadTotalAnalysisByMonth } from "../../board/boardSlice";
import { AnalysisColumn } from "../../board/types/AnalysisColumn";
import { toastCalled } from "../../common/commonSlice";
import FullscreenDialog from "../../common/components/FullscreenDialog";
import { ObjectOf } from "../../common/types/ObjectOf";
import { ToastNotification } from "../../common/types/ToastNotification";
import { onLoadGroups, onLoadLoggedUserGroups } from "../../groups/groupsSlice";
import Group from "../../groups/types/Group";
import { haveSameItems } from "../../groups/utils/haveSameItems";
import { onLoadGroupProducts } from "../../products/productsSlice";
import { editUserFormStepPrev, editUserModalClosed, onLoadUsers, onUpdateUser } from "../usersSlice";
import { getUserRaw } from "../utils/getUserRaw";
import UserFormStep1 from "./UserFormStep1";
import UserFormStep3 from "./UserFormStep3";
import UserFormStep4 from "./UserFormStep4";
import UserFormStepLast from "./UserFormStepLast";

export default function EditUserForm() {
  const dispatch = useAppDispatch();

  const { groups, loggedUserGroups } = useAppSelector((state) => state.groups);
  const { editUserCurrentStep, userToEdit } = useAppSelector((state) => state.users);
  const allGroups = { ...groups, ...loggedUserGroups };

  const [loading, setLoading] = useState(false);
  const [userGroups, setUserGroups] = useState<ObjectOf<Group>>({});
  const [userName, setUserName] = useState<string>("");
  const [userProfile, setUserProfile] = useState<string>("");

  const userNameChanged = userName !== userToEdit?.name;
  const userProfileChanged = userProfile !== userToEdit?.profile;
  const userGroupsChanged = !haveSameItems(
    Object.values(userGroups).map((group) => group.id),
    userToEdit?.groups || []
  );
  const anythingChanged = userNameChanged || userProfileChanged || userGroupsChanged;

  // Seta/limpa os campos de edição (name, groups) toda vez que o usuário a ser editado mudar
  useEffect(() => {
    if (userToEdit) {
      setUserName(userToEdit.name);
      setUserProfile(userToEdit.profile);
      setUserGroups(
        userToEdit.groups.reduce((acc, groupId) => {
          return allGroups[groupId] ? { ...acc, [groupId]: allGroups[groupId] } : acc;
        }, {})
      );
    }
  }, [userToEdit]);

  const loadAllAnalysis = async () => {
    const promises = [
      dispatch(
        onLoadAnalysisList({
          column: AnalysisColumn.COMPLETED,
          currentAnalysisCount: 0,
        })
      ),
      dispatch(
        onLoadAnalysisList({
          column: AnalysisColumn.MODERATION,
          currentAnalysisCount: 0,
        })
      ),
      dispatch(
        onLoadAnalysisList({
          column: AnalysisColumn.ONBOARDING,
          currentAnalysisCount: 0,
        })
      ),
      // Carrega o total de conexões em cada coluna
      dispatch(onLoadTotalAnalysisByMonth(AnalysisColumn.COMPLETED)),
      dispatch(onLoadTotalAnalysisByMonth(AnalysisColumn.MODERATION)),
      dispatch(onLoadTotalAnalysisByMonth(AnalysisColumn.ONBOARDING)),
    ];

    await Promise.all(promises);
  };

  const onSubmitUserEdit = async () => {
    setLoading(true);

    const userRaw = getUserRaw({
      ...userToEdit!,
      groups: Object.values(userGroups).map((group) => group.id),
      name: userName,
      profile: userProfile,
    });

    dispatch(onUpdateUser(userRaw))
      .then(async () => {
        const promises = [
          dispatch(onLoadGroups()),
          dispatch(onLoadGroupProducts()),
          dispatch(onClearAllAnalysis()),
          dispatch(onLoadUsers()),
          dispatch(onLoadLoggedUserGroups()),
          loadAllAnalysis(),
        ];

        await Promise.all(promises);
        dispatch(editUserModalClosed());

        const notification: ToastNotification = {
          message: t("Usuário atualizado com sucesso! Essa mudança pode demorar alguns minutos para acontecer."),
          type: "success",
        };

        dispatch(toastCalled(notification));
      })
      .catch((error) => {
        console.log("error: ", error);
        if (error.response?.status < 500) {
          const notification: ToastNotification = {
            message: t("Houve um erro ao atualizar o usuário. Tente novamente."),
            type: "error",
          };

          dispatch(toastCalled(notification));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderCurrentStep = () => {
    switch (editUserCurrentStep) {
      case 1:
        return <UserFormStep1 name={userName} setName={setUserName} />;
      case 2:
        return <UserFormStep3 profile={userProfile} setProfile={setUserProfile} />;
      case 3:
        return <UserFormStep4 selectedGroups={userGroups} setSelectedGroups={setUserGroups} />;
      case 4:
        return (
          <UserFormStepLast
            actionButtonLabel="Atualizar"
            loading={loading}
            onSubmit={onSubmitUserEdit}
            selectedGroups={userGroups}
            userEmail={userToEdit?.email || ""}
            userName={userName.trim()}
            userProfile={userProfile}
          />
        );
    }
  };

  return (
    <FullscreenDialog
      action="edit"
      currentStep={editUserCurrentStep}
      existingChanges={anythingChanged}
      handleGoBack={() => dispatch(editUserFormStepPrev())}
      onClose={() => dispatch(editUserModalClosed())}
      open={Boolean(userToEdit)}
      title={t("Edição de Usuários")}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>{renderCurrentStep()}</Box>
    </FullscreenDialog>
  );
}

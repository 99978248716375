import { LqdIconButton, LqdPlusTwoIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { createTemplateModalOpened } from "../../adminSlice";
import { onLoadTemplates } from "../../adminSliceThunks";
import { adminUpdateTemplate } from "../../api/UpdateTemplate";
import { TemplateResponse } from "../../types/TemplateResponse";
import CreateTemplateForm from "./CreateTemplateForm";
import EditTemplateForm from "./EditTemplateForm";
import TemplateRow from "./TemplateRow";

export default function TemplateConfig() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const segments = useAppSelector((state) => state.admin.segments);
  const templates = useAppSelector((state) => state.admin.templates);

  const [templateUnderEdit, setTemplateUnderEdit] = useState<TemplateResponse | null>(null);

  const handleTemplateStatusChange = (template: TemplateResponse) => {
    setTemplateUnderEdit(template);

    if (template.isActive) {
      dispatch(
        dialogCalled({
          actions: [
            { title: "Voltar" },
            { onClick: handleTemplateStatusChangeConfirmation, title: "Desativar template" },
          ],
          body: `Ao desativar o template, ele não poderá mais ser utilizado para a criação de nenhum
          produto. Deseja prosseguir?`,
          title: "Confirmar",
          type: "default",
        })
      );
    } else {
      handleTemplateStatusChangeConfirmation();
    }
  };

  const handleTemplateStatusChangeConfirmation = async () => {
    dispatch(dialogLoaded(true));

    const isActive = templateUnderEdit?.isActive;

    try {
      if (templateUnderEdit) {
        const requestBody = {
          edges: templateUnderEdit.edges,
          is_active: !isActive,
          name: templateUnderEdit.name,
          providers: templateUnderEdit.providers,
          rules: templateUnderEdit.rules,
          segments: templateUnderEdit.segments,
        };

        await adminUpdateTemplate(templateUnderEdit.templateId, requestBody);
        await dispatch(onLoadTemplates());

        const notification: ToastNotification = {
          message: `Template ${isActive ? "desativado" : "ativado"} com sucesso!`,
          type: "success",
        };

        dispatch(toastCalled(notification));
        setTemplateUnderEdit({ ...templateUnderEdit, isActive: !isActive });
        dispatch(dialogCalled(null));
      }
    } catch (error) {
      handleLiquidErrors(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          mb: 8,
          px: 12,
        }}
      >
        <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h4Headline">
          Templates
        </LqdTypography>
        <LqdTooltip
          placement="left"
          titlebody="Crie um template para facilitar a criação de um Produto, definindo regras."
          titleheader="Adicionar um template"
        >
          <LqdIconButton onClick={() => dispatch(createTemplateModalOpened())} round="true">
            <LqdPlusTwoIcon />
          </LqdIconButton>
        </LqdTooltip>
      </Box>
      {Object.values(templates).length > 0 ? (
        <>
          <Grid
            columns={{ lg: 16, md: 20 }}
            container
            sx={{
              alignItems: "center",
              backgroundColor: "rgba(248, 248, 249, 1)",
              borderBottom: "1px solid rgba(222, 225, 229, 1)",
              borderRadius: "8px 8px 0 0",
              height: "80px",
              mx: 8,
              width: "70vw",
            }}
          >
            <Grid item lg={5} md={7} sx={{ border: "none", pl: 3 }}>
              <LqdTypography textstyle="p2Paragraph">Template</LqdTypography>
            </Grid>
            <Grid item lg={3} md={3} sx={{ border: "none" }}>
              <LqdTypography textstyle="p2Paragraph">Moeda</LqdTypography>
            </Grid>
            <Grid item lg={3} md={3} sx={{ border: "none" }}>
              <LqdTypography textstyle="p2Paragraph">Segmentos</LqdTypography>
            </Grid>
            <Grid item lg={2.5} md={4} sx={{ border: "none" }}>
              <LqdTypography textstyle="p2Paragraph">Criação</LqdTypography>
            </Grid>
            <Grid item lg={2} md={3} sx={{ border: "none" }}>
              <LqdTypography textstyle="p2Paragraph">Status</LqdTypography>
            </Grid>
          </Grid>
          <Box sx={{ height: "380px", mr: 8, overflow: "auto", pl: 8, width: "70vw" }}>
            {Object.values(templates).map((template) => (
              <TemplateRow
                handleTemplateStatusChange={handleTemplateStatusChange}
                key={template.templateId}
                segments={segments}
                template={template}
              />
            ))}
          </Box>
        </>
      ) : (
        <LqdTypography color="secondary" sx={{ textAlign: "center" }} textstyle="h5Headline">
          Nenhum template foi criado até o momento
        </LqdTypography>
      )}
      <CreateTemplateForm />
      <EditTemplateForm />
    </>
  );
}

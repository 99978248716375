import { ObjectOf } from "@common/types/ObjectOf";
import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";
import {
  SimulatorFunctionTemplate,
  SimulatorSelectedVariable,
} from "@simulatorBuilder/types/SimulatorFunctionTemplate";
import { SimulatorOpenModal } from "@simulatorBuilder/types/SimulatorOpenModal";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { SimulatorVariableTemplateType } from "@simulatorBuilder/types/SimulatorVariableTypeTemplate";
import { Dispatch } from "react";
import { v4 as uuid } from "uuid";
import formatVariableName from "./formatVariableName";
import { predictResultFunctionType } from "./simulatorFunctionBlocksHelpers";

function simulatorSaveVariable(
  openModal: SimulatorOpenModal,
  selectedVariable: SimulatorSelectedVariable,
  variableLabel: string,
  variableName: string,
  variableType: SimulatorVariableTemplateType,
  variableValue: string | boolean,
  simulatorData: Simulator,
  dispatch: Dispatch<unknown>,
  handleNewVariableModalClose: () => void,
  handleCreateConstantVariable: () => SimulatorVariable
): SimulatorVariable {
  let updatedVariable: SimulatorVariable;

  if (openModal?.view === "editVariable") {
    updatedVariable = {
      id: selectedVariable.id || "",
      is_input: false,
      label: variableLabel,
      name: formatVariableName(variableName),
      solver: null,
      type: variableType,
      value: variableType === "boolean" ? JSON.stringify(variableValue) : (variableValue as string),
    };

    const updatedVariables = simulatorData!.variables.map((variable: SimulatorVariable) =>
      variable.id === selectedVariable.id ? updatedVariable : variable
    );

    dispatch(simulatorBuilderChanged({ key: "variables", value: updatedVariables }));
    handleNewVariableModalClose();
  } else updatedVariable = handleCreateConstantVariable();

  return updatedVariable;
}

function simulatorCreateConstantVariable(
  variableName: string,
  variableType: SimulatorVariableTemplateType,
  variableLabel: string,
  variableValue: string | boolean,
  simulator: Simulator,
  dispatch: Dispatch<unknown>,
  handleNewVariableModalClose: () => void
) {
  const newVariable: SimulatorVariable = {
    id: uuid(),
    is_input: false,
    label: variableLabel,
    name: formatVariableName(variableName),
    solver: null,
    type: variableType,
    value: variableType === "boolean" ? JSON.stringify(variableValue) : (variableValue as string),
  };

  dispatch(simulatorBuilderChanged({ key: "variables", value: [...simulator.variables, newVariable] }));
  handleNewVariableModalClose();
  return newVariable;
}

function simulatorCreateResultVariables(
  currentBlock: SimulatorFunctionBlock | undefined,
  blocks: Array<SimulatorFunctionBlock>,
  dispatch: Dispatch<unknown>,
  functionTemplates: Array<SimulatorFunctionTemplate>,
  simulator: Simulator,
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void
) {
  if (!currentBlock) return [];

  const newResultVariables: Array<SimulatorVariable> = [];
  const functions = currentBlock.functions || [];
  const simulatorVariables = [...simulator.variables];
  const updatedBlocks: Array<SimulatorFunctionBlock> = JSON.parse(JSON.stringify(blocks));

  functions.forEach((funct) => {
    const functResult = funct.result;

    Object.keys(functResult).forEach((resultKey) => {
      const functResultNameValue = functResult[resultKey];
      const targetResultVariable = `${currentBlock.block_id}_${funct.index}_${resultKey}`;

      const calculationFunctions = ["divide", "multiply", "subtract", "subtract_multiple", "sum", "sum_multiple"];
      const functName = funct.function_name;

      const functVariables = Object.keys(funct.variables)
        .sort()
        .map((key) => funct.variables[key]);

      const functTemplate = functionTemplates.find((template) => template.function_name === funct.function_name);

      const variableTypeLookup: ObjectOf<string> = simulator.variables.reduce((acc: ObjectOf<string>, variable) => {
        acc[variable.name] = variable.type || "string";
        return acc;
      }, {});

      const functVariableTypes = functVariables.map(
        (variable) => variableTypeLookup[variable]
      ) as unknown as Array<SimulatorVariableTemplateType>;

      const resultVariableType = calculationFunctions.includes(functName)
        ? predictResultFunctionType(functName, functVariableTypes)
        : ((functTemplate?.result_type || "string") as SimulatorVariableTemplateType);

      const newResultVariable = {
        id: `${currentBlock.block_id}_${funct.index}_${resultKey}`,
        is_input: false,
        label: simulatorVariables.find((v) => v.id === targetResultVariable)?.label || "",
        name: formatVariableName(functResultNameValue),
        solver: null,
        type: resultVariableType,
        value: "",
      };

      const existingVariableIndex = simulator.variables.findIndex((variable) => variable.id === newResultVariable.id);

      // Se a variável não existe, cria ela. Se existe, atualiza.
      if (existingVariableIndex === -1) {
        newResultVariables.push(newResultVariable);
      } else {
        const oldVariableName = simulator.variables[existingVariableIndex].name;
        simulatorVariables[existingVariableIndex] = newResultVariable;

        updatedBlocks.forEach((block) => {
          block.functions.forEach((functionBlock) => {
            Object.keys(functionBlock.variables).forEach((key) => {
              if (functionBlock.variables[key] === oldVariableName) {
                functionBlock.variables[key] = newResultVariable.name;
              }
            });
          });
        });
      }
    });
  });

  dispatch(simulatorBuilderChanged({ key: "variables", value: [...simulatorVariables, ...newResultVariables] }));
  dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocks }));
  setBlocks(updatedBlocks);

  return newResultVariables;
}

export default {
  simulatorCreateConstantVariable,
  simulatorCreateResultVariables,
  simulatorSaveVariable,
};

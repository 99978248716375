import {
  LqdEditIcon,
  LqdIconButton,
  LqdPlusIcon,
  LqdRepeatThreeIcon,
  LqdTrashIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Reorder } from "framer-motion";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  SimulatorResultTable,
  SimulatorResultTableColumn,
  SimulatorResultTableData,
} from "../../../../types/SimulatorDashboard";
import SimulatorResultTableEmptyLine from "./SimulatorResultTableEmptyLine";

type SimulatorResultTableComponentProps = {
  handleColumnNameClick: (columnIndex: number, tableIndex: number) => void;
  handleTableChange: (tableColumnName: string, columnIndex: number, rowIndex: number, tableIndex: number) => void;
  onColumnOrderChange: (newOrder: Array<string>, tableIndex: number) => void;
  renderView: () => JSX.Element | null;
  setCurrentView: Dispatch<SetStateAction<"changeColumnName" | "tableView">>;
  setEditRowProps: Dispatch<
    SetStateAction<{
      columnIndex: number;
      rowIndex: number;
      tableIndex: number;
      tableRowName: string;
    } | null>
  >;
  setTableColumnBeingChanged: Dispatch<SetStateAction<SimulatorResultTableColumn | null>>;
  table: SimulatorResultTableData;
  tableIndex: number;
  tableMethods: {
    addColumn: (tableIndex: number) => void;
    addRow: (tableIndex: number) => void;
    deleteColumn: (columnIndex: string, tableIndex: number) => void;
    deleteRow: (rowIndex: number, columnIndex: number, tableIndex: number) => void;
    resetColumn: (tableIndex: number) => void;
  };
};

export default function SimulatorResultTableComponent(props: SimulatorResultTableComponentProps) {
  const {
    handleColumnNameClick,
    handleTableChange,
    onColumnOrderChange,
    setCurrentView,
    setTableColumnBeingChanged,
    setEditRowProps,
    table,
    tableIndex,
    tableMethods,
  } = props;
  const { columns, rows } = table;

  const [orderedColumns, setOrderedColumns] = useState<Array<string>>(columns.map((column) => column.columnId));

  const columnContentFilled = (columns.length === 1 && columns[0].columnName !== null) || columns.length > 1;
  const lineContentFilled = rows[0].rowContent.some((tableCell) => tableCell.data !== null);

  useEffect(() => {
    setOrderedColumns(columns.map((column) => column.columnId));
  }, [columns]);

  const handleReorderColumns = (columnsOrder: Array<string>) => {
    const newOrder = [columns[0].columnId, ...columnsOrder];
    setOrderedColumns(newOrder);

    const updatedRows = rows.map((row) => {
      const newRowContent = newOrder.map((columnId) => {
        return row.rowContent.find((cell) => cell.id === columnId) || { data: null, id: columnId };
      });
      return { ...row, rowContent: newRowContent };
    });

    updatedRows.forEach((row, rowIndex) => {
      row.rowContent.forEach((cell, columnIndex) => {
        const columnName = columns.find((col) => col.columnId === cell.id)?.columnName || "";
        handleTableChange(columnName, columnIndex, rowIndex, tableIndex);
      });
    });

    onColumnOrderChange(newOrder, tableIndex);
  };

  const renderTableCellContent = (
    tableCell: { data: SimulatorResultTable | null; id: string },
    cellColumnIndex: number,
    cellRowIndex: number
  ) => {
    if (tableCell) {
      switch (true) {
        case tableCell.data !== null && cellColumnIndex !== 0:
          return (
            <TableCell
              key={`${cellRowIndex}-${cellColumnIndex}`}
              sx={{
                alignItems: "center",
                borderRight: cellColumnIndex === columns.length - 1 ? "none" : "1px solid rgba(212, 215, 220, 1)",
                display: "flex",
                flex: 1,
                height: "60px",
                justifyContent: "space-between",
                maxWidth: "250px",
                px: 2.5,
                width: "250px",
              }}
            >
              <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                {tableCell.data?.label}
              </LqdTypography>
              <LqdIconButton
                onClick={() => tableMethods.deleteRow(cellRowIndex, cellColumnIndex, tableIndex)}
                type="ghostIcon"
              >
                <LqdTrashIcon color="rgba(127, 135, 152, 1)" size={20} />
              </LqdIconButton>
            </TableCell>
          );
        case cellColumnIndex === 0:
          return (
            <TableCell
              sx={{
                borderRight: "1px solid rgba(212, 215, 220, 1)",
                display: "flex",
                flex: 1,
                height: "60px",
                maxWidth: "250px",
                px: 2.5,
                width: "250px",
              }}
            >
              <Stack alignItems="center" direction="row" spacing={1.5}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {tableCell.data?.label || "Nome da linha"}
                </LqdTypography>
                <LqdIconButton
                  buttonsize="small"
                  onClick={() => {
                    setEditRowProps({
                      columnIndex: cellColumnIndex,
                      rowIndex: cellRowIndex,
                      tableIndex: tableIndex,
                      tableRowName: "",
                    });

                    setTableColumnBeingChanged({
                      columnId: columns[0].columnId,
                      columnName: columns[0].columnName,
                    });

                    setCurrentView("changeColumnName");
                  }}
                  round="true"
                  type="ghostIcon"
                >
                  <LqdEditIcon color="rgba(155, 162, 175, 1)" size={20} />
                </LqdIconButton>
              </Stack>
            </TableCell>
          );
        default:
          return (
            <SimulatorResultTableEmptyLine
              columnIndex={cellColumnIndex}
              handleTableChange={handleTableChange}
              key={`${cellRowIndex}-${cellColumnIndex}`}
              lastColumn={cellColumnIndex === columns.length - 1}
              rowIndex={cellRowIndex}
              selectedVariable={tableCell}
              tableIndex={tableIndex}
            />
          );
      }
    }
  };

  return (
    <>
      <Table>
        <TableHead sx={{ "&:hover": { cursor: "pointer" }, alignItems: "center", display: "flex" }}>
          <TableRow sx={{ backgroundColor: "rgba(127, 135, 152, 0.04)", display: "flex", justifyContent: "center" }}>
            <TableCell
              key={`column-${orderedColumns[0]}`}
              sx={{
                borderRight: "1px solid rgba(212, 215, 220, 1)",
                display: "flex",
                flex: 1,
                height: "60px",
                maxWidth: "250px",
                px: 2.5,
                width: "250px",
              }}
            >
              <Stack alignItems="center" direction="row" spacing={1.5}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {columns[0].columnName || "Incorporadora"}
                </LqdTypography>
                <LqdIconButton
                  buttonsize="small"
                  onClick={() => handleColumnNameClick(0, tableIndex)}
                  round="true"
                  type="ghostIcon"
                >
                  <LqdEditIcon color="rgba(155, 162, 175, 1)" size={20} />
                </LqdIconButton>
              </Stack>
            </TableCell>
          </TableRow>
          <Reorder.Group
            axis="x"
            className="reorder-group-table-header"
            onReorder={handleReorderColumns}
            values={orderedColumns}
          >
            <TableRow sx={{ backgroundColor: "rgba(127, 135, 152, 0.04)", display: "flex", justifyContent: "center" }}>
              {orderedColumns.slice(1).map((columnId, index) => {
                const columnName = columns.find((column) => column.columnId === columnId)?.columnName;

                return (
                  <Reorder.Item key={columnId} value={columnId}>
                    <TableCell
                      key={`column-${columnId}`}
                      sx={{
                        borderRight: index === columns.length - 1 ? "none" : "1px solid rgba(212, 215, 220, 1)",
                        display: "flex",
                        flex: 1,
                        height: "60px",
                        maxWidth: "250px",
                        px: 2.5,
                        width: "250px",
                      }}
                    >
                      <Stack alignItems="center" direction="row" spacing={1.5}>
                        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                          {columnName || "Nome da coluna"}
                        </LqdTypography>
                        <LqdIconButton
                          buttonsize="small"
                          onClick={() => handleColumnNameClick(index + 1, tableIndex)}
                          round="true"
                          type="ghostIcon"
                        >
                          <LqdEditIcon color="rgba(155, 162, 175, 1)" size={20} />
                        </LqdIconButton>
                        <LqdIconButton
                          buttonsize="small"
                          onClick={
                            orderedColumns.length === 2
                              ? () => {
                                  tableMethods.resetColumn(tableIndex);
                                }
                              : () => tableMethods.deleteColumn(columnId, tableIndex)
                          }
                          round="true"
                          type="ghostIcon"
                        >
                          {orderedColumns.length > 2 ? (
                            <LqdTrashIcon color="rgba(155, 162, 175, 1)" size={20} />
                          ) : (
                            <LqdRepeatThreeIcon color="rgba(155, 162, 175, 1)" size={20} />
                          )}
                        </LqdIconButton>
                      </Stack>
                    </TableCell>
                  </Reorder.Item>
                );
              })}
            </TableRow>
          </Reorder.Group>
          {columnContentFilled ? (
            <LqdIconButton
              buttonsize="small"
              onClick={() => tableMethods.addColumn(tableIndex)}
              round="true"
              sx={{ height: "18px", position: "abolute", right: "18px", width: "18px" }}
            >
              <LqdPlusIcon color="rgba(255, 255, 255, 1)" />
            </LqdIconButton>
          ) : null}
        </TableHead>

        <TableBody>
          {rows.map(({ rowContent, rowId }, rowIndex) => (
            <TableRow key={`row-${rowId}-${uuid()}`} sx={{ display: "flex", width: "100%" }}>
              {orderedColumns.map((columnId, columnIndex) => {
                const tableCell = rowContent.find((cell) => cell.id === columnId);
                return renderTableCellContent(tableCell!, columnIndex, rowIndex);
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {lineContentFilled ? (
        <LqdIconButton
          buttonsize="small"
          onClick={() => tableMethods.addRow(tableIndex)}
          round="true"
          sx={{
            bottom: "16px",
            height: "18px",
            left: columns.length > 1 ? "50%" : "15%",
            position: "sticky",
            width: "18px",
          }}
        >
          <LqdPlusIcon color="rgba(255, 255, 255, 1)" />
        </LqdIconButton>
      ) : null}
    </>
  );
}

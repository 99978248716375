import { Box } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toastCalled } from "../../common/commonSlice";
import FullscreenDialog from "../../common/components/FullscreenDialog";
import { ObjectOf } from "../../common/types/ObjectOf";
import { ToastNotification } from "../../common/types/ToastNotification";
import { onLoadGroupProducts } from "../../products/productsSlice";
import { Product } from "../../products/types/Product";
import { User } from "../../users/types/User";
import { onLoadUsers } from "../../users/usersSlice";
import updateGroup from "../api/UpdateGroup";
import { editGroupModalClosed, onLoadGroups, onLoadLoggedUserGroups } from "../groupsSlice";
import { getGroupRaw } from "../utils/getGroupRaw";
import { haveSameItems } from "../utils/haveSameItems";
import GroupFormStep1 from "./GroupFormStep1";
import GroupFormStep2 from "./GroupFormStep2";
import GroupFormStep3 from "./GroupFormStep3";
import { default as GroupFormLastStep } from "./GroupFormStepLast";

export default function EditGroupForm() {
  const dispatch = useAppDispatch();
  const { groupBeingEdited, editGroupCurrentStep } = useAppSelector((state) => state.groups);
  const { users } = useAppSelector((state) => state.users);
  const allProducts = useAppSelector((state) => ({
    ...state.products.groupProducts,
    ...state.products.tenantProducts,
  }));
  const [loading, setLoading] = useState(false);
  const [groupTitle, setGroupTitle] = useState("");
  const [groupProducts, setGroupProducts] = useState<ObjectOf<Product>>({});
  const [groupUsers, setGroupUsers] = useState<ObjectOf<User>>({});

  const titleChanged = groupTitle !== groupBeingEdited?.title;
  const productsChanged = !haveSameItems(
    groupBeingEdited?.products || [],
    Object.values(groupProducts).map((product) => product.id)
  );
  const usersChanged = !haveSameItems(
    groupBeingEdited?.users || [],
    Object.values(groupUsers).map((user) => user.email)
  );
  const anythingChanged = titleChanged || productsChanged || usersChanged;

  // Seta/limpa os campos de edição (title, products, users) toda vez que o grupo a ser editado mudar
  useEffect(() => {
    if (groupBeingEdited) {
      setGroupTitle(groupBeingEdited.title);
      setGroupProducts(
        groupBeingEdited.products.reduce((acc, productId) => {
          acc[productId] = allProducts[productId];
          return acc;
        }, {} as ObjectOf<Product>)
      );
      setGroupUsers(
        groupBeingEdited.users.reduce((acc, userEmail) => {
          acc[userEmail] = users[userEmail];
          return acc;
        }, {} as ObjectOf<User>)
      );
    }
  }, [groupBeingEdited]);

  const handleCloseClick = () => {
    dispatch(editGroupModalClosed());
  };

  // Faz a request para editar o grupo, quando clicado no submit do último form step
  const onEditGroupSubmit = () => {
    if (!groupBeingEdited) {
      throw new Error("Could not get groupBeingEdited");
    }
    const groupRaw = getGroupRaw({
      ...groupBeingEdited,
      products: Object.values(groupProducts).map((product) => product.id),
      title: groupTitle,
      users: Object.values(groupUsers).map((user) => user.email),
    });
    setLoading(true);
    updateGroup(groupRaw)
      .then(async () => {
        const promises = [
          dispatch(onLoadGroupProducts()),
          dispatch(onLoadLoggedUserGroups()),
          dispatch(onLoadUsers()),
          dispatch(onLoadGroups()),
        ];
        await Promise.all(promises);
        const notification: ToastNotification = {
          message: t("Grupo atualizado com sucesso."),
          type: "success",
        };
        dispatch(toastCalled(notification));
        dispatch(editGroupModalClosed());
      })
      .catch((error) => {
        console.log("error: ", error);
        if (error.response?.status < 500) {
          const notification: ToastNotification = {
            message: t("Houve um erro ao atualizar o grupo. Tente novamente."),
            type: "error",
          };
          dispatch(toastCalled(notification));
        }
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 500);
      });
  };

  // Renderiza o step atual, dentro do template
  const renderCurrentStep = () => {
    switch (editGroupCurrentStep) {
      case 1:
        return <GroupFormStep1 editing={true} setTitle={setGroupTitle} title={groupTitle} />;
      case 2:
        return (
          <GroupFormStep2 editing={true} selectedProducts={groupProducts} setSelectedProducts={setGroupProducts} />
        );
      case 3:
        return <GroupFormStep3 editing={true} groupUsers={groupUsers} setGroupUsers={setGroupUsers} />;
      case 4:
        return (
          <GroupFormLastStep
            actionButtonLabel={t("Atualizar")}
            loading={loading}
            newProducts={groupProducts}
            newTitle={groupTitle}
            newUsers={groupUsers}
            onSubmit={onEditGroupSubmit}
          />
        );
    }
  };

  return (
    <FullscreenDialog
      action="edit"
      currentStep={editGroupCurrentStep}
      existingChanges={anythingChanged}
      onClose={handleCloseClick}
      open={Boolean(groupBeingEdited)}
      title={t("Editar Grupo")}
    >
      <Box sx={{ alignItems: "center", display: "flex", height: "100%", justifyContent: "center" }}>
        {renderCurrentStep()}
      </Box>
    </FullscreenDialog>
  );
}

import { LqdLinkIcon, LqdServerDatabaseIcon, LqdTypography, LqdWorldIcon } from "@/liquid-components/src";
import { useCopyToClipboard } from "@common/hooks/useCopyToClipboard";
import { Box, Stack } from "@mui/material";
import { getDateFormatWithHoursAndSeconds } from "../../analysisDetails/utils/analysisDetailsDataFormatter";
import { AUTOMATION_COLOR_DICTIONARY } from "../helpers/automationColorDictionary";
import { AUTOMATION_RESULT_DICTIONARY } from "../helpers/automationResultDictionary";
import { AUTOMATION_RESULT_ICONS } from "../helpers/automationResultIcons";
import { AutomationResult } from "../types/AutomationResult";

type AutomationDetailsCardProps = {
  automationResult: AutomationResult;
};

export default function AutomationDetailsCard(props: AutomationDetailsCardProps) {
  const { automationResult } = props;

  const copyValueToClipboard = useCopyToClipboard();

  const analysisId = automationResult.analysis_id;
  const analysisUrl = automationResult.analysis_url;
  const resultColor = AUTOMATION_COLOR_DICTIONARY[automationResult.status];
  const resultIcon = AUTOMATION_RESULT_ICONS[automationResult.status];

  return (
    <tr>
      <td style={{ padding: "20px 0" }}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: resultColor,
              borderRadius: "50%",
              display: { md: "flex", xs: "none" },
              height: "40px",
              width: "40px",
            }}
          >
            <LqdServerDatabaseIcon color="rgba(255, 255, 255, 1)" />
          </Stack>
          <Box>
            <LqdTypography textstyle="l1Label">{automationResult.name}</LqdTypography>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ fontSize: "10px" }} textstyle="l1Label">
              {getDateFormatWithHoursAndSeconds(automationResult.timestamp)}
            </LqdTypography>
          </Box>
        </Stack>
      </td>
      <td>
        <Stack alignItems="center" direction="row" spacing={1}>
          {resultIcon}
          <LqdTypography color={resultColor} textstyle="l1Label">
            {AUTOMATION_RESULT_DICTIONARY[automationResult.status]}
          </LqdTypography>
        </Stack>
      </td>
      <td>
        <Stack alignItems="center" direction="row" spacing={1}>
          <LqdWorldIcon color={analysisId ? "rgba(127, 135, 152, 1)" : "rgba(212, 215, 220, 1)"} />
          <LqdTypography
            color={analysisId ? "rgba(127, 135, 152, 1)" : "rgba(212, 215, 220, 1)"}
            onClick={() => copyValueToClipboard(analysisId, "ID da conexão")}
            sx={{ cursor: "pointer" }}
            textstyle="l1Label"
          >
            {analysisId ? analysisId.slice(0, 14) + "..." : "ID não disponível"}
          </LqdTypography>
        </Stack>
      </td>
      <td>
        <a href={automationResult.analysis_url} style={{ textDecoration: "none" }} target="_blank">
          <Stack alignItems="center" direction="row" spacing={1}>
            <LqdLinkIcon color={analysisUrl ? "rgba(127, 135, 152, 1)" : "rgba(212, 215, 220, 1)"} />
            <LqdTypography
              color={analysisUrl ? "rgba(127, 135, 152, 1)" : "rgba(212, 215, 220, 1)"}
              textstyle="l1Label"
            >
              {analysisUrl ? analysisUrl?.split("https://")[1]?.slice(0, 40) + "..." : "Link não disponível"}
            </LqdTypography>
          </Stack>
        </a>
      </td>
    </tr>
  );
}

import { get } from "../../common/utils/apiUtils";
import { AnalysisReportDataRaw } from "../types/AnalysisReportDataRaw";

/** Endpoint para buscar os dados financeiros/analíticos de um determinado tenant (dados de consumo no período de um mês).
 * @param startDate Data de início para filtrar os dados.
 * @param endDate Data de fim para filtrar os dados.
 * @returns Array de AnalysisReportDataRaw.
 * @remarks Endpoint ADMIN.
 */
export const getScheduledFinancialReportData = (interval_type: string) => {
  return get<Array<AnalysisReportDataRaw>>(`/analysis/report/client/fetch-scheduled?interval_type=${interval_type}`);
};

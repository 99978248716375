import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    height: "lg" | "md" | "sm" | "xl" | "xs" | undefined;
    width: "lg" | "md" | "sm" | "xl" | "xs" | undefined;
  }>({
    height: undefined,
    width: undefined,
  });

  function calculateWidth(width: number) {
    switch (true) {
      case width < 600:
        return "xs";
      case width < 900:
        return "sm";
      case width < 1200:
        return "md";
      case width < 1536:
        return "lg";
      default:
        return "xl";
    }
  }

  function calculateHeigth(height: number) {
    switch (true) {
      case height < 600:
        return "xs";
      case height < 900:
        return "sm";
      case height < 1200:
        return "md";
      case height < 1536:
        return "lg";
      default:
        return "xl";
    }
  }

  function handleResize() {
    const windowWidth = calculateWidth(window.innerWidth);
    const windowHeigth = calculateHeigth(window.innerHeight);

    setWindowSize({
      height: windowHeigth,
      width: windowWidth,
    });
  }

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return windowSize;
};

export default useWindowSize;

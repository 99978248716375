import { LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import {
  SimulatorResultCard,
  SimulatorResultHighlight,
  SimulatorResultTableData,
} from "../../types/SimulatorDashboard";

export const dashboardRender = (dashboard: {
  cards: Array<SimulatorResultCard>;
  highlight: Array<SimulatorResultHighlight>;
  tables: Array<SimulatorResultTableData>;
}) => {
  return (
    <Stack sx={{ px: 3 }}>
      <LqdTypography color="rgba(36, 38, 42, 1)" textstyle="h3Headline">
        Cards:
      </LqdTypography>
      {dashboard.cards.map((card) => (
        <Stack
          key={card.name}
          sx={{
            border: "1px solid rgba(36, 38, 42, 1)",
            borderRadius: "20px",
            minWidth: "300px",
            my: 2,
            p: 3,
            width: "fit-content",
          }}
        >
          {card.label}
        </Stack>
      ))}
      <LqdTypography color="rgba(36, 38, 42, 1)" textstyle="h3Headline">
        Highlights:
      </LqdTypography>
      {dashboard.highlight.map((highlight) => (
        <Stack
          key={highlight.name}
          sx={{
            border: "1px solid rgba(36, 38, 42, 1)",
            borderRadius: "20px",
            minWidth: "300px",
            my: 2,
            p: 3,
            width: "fit-content",
          }}
        >
          {highlight.label}
        </Stack>
      ))}
      <LqdTypography color="rgba(36, 38, 42, 1)" textstyle="h3Headline">
        Tabelas:
      </LqdTypography>
      {dashboard.tables.map((table, index) => (
        <Stack
          direction="column"
          gap={1}
          key={index}
          sx={{
            border: "1px solid rgba(36, 38, 42, 1)",
            borderRadius: "20px",
            minWidth: "300px",
            my: 2,
            p: 3,
            width: "fit-content",
          }}
        >
          <LqdTypography color="rgba(36, 38, 42, 1)" sx={{ mb: 0.5 }} textstyle="h5Headline">
            • Colunas:
          </LqdTypography>
          {table.columns.map((column) => (
            <Stack direction="column" key={column.columnId} sx={{ my: 0.5, pl: 3 }}>
              <LqdTypography color="rgba(36, 38, 42, 1)">{`Id: ${column.columnId}`}</LqdTypography>
              <LqdTypography color="rgba(36, 38, 42, 1)">{`Nome: ${column.columnName}`}</LqdTypography>
            </Stack>
          ))}
          <LqdTypography color="rgba(36, 38, 42, 1)" sx={{ mb: 0.5 }} textstyle="h5Headline">
            • Linhas:
          </LqdTypography>
          {table.rows.map((row) => (
            <Stack direction="column" key={row.rowId} sx={{ my: 0.5, pl: 3 }}>
              <LqdTypography
                color="rgba(36, 38, 42, 1)"
                sx={{ fontWeight: 600 }}
              >{`Linha ${row.rowId}:`}</LqdTypography>
              {row.rowContent.map((tableCell) => {
                const data = tableCell.data ? Object.entries(tableCell.data) : [];
                return data.map(([cellKey, cellValue]) => (
                  <LqdTypography
                    color="rgba(36, 38, 42, 1)"
                    key={cellKey}
                    sx={{ pb: cellKey === "value" ? 2 : 0, pl: 2 }}
                  >{`${cellKey} : ${cellValue}`}</LqdTypography>
                ));
              })}
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

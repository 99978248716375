import { LqdButton, LqdPlusIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box, Grid } from "@mui/material";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled, dialogLoaded } from "../../common/commonSlice";
import GroupRow from "../../common/components/GroupRow";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import updateGroup from "../api/UpdateGroup";
import CreateGroupForm from "../components/CreateGroupForm";
import EditGroupForm from "../components/EditGroupForm";
import { createGroupModalOpened, editGroupModalOpened, onLoadGroups } from "../groupsSlice";
import Group from "../types/Group";
import { getGroupRaw } from "../utils/getGroupRaw";

export default function GroupsPage() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const refToTop = useRef<HTMLDivElement>(null);

  const { groups } = useAppSelector((state) => state.groups);
  const products = useAppSelector((state) => {
    if (Object.values(state.products.tenantProducts).length > 0) {
      return state.products.tenantProducts;
    }
    return state.products.groupProducts;
  });
  const { users } = useAppSelector((state) => state.users);

  const [selectedGroupRowKey, setSelectedGroupRowKey] = useState<string>("");

  /** Faz a request para ativar/desativar o grupo
   * @param group Grupo a ser ativado/desativado
   * @param confirm Se a ação deve ser confirmada
   */
  const handleToggleGroup = async (group: Group, confirm?: boolean) => {
    const updateGroupStatus = async () => {
      try {
        const updatedGroup: Group = { ...group, active: !group.active };
        const groupRaw = getGroupRaw(updatedGroup);
        await updateGroup(groupRaw);
        dispatch(onLoadGroups());
      } catch (error) {
        handleLiquidErrors(error, "Houve um erro ao ativar o grupo. Tente novamente.");
      } finally {
        dispatch(dialogCalled(null));
      }
    };

    if (group.active) {
      dispatch(
        dialogCalled({
          actions: [
            { title: "Cancelar" },
            {
              onClick: async () => {
                onConfirmInactivateGroup(group);
                await updateGroupStatus();
              },
              title: "Confirmar",
            },
          ],
          body: "Tem certeza que deseja desativar este grupo?",
          title: "Desativar grupo",
          type: "default",
        })
      );
    } else if (confirm) {
      await updateGroupStatus();
    }
  };

  /** Confirma a desativação do grupo, após clicar no botão de confirmação do dialog
   * @param group Grupo a ser desativado
   */
  const onConfirmInactivateGroup = (group: Group) => {
    dispatch(dialogLoaded(true));
    handleToggleGroup(group, true);
  };

  const onEditGroupClick = (group: Group, step: number) => {
    dispatch(editGroupModalOpened({ group, step }));
  };

  const onCreateGroupClick = () => {
    dispatch(createGroupModalOpened());
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          mb: 11,
          pt: 6,
          px: { lg: 12.5, md: 6, sm: 4 },
        }}
      >
        <LqdTypography className="lqd-groups-page-title" textstyle="h4Headline">
          Grupos
        </LqdTypography>
        <LqdTooltip
          placement="left"
          titlebody="Crie um grupo e associe políticas e usuários da maneira que desejar."
          titleheader="Adicionar um grupo"
        >
          <LqdButton id="add-group-button" onClick={onCreateGroupClick} startIcon={<LqdPlusIcon />}>
            Novo Grupo
          </LqdButton>
        </LqdTooltip>
      </Box>
      <Box sx={{ p: { lg: 8, md: 6, sm: 4, xs: 2 }, pt: { lg: 0 } }}>
        {Object.values(groups).length > 0 ? (
          <>
            <Grid
              columns={{ lg: 16, md: 20 }}
              container
              sx={{
                alignItems: "center",
                backgroundColor: "rgba(248, 248, 249, 1)",
                borderBottom: "1px solid rgba(222, 225, 229, 1)",
                borderRadius: "8px 8px 0 0",
                height: "80px",
                ml: 4,
                width: "70vw",
              }}
            >
              <Grid item lg={6} md={7} sx={{ border: "none", pl: 3 }}>
                <LqdTypography textstyle="p2Paragraph">Grupo</LqdTypography>
              </Grid>
              <Grid item lg={2} md={3} sx={{ border: "none" }}>
                <LqdTypography textstyle="p2Paragraph">Produtos</LqdTypography>
              </Grid>
              <Grid item lg={2.5} md={3} sx={{ border: "none" }}>
                <LqdTypography textstyle="p2Paragraph">Usuários</LqdTypography>
              </Grid>
              <Grid item lg={3.5} md={4} sx={{ border: "none" }}>
                <LqdTypography textstyle="p2Paragraph">Criação</LqdTypography>
              </Grid>
              <Grid item lg={2} md={3} sx={{ border: "none" }}>
                <LqdTypography textstyle="p2Paragraph">Status</LqdTypography>
              </Grid>
            </Grid>
            <Box sx={{ height: "380px", mr: 8, overflow: "auto", pl: 4, width: "70vw" }}>
              {Object.values(groups).map((group) => (
                <Box key={group.id} ref={group.id === selectedGroupRowKey ? refToTop : null}>
                  <GroupRow
                    group={group}
                    groupRowKey={group.id}
                    onEditGroup={(step) => onEditGroupClick(group, step)}
                    onToggleButtonClick={async (confirm) => await handleToggleGroup(group, confirm)}
                    products={products}
                    refToTop={refToTop}
                    selectedGroupRowKey={selectedGroupRowKey}
                    setSelectedGroupRowKey={setSelectedGroupRowKey}
                    users={users}
                  />
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <LqdTypography
            color="secondary"
            sx={{ position: "absolute", right: 0, textAlign: "center", top: "calc(50%)", width: "100%" }}
            textstyle="h4Headline"
          >
            Você não participa de nenhum grupo.
          </LqdTypography>
        )}
      </Box>
      <CreateGroupForm />
      <EditGroupForm />
    </>
  );
}

import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./src/App";
import { ObjectOf } from "./src/features/common/types/ObjectOf";
import { env } from "./src/features/common/utils/apiUtils";
import reportWebVitals from "./src/reportWebVitals";
import store from "./src/store";

const NEW_RELIC_CONFIG: ObjectOf<ObjectOf<ObjectOf<ObjectOf<string | boolean | Array<string>> | string | number>>> = {
  development: {
    info: {
      applicationID: "1103368825",
      beacon: "bam.nr-data.net",
      errorBeacon: "bam.nr-data.net",
      licenseKey: "NRJS-60b05491728bd00ba62",
      sa: 1,
    },
    init: {
      ajax: { deny_list: ["bam.nr-data.net"] },
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
    },
    loader_config: {
      accountID: "4429720",
      agentID: "1103368825",
      applicationID: "1103368825",
      licenseKey: "NRJS-60b05491728bd00ba62",
      trustKey: "4429720",
    },
  },
  production: {
    info: {
      applicationID: "1103368827",
      beacon: "bam.nr-data.net",
      errorBeacon: "bam.nr-data.net",
      licenseKey: "NRJS-60b05491728bd00ba62",
      sa: 1,
    },
    init: {
      ajax: { deny_list: ["bam.nr-data.net"] },
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
    },
    loader_config: {
      accountID: "4429720",
      agentID: "1103368827",
      applicationID: "1103368827",
      licenseKey: "NRJS-60b05491728bd00ba62",
      trustKey: "4429720",
    },
  },
  staging: {
    info: {
      applicationID: "1103368826",
      beacon: "bam.nr-data.net",
      errorBeacon: "bam.nr-data.net",
      licenseKey: "NRJS-60b05491728bd00ba62",
      sa: 1,
    },
    init: {
      ajax: { deny_list: ["bam.nr-data.net"] },
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
    },
    loader_config: {
      accountID: "4429720",
      agentID: "1103368826",
      applicationID: "1103368826",
      licenseKey: "NRJS-60b05491728bd00ba62",
      trustKey: "4429720",
    },
  },
};

new BrowserAgent(NEW_RELIC_CONFIG[env]);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();

import { LqdButtonTypes } from "@/liquid-components/src/components/Button/Button";
import { Dialog, DialogContent, Stack } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store";
import { dialogCalled } from "../commonSlice";
import FullscreenDialogHeader from "./FullscreenDialogHeader";

export type FullScreenDialogExtraButton = {
  disabled: boolean;
  onClick: () => void;
  title: string;
  type: "tab" | LqdButtonTypes;
};

type FullscreenDialogProps = {
  action?: "create" | "edit";
  children?: ReactNode;
  currentStep?: number;
  existingChanges?: boolean;
  extraButtons?: Array<FullScreenDialogExtraButton>;
  handleGoBack?: () => void;
  hashValue?: string;
  hideHeader?: boolean;
  onClose: (saving: boolean) => void;
  onEditClick?: () => void;
  onTabChange?: (tab: string) => void;
  open: boolean;
  showEditButton?: boolean;
  showSkeleton?: boolean;
  tabValue?: string;
  title: string;
};

export default function FullscreenDialog(props: FullscreenDialogProps) {
  const {
    action,
    children,
    currentStep,
    existingChanges,
    extraButtons,
    handleGoBack,
    hashValue,
    hideHeader,
    onClose,
    onEditClick,
    onTabChange,
    open,
    showEditButton,
    showSkeleton,
    tabValue,
    title,
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const confirmCloseDialog = () => {
    dispatch(dialogCalled(null));
    onClose(false);
  };

  const handleOnClose = () => {
    if (action === "edit" || action === "create") {
      if (existingChanges) {
        dispatch(
          dialogCalled({
            actions: [{ title: "Voltar" }, { onClick: confirmCloseDialog, title: "Sair" }],
            body: t(`Há uma
              ${action === "edit" ? "edição" : "criação"}
              em andamento, sair agora cancelará as alterações não salvas. Deseja continuar?`),
            title: t("Atenção"),
            type: "default",
          })
        );
      } else {
        onClose(false);
      }
    } else {
      onClose(false);
    }
  };

  const showBackButton = action === "create" && (currentStep || 1) > 1;

  return (
    <Dialog fullScreen onClose={() => onClose(false)} open={open} sx={{ display: "flex", flexDirection: "column" }}>
      <DialogContent sx={{ flex: 1, p: 0 }}>
        <>
          {!hideHeader ? (
            <FullscreenDialogHeader
              extraButtons={extraButtons}
              goBack={handleGoBack}
              hashValue={hashValue}
              onClose={handleOnClose}
              onEditClick={onEditClick}
              onTabChange={onTabChange}
              showBackButton={showBackButton}
              showEditButton={showEditButton}
              showSkeleton={showSkeleton}
              tabValue={tabValue}
              title={title}
            />
          ) : null}
          <Stack sx={{ height: "calc(100% - 96px)" }}>{children}</Stack>
        </>
      </DialogContent>
    </Dialog>
  );
}

import { LqdTypography } from "@/liquid-components/src";
import { Box, Grid, Stack } from "@mui/material";
import { SimulatorTestDBResponse } from "@simulatorBuilder/types/Simulator";
import { getDateFormat } from "../../../analysisDetails/utils/analysisDetailsDataFormatter";
import {
  SimulatorResultCard,
  SimulatorResultHighlight,
  SimulatorResultTableData,
} from "../../types/SimulatorDashboard";
import { SimulatorTesterResult, convertSimulationKeys } from "../../types/simulationTester";
import { dashboardRender } from "./SimulationTesterDashboardRender";

type SimulationTestResult = {
  result: SimulatorTestDBResponse | SimulatorTesterResult | null;
};

export default function SimulationTestResult(props: SimulationTestResult) {
  const { result } = props;

  return result ? (
    <Box sx={{ mt: "40px" }}>
      <LqdTypography color="rgba(36, 38, 42, 1)" textstyle="h1Headline">
        Resultado da requisição:
      </LqdTypography>
      <LqdTypography color="rgba(36, 38, 42, 1)" sx={{ fontWeight: 600 }} textstyle="h2Headline">
        {result.name}
      </LqdTypography>
      <Grid container spacing={2} sx={{ overflowX: { lg: "hidden", sm: "auto" } }}>
        <Grid item sx={{ maxHeigth: "350px", overflowy: "auto" }} xs={4}>
          {dashboardRender(
            result.dashboard as {
              cards: Array<SimulatorResultCard>;
              highlight: Array<SimulatorResultHighlight>;
              tables: Array<SimulatorResultTableData>;
            }
          )}
        </Grid>

        <Grid item sx={{ maxHeigth: "350px", overflowy: "auto" }} xs={4}>
          <LqdTypography color="rgba(36, 38, 42, 1)" textstyle="h3Headline">
            Variáveis:
          </LqdTypography>
          {Object.entries(result.results).map(([result, value], index) => (
            <Stack
              key={index}
              sx={{
                border: "1px solid rgba(36, 38, 42, 1)",
                borderRadius: "20px",
                minWidth: "350px",
                my: 2,
                p: 3,
                width: "fit-content",
              }}
            >
              {result === "data_de_hoje" || result === "data_do_peer_mais_velho" ? (
                <LqdTypography
                  color="rgba(36, 38, 42, 1)"
                  sx={{ pl: 2 }}
                >{`${result} : ${getDateFormat(Number(value))}`}</LqdTypography>
              ) : (
                <LqdTypography color="rgba(36, 38, 42, 1)" sx={{ pl: 2 }}>{`${result} : ${value}`}</LqdTypography>
              )}
            </Stack>
          ))}
        </Grid>

        <Grid item sx={{ maxHeigth: "350px", overflowy: "auto" }} xs={4}>
          <LqdTypography color="rgba(36, 38, 42, 1)" textstyle="h3Headline">
            Steps:
          </LqdTypography>
          {result.steps.map((step, index) => (
            <Stack
              key={index}
              sx={{
                border: "1px solid rgba(36, 38, 42, 1)",
                borderRadius: "20px",
                minWidth: "400px",
                my: 2,
                p: 3,
                width: "fit-content",
              }}
            >
              {Object.entries(step).map(([stepName, value]) => {
                const formattedStepKey = convertSimulationKeys(stepName);
                return (
                  <Stack
                    key={stepName}
                    sx={{
                      border: "1px solid rgba(36, 38, 42, 1)",
                      borderRadius: "20px",
                      minWidth: "350px",
                      my: 2,
                      p: 3,
                      width: "fit-content",
                    }}
                  >
                    {stepName === "result" ? (
                      <Stack sx={{ p: 1 }}>
                        <LqdTypography color="rgba(36, 38, 42, 1)" textstyle="h4Headline">
                          {`${formattedStepKey}: `}
                        </LqdTypography>
                        {Object.entries(value).map(([innerKey, innerValue]) => (
                          <Stack key={innerKey}>
                            {typeof innerValue === "object" && innerValue !== null
                              ? Object.entries(innerValue).map(([nestedKey, nestedValue]) => (
                                  <LqdTypography color="rgba(36, 38, 42, 1)" key={nestedKey} sx={{ pl: 2 }}>
                                    {`${nestedKey} : ${nestedValue}`}
                                  </LqdTypography>
                                ))
                              : null}
                          </Stack>
                        ))}
                      </Stack>
                    ) : (
                      <LqdTypography color="rgba(36, 38, 42, 1)">
                        {`${formattedStepKey} : ${JSON.stringify(value)}`}
                      </LqdTypography>
                    )}
                  </Stack>
                );
              })}
            </Stack>
          ))}
        </Grid>
      </Grid>
    </Box>
  ) : null;
}

import {
  LqdButton,
  LqdCircularLoader,
  LqdIconButton,
  LqdInput,
  LqdSelect,
  LqdTrashIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Box } from "@mui/material";
import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { SimulatorFunctionBlock } from "../../../../types/SimulatorFunctionBlock";
import { SimulatorVariable } from "../../../../types/SimulatorVariable";
import { SimulatorVariableTemplateType } from "../../../../types/SimulatorVariableTypeTemplate";

type SimulatorFunctionBlocksFormNewVariableModalValueProps = {
  blockId: number;
  blocks: Array<SimulatorFunctionBlock>;
  createVariable: () => SimulatorVariable;
  functionId: number;
  functionKey: string;
  handleDeleteVariable: () => void;
  handleGoBack: () => void;
  loading: boolean;
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void;
  setVariableValue: (value: boolean | string) => void;
  variableType: SimulatorVariableTemplateType;
  variableValue: boolean | string;
};

const VARIABLE_VALUE_MIN_LENGTH = 0;
const VARIABLE_VALUE_MAX_LENGTH = 25;

export default function SimulatorFunctionBlocksFormNewVariableModalValue(
  props: SimulatorFunctionBlocksFormNewVariableModalValueProps
) {
  const {
    blockId,
    blocks,
    createVariable,
    functionId,
    functionKey,
    handleDeleteVariable,
    handleGoBack,
    loading,
    setBlocks,
    setVariableValue,
    variableType,
    variableValue,
  } = props;

  const dispatch = useAppDispatch();

  const { selectedVariable } = useAppSelector((state) => state.simulatorBuilder);

  useEffect(() => {
    if (!variableValue && selectedVariable?.value) {
      setVariableValue(selectedVariable.value);
    }
  }, [selectedVariable, variableValue, setVariableValue]);

  const variableValueTooShort = (variableValue as string).length < VARIABLE_VALUE_MIN_LENGTH;
  const variableValueTooBig = (variableValue as string).length > VARIABLE_VALUE_MAX_LENGTH;
  const enableSaveButton =
    variableType === "boolean" ? variableValue !== "" : variableValue && !variableValueTooShort && !variableValueTooBig;

  const handleCreateConstantVariable = async () => {
    const variableData = createVariable();

    const currentBlock = blocks.find((block) => block.block_id === blockId)!;
    const currentFunction = currentBlock.functions.find((funct) => funct.index === functionId)!;

    const updatedFunction = {
      ...currentFunction,
      variables: {
        ...currentFunction?.variables,
        [selectedVariable.functionKey || functionKey]: variableData.name,
      },
    };

    const updatedFunctions = currentBlock!.functions.map((funct) =>
      funct.index === functionId ? updatedFunction : funct
    );

    const updatedBlock = { ...currentBlock!, functions: updatedFunctions };
    const updatedBlocks = blocks.map((block) => (block.block_id === blockId ? updatedBlock : block));

    setBlocks(updatedBlocks);
    dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocks }));
  };

  const handleBooleanValue = () => {
    switch (true) {
      case variableValue === "":
        return "";
      case !!variableValue:
        return "Verdadeiro";
      default:
        return "Falso";
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2 }} textstyle="h5Headline">
          Qual é o valor da variável constante?
        </LqdTypography>
        {selectedVariable.id ? (
          <LqdIconButton buttonsize="small" onClick={handleDeleteVariable} type="ghostIcon">
            <LqdTrashIcon />
          </LqdIconButton>
        ) : null}
      </Box>
      {variableType === "boolean" ? (
        <LqdSelect
          onChange={(value) => setVariableValue(value === "Verdadeiro")}
          options={[
            { label: "Verdadeiro", value: "Verdadeiro" },
            { label: "Falso", value: "Falso" },
          ]}
          placeholder="Selecione o valor da variável"
          value={handleBooleanValue()}
        />
      ) : (
        <LqdInput
          maskType={variableType}
          onConfirm={handleCreateConstantVariable}
          onConfirmValidation={Boolean(enableSaveButton && !loading)}
          setValue={setVariableValue}
          showCharacterLimit={true}
          value={variableValue as string}
        />
      )}
      <Box sx={{ float: "right", mt: 3 }}>
        <LqdButton disabled={loading} onClick={handleGoBack} sx={{ mr: 3 }} type="outlineTertiary">
          Voltar
        </LqdButton>
        <LqdButton disabled={!enableSaveButton || loading} onClick={handleCreateConstantVariable}>
          {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Salvar"}
        </LqdButton>
      </Box>
    </>
  );
}

import {
  LqdCheckIcon,
  LqdCloseIcon,
  LqdFlagIcon,
  LqdProviderIcon,
  LqdProviderTwoIcon,
  LqdRepeatIcon,
  LqdSearchCNPJIcon,
  LqdSearchCPFIcon,
  LqdWarningIcon,
} from "@/liquid-components/src";
import { ObjectOf } from "../../common/types/ObjectOf";
import ActionNodeCustomData from "../types/ActionNodeCustomData";
import NodeOptions from "../types/NodeOptions";
import ProviderNodeCustomData from "../types/ProviderNodeCustomData";

type NodeIconProps = ObjectOf<ActionNodeCustomData | NodeOptions | ProviderNodeCustomData | unknown>;

function getNodeIconFromProvider(nodeProvider: string) {
  switch (nodeProvider) {
    case "pluggy":
    case "serasa_parcial":
    case "serasa_completo":
    case "peer_model":
    case "liquidpass":
    case "financial_indicators":
      return <LqdSearchCPFIcon color="rgba(0, 0, 0, 1)" />;
    case "serasa_cnpj":
    case "liquidpass_cnpj":
      return <LqdSearchCNPJIcon color="rgba(0, 0, 0, 1)" />;
    case "simulation":
      return <LqdProviderIcon color="rgba(0, 0, 0, 1)" />;
    default:
      return <LqdProviderIcon color="rgba(0, 0, 0, 1)" />;
  }
}

export default function NodeIcon(props: NodeIconProps) {
  const { action, provider, title } = props;

  const getIcon = () => {
    if (props) {
      if (title === "Start Flow") {
        return <LqdFlagIcon color="rgba(0, 0, 0, 1)" />;
      }
      if (action) {
        switch (action) {
          case "match":
            return <LqdCheckIcon color="rgba(0, 0, 0, 1)" />;
          case "unmatch":
            return <LqdCloseIcon color="rgba(0, 0, 0, 1)" />;
          case "moderation":
            return <LqdProviderTwoIcon color="rgba(0, 0, 0, 1)" />;
          case "warning":
            return <LqdWarningIcon color="rgba(0, 0, 0, 1)" />;
          case "jump":
            return <LqdRepeatIcon color="rgba(0, 0, 0, 1)" />;
        }
      } else {
        return getNodeIconFromProvider(provider as string);
      }
    }
  };

  return <>{getIcon()}</>;
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { createTenant } from "../signup/api/CreateTenant";
import { adminListGroups } from "./api/ListGroups";
import { adminListProducts } from "./api/ListProducts";
import { adminListProviders } from "./api/ListProviders";
import { adminListSegments } from "./api/ListSegments";
import { adminListTemplates } from "./api/ListTemplates";
import { adminListTenants } from "./api/ListTenants";
import { adminUpdateTemplate } from "./api/UpdateTemplate";
import { adminUpdateTenant } from "./api/UpdateTenant";
import { TemplateUpdate } from "./types/TemplateUpdate";
import { TenantCreateRaw } from "./types/TenantCreateRaw";
import { TenantUpdate } from "./types/TenantUpdate";
import { formatRawGroupSuperAdmin } from "./utils/formatRawGroupSuperAdmin";
import { formatRawProductSuperAdmin } from "./utils/formatRawProductSuperAdmin";
import { formatRawProviderSuperAdmin } from "./utils/formatRawProviderSuperAdmin";
import { formatRawSegmentSuperAdmin } from "./utils/formatRawSegmentSuperAdmin";
import { formatRawTemplateSuperAdmin } from "./utils/formatRawTemplateSuperAdmin";
import { formatRawTenantSuperAdmin } from "./utils/formatRawTenantSuperAdmin";

export const onCreateTenant = createAsyncThunk("admin/onCreateTenant", async (payload: TenantCreateRaw) => {
  await createTenant(payload);
});

export const onUpdateTenant = createAsyncThunk(
  "admin/onUpdateTenant",
  async ({ tenantCode, tenantInfo }: { tenantCode: string; tenantInfo: TenantUpdate }) => {
    await adminUpdateTenant(tenantCode, tenantInfo);
  }
);

export const onLoadGroupsAdmin = createAsyncThunk("admin/onLoadGroups", async () => {
  try {
    const groupsRes = await adminListGroups();
    const rawGroups = groupsRes.data;
    const groups = rawGroups.map(formatRawGroupSuperAdmin);

    return groups;
  } catch (error) {
    const errorData = error as AxiosError;
    const status = errorData.response?.status;

    if (status === 401) {
      return null;
    }
  }
});

export const onLoadProducts = createAsyncThunk("admin/onLoadProducts", async () => {
  try {
    const productsRes = await adminListProducts();
    const rawProducts = productsRes.data;
    const products = rawProducts.map(formatRawProductSuperAdmin);

    return products;
  } catch (error) {
    const errorData = error as AxiosError;
    const status = errorData.response?.status;

    if (status === 401) {
      return null;
    }
  }
});

export const onLoadProviders = createAsyncThunk("admin/onLoadProviders", async () => {
  try {
    const providersRes = await adminListProviders();
    const rawProviders = providersRes.data;
    const providers = rawProviders.map(formatRawProviderSuperAdmin);

    return providers;
  } catch (error) {
    const errorData = error as AxiosError;
    const status = errorData.response?.status;

    if (status === 401) {
      return null;
    }
  }
});

export const onLoadSegments = createAsyncThunk("admin/onLoadSegments", async () => {
  try {
    const segmentsRes = await adminListSegments();
    const rawSegments = segmentsRes.data;
    const segments = rawSegments.map(formatRawSegmentSuperAdmin);

    return segments;
  } catch (error) {
    const errorData = error as AxiosError;
    const status = errorData.response?.status;

    if (status === 401) {
      return null;
    }
  }
});

export const onLoadTemplates = createAsyncThunk("admin/onLoadTemplates", async () => {
  try {
    const templateRes = await adminListTemplates();
    const rawTemplates = templateRes.data;
    const templates = rawTemplates.map(formatRawTemplateSuperAdmin);

    return templates;
  } catch (error) {
    const errorData = error as AxiosError;
    const status = errorData.response?.status;

    if (status === 401) {
      return null;
    }
  }
});

export const onLoadTenants = createAsyncThunk("admin/onLoadTenants", async () => {
  try {
    const tenantRes = await adminListTenants();
    const rawTenants = tenantRes.data;
    const tenants = rawTenants.map(formatRawTenantSuperAdmin);

    return tenants;
  } catch (error) {
    const errorData = error as AxiosError;
    const status = errorData.response?.status;

    if (status === 401) {
      return null;
    }
  }
});

export const onUpdateTemplate = createAsyncThunk(
  "admin/onUpdateTemplate",
  async ({ updateTemplateForm, templateId }: { templateId: string; updateTemplateForm: TemplateUpdate }) => {
    const updateTemplateReq = await adminUpdateTemplate(templateId, updateTemplateForm);
    return updateTemplateReq.data;
  }
);

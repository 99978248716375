import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterEleven() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        11. RESPONSABILIDADES
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.1. A Liquid adota as melhores ferramentas de segurança da informação e práticas de compliance digital para
        proporcionar o nível de privacidade e segurança adequados para Dados Pessoais. No entanto, destacamos que,
        apesar de todos os nossos esforços, não somos capazes de assegurar a absoluta e total inviolabilidade do
        Tratamento, de modo que não podemos nos responsabilizar por eventuais danos causados por terceiros em virtude do
        descumprimento desta Política e/ou das obrigações contratuais assumidas com a Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.2. A Liquid, via de regra, figura como Operador de Dados, uma vez que apenas disponibiliza o meio para que
        seja realizado o Tratamento de Dados através de suas Soluções, de acordo com os Parâmetros estabelecidos pelo
        Usuário Decisor.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        11.2.1. A Plataforma Liquid permite o Tratamento de quaisquer categorias de Dados Pessoais, incluindo Dados
        Pessoais Sensíveis, cabendo ao Usuário Decisor a responsabilidade de garantir a finalidade e hipótese de
        Tratamento adequada, assim como a necessidade, pertinência e qualidade dos Dados inseridos na Plataforma.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.3. A depender do tipo de solicitação recebida pelo Usuário Analisado, a Liquid, na qualidade de Operador, a
        repassará ao Usuário Decisor, ao qual competem as decisões referentes ao Tratamento (Controlador), não podendo
        ser responsabilizada pela ausência ou ineficácia no atendimento de referida solicitação.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.4. Na qualidade de Operador, a Liquid não se responsabilizará nos casos em que os Parâmetros estabelecidos
        pelo Usuário Decisor forem ou puderem ser considerados como Tratamento discriminatório.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.5. Conforme estabelecido no item 9.2.2. acima, informações sobre a tomada de decisão automatizada deverão ser
        requeridas diretamente junto ao Usuário Decisor, que estabeleceu os Parâmetros e ao qual competem as decisões
        referentes ao Tratamento (Controlador).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.6. A Liquid não se responsabilizará pela decisão final tomada pelo Usuário Decisor ou pelo Tratamento de
        Dados realizado fora do contexto de suas Soluções.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.7. A Liquid não se responsabilizará pelo compartilhamento indevido por quaisquer Usuários de seu login ou
        senha de acesso a qualquer uma de suas Soluções, independentemente de se tratar do Usuário Decisor ou do Usuário
        Analisado.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.8. A Liquid também não se responsabilizará pela atuação criminosa de hackers, crackers ou assemelhados, bem
        como por ações ou omissões que possam ser atribuídas exclusivamente aos Usuários (Decisor ou Analisado) ou
        terceiros, com exceção de comprovada negligência da Liquid em proteger os Dados Pessoais.
      </LqdTypography>
    </>
  );
}

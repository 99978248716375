import {
  LqdBreadcrumb,
  LqdButton,
  LqdCircularLoader,
  LqdEditIcon,
  LqdIconButton,
  LqdPlusIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { Box, Stack } from "@mui/material";
import { onUpdateSimulator } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorFunctionBlocksFormViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksFormViews";
import { SimulatorFunctionBlocksViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksViews";
import { SimulatorOpenModal } from "@simulatorBuilder/types/SimulatorOpenModal";
import methods from "@simulatorBuilder/utils/simulatorFunctionBlocksMethods";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import FullscreenDialog from "../../../../common/components/FullscreenDialog";
import { ObjectOf } from "../../../../common/types/ObjectOf";
import { useErrorHandler } from "../../../../common/utils/useErrorHandler";
import { SimulatorFunctionBlock } from "../../../types/SimulatorFunctionBlock";
import { SimulatorVariable } from "../../../types/SimulatorVariable";
import { SimulatorVariableTemplateType } from "../../../types/SimulatorVariableTypeTemplate";
import { isSomeFunctionNotCompletelyFilled } from "../../../utils/simulatorFunctionBlocksHelpers";
import NewVariableModal from "./NewVariableModal/SimulatorFunctionBlocksFormNewVariableModal";
import SimulatorFunctionBlocksFormName from "./SimulatorFunctionBlocksFormName";
import SimulatorFunctionBlocksFunctionCardForm from "./SimulatorFunctionBlocksFunctionCardForm";

type SimulatorFunctionBlocksFormFunctionListProps = {
  blockId: number;
  blockName: string;
  blocks: Array<SimulatorFunctionBlock>;
  functionMethods: { add: () => void; delete: (index: number) => void; duplicate: (index: number) => void };
  isDuplicating: boolean;
  onClose: (saving: boolean) => void;
  setBlockId: (blockId: number) => void;
  setBlockName: (name: string) => void;
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void;
  setCurrentFunctionBlocksView: (view: SimulatorFunctionBlocksViews) => void;
  setCurrentView: (view: SimulatorFunctionBlocksViews) => void;
  setIsDuplicating: Dispatch<SetStateAction<boolean>>;
};

export default function SimulatorFunctionBlocksFormFunctionList(props: SimulatorFunctionBlocksFormFunctionListProps) {
  const {
    blockId,
    blockName,
    blocks,
    functionMethods,
    isDuplicating,
    onClose,
    setBlockId,
    setBlockName,
    setBlocks,
    setCurrentFunctionBlocksView,
    setCurrentView,
    setIsDuplicating,
  } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { functionTemplates, selectedVariable, simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const { simulatorCreateConstantVariable, simulatorCreateResultVariables, simulatorSaveVariable } = methods;

  const [currentNewVariableModalView, setCurrentNewVariableModalView] =
    useState<SimulatorFunctionBlocksFormViews>("variableName");
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<SimulatorOpenModal>(null);
  const [variableName, setVariableName] = useState<string>("");
  const [variableLabel, setVariableLabel] = useState<string>("");
  const [variableType, setVariableType] = useState<SimulatorVariableTemplateType>("" as SimulatorVariableTemplateType);
  const [variableValue, setVariableValue] = useState<boolean | string>("");
  const [maySaveBlock, setMaySaveBlock] = useState<boolean>(false);

  const currentBlock = blocks.find((block) => block.block_id === blockId);
  const blockFunctions = currentBlock?.functions || [];

  const handleSaveVariable = (): SimulatorVariable => {
    return simulatorSaveVariable(
      openModal,
      selectedVariable,
      variableLabel,
      variableName,
      variableType,
      variableValue,
      simulator,
      dispatch,
      handleNewVariableModalClose,
      handleCreateConstantVariable
    );
  };

  const handleCreateConstantVariable = () => {
    return simulatorCreateConstantVariable(
      variableName,
      variableType,
      variableLabel,
      variableValue,
      simulator,
      dispatch,
      handleNewVariableModalClose
    );
  };

  const createResultVariables = () => {
    return simulatorCreateResultVariables(currentBlock, blocks, dispatch, functionTemplates, simulator, setBlocks);
  };

  const updateSimulatorBlocks = async () => {
    setLoading(true);

    try {
      const newVariables = createResultVariables();
      const updatedSimulator = {
        ...simulator,
        variables: [...simulator.variables, ...newVariables],
      };
      const requestData = { blocks, simulator: updatedSimulator, simulatorId: simulator.simulator_id };

      await dispatch(onUpdateSimulator(requestData)).unwrap();

      if (isDuplicating) {
        dispatch(
          toastCalled({
            message: "O bloco foi duplicado com sucesso.",
            type: "success",
          })
        );
      }
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const notification: ToastNotification = {
        message: `Existem variáveis com nome inválido: ${message.invalid_vars.join(",\n")}`,
        type: "error",
      };

      dispatch(toastCalled(notification));
    } finally {
      setCurrentFunctionBlocksView("blockName");
      onClose(true);
      setIsDuplicating(false);
      setLoading(false);
    }
  };

  const handleNewVariableModalClose = () => {
    setCurrentNewVariableModalView("variableName");
    setVariableName("");
    setVariableLabel("");
    setVariableType("" as SimulatorVariableTemplateType);
    setVariableValue("");
    setOpenModal(null);
  };

  const modalContent: ObjectOf<ObjectOf<JSX.Element | string>> = {
    editVariable: {
      content: (
        <NewVariableModal
          blockId={blockId}
          blocks={blocks}
          createVariable={handleSaveVariable}
          currentView={currentNewVariableModalView}
          functionId={openModal?.functionId as number}
          functionKey={openModal?.functionKey as string}
          handleVariableCloseModal={handleNewVariableModalClose}
          loading={loading}
          setBlocks={setBlocks}
          setCurrentView={setCurrentNewVariableModalView}
          setVariableLabel={setVariableLabel}
          setVariableName={setVariableName}
          setVariableType={setVariableType}
          setVariableValue={setVariableValue}
          variableLabel={variableLabel}
          variableName={variableName}
          variableType={variableType}
          variableValue={variableValue}
        />
      ),
      title: selectedVariable.label || "",
    },
    newBlock: {
      content: (
        <Box sx={{ alignItems: "center", display: "flex", height: "100%", justifyContent: "center" }}>
          <SimulatorFunctionBlocksFormName
            blockId={blockId}
            blockName={blockName}
            blocks={blocks}
            functionId={openModal?.functionId}
            functionKey={openModal?.functionKey as string}
            setBlockId={setBlockId}
            setBlockName={setBlockName}
            setBlocks={setBlocks}
            setCurrentView={setCurrentView}
            setIsDuplicating={setIsDuplicating}
            setOpenModal={setOpenModal}
          />
        </Box>
      ),
      title: "Novo Bloco",
    },
    newVariable: {
      content: (
        <NewVariableModal
          blockId={blockId}
          blocks={blocks}
          createVariable={handleCreateConstantVariable}
          currentView={currentNewVariableModalView}
          functionId={openModal?.functionId as number}
          functionKey={openModal?.functionKey as string}
          handleVariableCloseModal={handleNewVariableModalClose}
          loading={loading}
          setBlocks={setBlocks}
          setCurrentView={setCurrentNewVariableModalView}
          setVariableLabel={setVariableLabel}
          setVariableName={setVariableName}
          setVariableType={setVariableType}
          setVariableValue={setVariableValue}
          variableLabel={variableLabel}
          variableName={variableName}
          variableType={variableType}
          variableValue={variableValue}
        />
      ),
      title: currentNewVariableModalView === "variableName" ? "Nova variável constante" : variableName,
    },
  };

  useEffect(() => {
    const functionChecker = isSomeFunctionNotCompletelyFilled(currentBlock!, simulatorData!);
    setMaySaveBlock(functionChecker);
  }, [simulatorData, blocks]);

  return (
    <>
      <Box sx={{ backgroundColor: "rgba(240, 241, 243, 1)", py: 1.5, width: "100%" }}>
        <Box sx={{ ml: "100px" }}>
          <LqdBreadcrumb
            options={[
              { name: simulator?.name || "", onClick: () => onClose(false) },
              { name: currentBlock!.name || "" },
            ]}
            separator="/"
          />
        </Box>
      </Box>
      <Box
        sx={{
          margin: "auto",
          maxHeight: "640px",
          maxWidth: "874px",
          overflowY: "auto",
          px: 7.5,
          py: 3,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", mb: 3 }}>
          <LqdTypography sx={{ mr: 2 }} textstyle="h4Headline">
            {currentBlock!.name}
          </LqdTypography>
          <LqdIconButton buttonsize="small" onClick={() => setCurrentView("blockName")} round="true" type="ghostIcon">
            <LqdEditIcon size={20} />
          </LqdIconButton>
        </Box>
        <Stack spacing={3}>
          {blockFunctions.map((func) => (
            <SimulatorFunctionBlocksFunctionCardForm
              blockId={blockId}
              blocks={blocks}
              createResultVariables={createResultVariables}
              func={func}
              functionMethods={functionMethods}
              key={`${func.function_name}-${func.index}`}
              setBlockName={setBlockName}
              setBlocks={setBlocks}
              setOpenModal={setOpenModal}
            />
          ))}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2.5 }}>
          <LqdButton
            disabled={maySaveBlock || loading}
            endIcon={<LqdPlusIcon />}
            onClick={functionMethods.add}
            type="outlineTertiary"
          >
            Adicionar Função
          </LqdButton>
          <LqdButton disabled={!blockFunctions.length || maySaveBlock || loading} onClick={updateSimulatorBlocks}>
            {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Salvar"}
          </LqdButton>
        </Stack>
      </Box>
      {openModal ? (
        <FullscreenDialog
          action="create"
          onClose={handleNewVariableModalClose}
          open={!!openModal}
          title={modalContent[openModal.view].title as string}
        >
          {modalContent[openModal.view].content}
        </FullscreenDialog>
      ) : null}
    </>
  );
}

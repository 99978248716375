import { ObjectOf } from "../../common/types/ObjectOf";
import { SimulatorDBResults } from "./Simulator";

export interface SimulatorTesterResult {
  dashboard: Dashboard;
  name: string;
  results: SimulatorDBResults;
  steps: Array<Step>;
}

export interface Dashboard {
  cards: Array<Card>;
  highlight: Array<Highlight>;
  tables: Array<Table>;
}

export interface Card {
  index: number;
  label: string;
  name: string;
  type: string;
  value: string;
}

export interface Highlight {
  index: number;
  label: string;
  name: string;
  type: string;
  value: string;
}

export interface Table {
  columns: Array<Column>;
  rows: Array<Row>;
}

export interface Column {
  columnId: string;
  columnName: string;
}

export interface Row {
  rowContent: Array<RowContent>;
  rowId: number;
}

export interface RowContent {
  data: Data;
  id: string;
}

export interface Data {
  column: number;
  label: string;
  line: number;
  name: string;
  type: string;
  value: string;
}

export interface Step {
  block_id: number;
  block_name: string;
  function_index: number;
  function_name: string;
}

export const convertSimulationKeys = (functionKey: string): string => {
  const blockFunctionKeys: ObjectOf<string> = {
    block_id: "id_bloco",
    block_name: "nome_bloco",
    function_index: "indice_funcao",
    function_name: "nome_funcao",
    function_params: "parametros_funcao",
    index: "index",
    param_type: "tipo_parametro",
    result: "resultado",
    type: "tipo",
    variables: "variaveis",
  };

  return blockFunctionKeys[functionKey] || functionKey;
};
